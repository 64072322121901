import gql from 'graphql-tag';

export const assignBusAndDrivers = gql`
  mutation assignBusAndDrivers($buses_drivers: [BusAndDrivers!]!) {
    assignBusAndDrivers(buses_drivers: $buses_drivers) {
      status
      message
      message_ar
    }
  }
`;

export default assignBusAndDrivers;
