import gql from 'graphql-tag';

export const FetchTripsForPrices = gql`
  query tripTemplate($tripTemplateId: ID!) {
    tripTemplate(id: $tripTemplateId) {
      id
      ref_code
      access_level
      from_date
      to_date
      is_active
      routeLines {
        id
        from_city {
          id
          code
          name_en
          name_ar
        }
        to_city {
          id
          code
          name_en
          name_ar
        }
        prices {
          id
          seat_type_id {
            id
            name_en
            name_ar
          }
          bus_salon_id
          price
        }
      }
    }
  }
`;
