import gql from 'graphql-tag';

export const CHECK_TICKET_REPLACEABLE = gql`
  query checkTicketReplaceable($ticketId: ID!) {
    checkTicketReplaceable(id: $ticketId) {
      status
      message
      message_ar
      data {
        ticket {
          id
          price
          customer {
            id
            name
            phone
            email
            is_valid
            registered
          }
        }
      }
    }
  }
`;

export default CHECK_TICKET_REPLACEABLE;
