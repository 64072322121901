import gql from 'graphql-tag';

export const EXPORT_TICKETS_REPORT_BACKGROUND = gql`
  mutation exportTicketsBackground {
    exportTicketsBackground {
      status
      message
    }
  }
`;

export default EXPORT_TICKETS_REPORT_BACKGROUND;
