import gql from 'graphql-tag';

export const EXPORT_SHIFT_LOG = gql`
  mutation exportStationShiftLog($id: ID!) {
    exportStationShiftLog(id: $id) {
      status
      message
      data {
        downloadable_link
      }
    }
  }
`;

export default EXPORT_SHIFT_LOG;
