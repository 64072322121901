import { createContext } from 'react';

const authContext = createContext({
  authenticated: false,
  user: {},
  token: localStorage.getItem('token'),
  setSession: () => {},
  setIdentity: () => {},
  logout: () => {},
  refetchUser: () => {},
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
export const AuthContext = authContext;
