/**Component that lists all available bus salons in the system
 * with action to view seat map and available seat types
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Dropdown, Popover } from 'antd';
import { Query } from '@apollo/react-components';
import { busSalonList } from 'services';
import SeatTypeInfo from './seat-type-info';
import SalonLayoutInfo from './salon-layout-info';
import { ListHeader, PrimaryButton, PrimaryTitle, Can } from 'components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BusSalonList = () => {
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Bus Name',
      dataIndex: 'name',
    },
    {
      title: 'Seat Map',
      dataIndex: 'layout',
      render: (layout) => {
        return layout && layout.length ? (
          <Popover trigger="click" content={<SalonLayoutInfo seats={layout} />}>
            <a className="ant-dropdown-link" href="#">
              {t('More Details')}
            </a>
          </Popover>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'Seat Types',
      dataIndex: 'seat_types',
      render: (seat_types) => {
        return seat_types && seat_types.length ? (
          <Dropdown overlay={<SeatTypeInfo seatTypes={seat_types} />}>
            <a className="ant-dropdown-link" href="#">
              {t('More Details')}
            </a>
          </Dropdown>
        ) : (
          '-'
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });
  return (
    <>
      <Query query={busSalonList} variables={{ first: 5, page: 1 }}>
        {({ loading, error, data, fetchMore }) => (
          <>
            {loading && <p>Loading ...</p>}
            {error && <p>{error}</p>}
            {data && data.busSalons && data.busSalons.data && (
              <>
                <ListHeader>
                  <PrimaryTitle>{t('Bus Salons')}</PrimaryTitle>
                  <Can
                    perform="CREATE_SALON"
                    yes={
                      <PrimaryButton
                        onClick={() => history.push('/new-bus-salon')}
                      >
                        {t('Add New')}
                      </PrimaryButton>
                    }
                  />
                </ListHeader>
                <Table
                  scroll={{ x: 400 }}
                  dataSource={data.busSalons.data.map((busSalon) => ({
                    ...busSalon,
                    key: busSalon.id,
                  }))}
                  columns={columns}
                  loading={loading}
                  pagination={{
                    total:
                      data &&
                      data.busSalons &&
                      data.busSalons.paginatorInfo &&
                      data.busSalons.paginatorInfo.total,
                    pageSize: 5,
                    onChange: (page) => {
                      fetchMore({
                        variables: {
                          page: page,
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prev;
                          return fetchMoreResult;
                        },
                      });
                    },
                  }}
                />
              </>
            )}
          </>
        )}
      </Query>
    </>
  );
};

export default BusSalonList;
