import React, { Component } from 'react';
import { List, Row, Col, Button } from 'antd';
import { getCustomer } from 'services';
import { Query } from '@apollo/react-components';
import { Can, ListHeader, PrimaryButton, PrimaryTitle } from 'components';

const purchaseData = [
  {
    Ticket: '1234',
    Trip: 'CAI/MO-HUR',
    Date: '1/1/2019 9am',
    seats: '1 Seat',
    price: '150 EGP',
    status: 'Paid',
  },
  {
    Ticket: '1234',
    Trip: 'CAI/MO-HUR',
    Date: '1/1/2019 9am',
    seats: '1 Seat',
    price: '150 EGP',
    status: 'Processing',
  },
];

const personalHaeder = (
  <ListHeader>
    <PrimaryTitle>Personal Info</PrimaryTitle>
    <Can
      perform="EDIT_CUSTOMER"
      yes={<PrimaryButton href="">Edit Customer</PrimaryButton>}
    />
  </ListHeader>
);

const purchaseHaeder = (
  <ListHeader>
    <PrimaryTitle>Purchase History</PrimaryTitle>
  </ListHeader>
);

class CustomerDetailsView extends Component {
  personalData = this.props.personalData || {
    Id: 1,
    name: 'Mohamed Mahmoud',
    phone: '0100 234 5678',
    type: 'Student',
  };

  dataSource = Object.keys(this.personalData).map((key) => {
    return { [key]: this.personalData[key] };
  });

  render() {
    const { dataSource } = this;
    return (
      <div>
        {/* [Todo] Add Header when merge this with testing or develop as it exist in composnet/list*/}
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <List
              header={personalHaeder}
              bordered
              dataSource={dataSource}
              renderItem={(item) => (
                <List.Item>
                  {Object.keys(item).map((key) => (
                    <List.Item.Meta description={`${key}: ${item[key]}`} />
                  ))}
                </List.Item>
              )}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <List
              // grid={{ gutter: 16, column: 1 }}
              itemLayout="vertical"
              header={purchaseHaeder}
              bordered
              dataSource={purchaseData}
              renderItem={(item) => (
                <List.Item>
                  {Object.keys(item).map((key) => (
                    <List.Item.Meta description={`${key}: ${item[key]}`} />
                  ))}
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const CustomerDetails = (props) => (
  <Query query={getCustomer} variables={{ id: props.match.params.id }}>
    {({ loading, error, data }) => {
      if (loading) return 'Loading...';
      if (error) return `Error! ${error.message}`;
      console.log('customer', data.customer);
      return <CustomerDetailsView personalData={data.customer} />;
    }}
  </Query>
);

export default CustomerDetails;
