export const getFawryChargeRequest = (
  language,
  orderId,
  orderNumber,
  totalPrice,
  customer,
  signature
) => {
  const merchantCode = process.env.REACT_APP_FAWRY_MERCHANT_CODE;

  const fawryChargeRequest = {
    language: language === 'en' ? 'en-gb' : 'ar-eg',
    merchantCode,
    merchantRefNumber: orderId,
    customer,
    order: {
      orderItems: [
        {
          productSKU: orderNumber,
          // TODO: find a better description for order item.
          description: 'Test Product',
          price: totalPrice,
          quantity: 1,
        },
      ],
    },
    signature,
  };
  console.info(fawryChargeRequest);
  return fawryChargeRequest;
};
