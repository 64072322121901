import gql from 'graphql-tag';

export const GET_ALL_SEGMENTS = gql`
  query allPartnerSegments {
    allPartnerSegments {
      id
      name
    }
  }
`;

export default GET_ALL_SEGMENTS;
