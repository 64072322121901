import gql from 'graphql-tag';

export const GET_DRIVER = gql`
  query getDriver($id: ID!, $first: Int!, $page: Int!) {
    getDriver(id: $id) {
      id
      name
      phone
      orders(first: $first, page: $page) {
        paginatorInfo {
          count
          total
          currentPage
        }
        data {
          id
          customer {
            name
            phone
          }
          price
          payment_method
          order_number
          created_at
          status
          tickets {
            seat_number
            seat_type {
              name_en
            }
            price
            trip {
              ref_code
              date
              time
            }
          }
          created_at
          updated_at
        }
      }
    }
  }
`;

export default GET_DRIVER;
