import React, { useState } from 'react';
import { Query } from '@apollo/react-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, DatePicker, Select, Button, Spin } from 'antd';
import moment from 'moment';
import { GET_ACTIVE_LOCATIONS } from 'services';
import { useTranslation } from 'react-i18next';

const { Option, OptGroup } = Select;

const SearchTrips = ({
  form: { getFieldDecorator, getFieldValue, validateFields, setFieldsValue , resetFields },
  setFormValues,
  setRounded,
}) => {
  const [t, i18n] = useTranslation();
  const nameLang = i18n.language;
  const [roundTrip, handleRoundTrip] = useState(false);
  const [selectedCityName, setSelectedCityName] = useState(0);
  const handleSearchTrips = (e) => {
    e.preventDefault();
    const formFields = ['from', 'to', 'trip_date'];
    if (roundTrip) formFields.push('round_trip_date');
    validateFields(formFields, (err, fieldsValue) => {
      if (!err) {
        setFormValues(fieldsValue);
        setRounded(false);
      }
    });
  };

  return (
    <Query query={GET_ACTIVE_LOCATIONS}>
      {({ loading, error, data }) => {
        if (error) return `Error! ${error.message}`;
        const { activeLocations } = data || { activeLocations: [] };

        const availableLocations = activeLocations.reduce(
          (result, location) => {
            const cityName = location.city.name_en;
            const cityId = location.city.id;

            if (!Object.keys(result).includes(cityName)) {
              result[cityName] = [{ ...location, city_id: cityId }];
            } else {
              result[cityName].push({ ...location, city_id: cityId });
            }
            return result;
          },
          {}
        );
        
        const isEmptyCities = Object.keys(availableLocations).map(
          (city) =>
            availableLocations[city].filter((e) => e.driver_location !== true)
              .length
        );

        const onChange = (fromLocationId) => {
          if (fromLocationId === getFieldValue('to'))
            setFieldsValue({
              to: undefined,
            });

          Object.keys(availableLocations).forEach((cityName) => {
            availableLocations[cityName].forEach((location) => {
              if (location.id === fromLocationId) {
                setSelectedCityName(cityName);
                return;
              }
            });
          });
        };

        const tripDate = getFieldValue('trip_date');

        return (
          <>
            <Form layout="inline" onSubmit={handleSearchTrips}>
              <Form.Item>
                {getFieldDecorator('from', {
                  rules: [
                    { required: true, message: t('Please enter the station!') },
                  ],
                })(
                  <Select
                    placeholder={t('Select Station')}
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    onChange={onChange}
                    style={{ width: 400 }}
                  >
                    {loading ? (
                      <Option
                        value={null}
                        disabled
                        style={{ textAlign: 'center' }}
                      >
                        <Spin tip="Loading Stations..." />
                      </Option>
                    ) : (
                      Object.keys(availableLocations).filter((city, cityIndex)=> isEmptyCities[cityIndex] >= 1 ).map(
                        (cityName, cityIndex) => (
                          <OptGroup key={`city_${cityIndex}`} label={cityName}>
                            {availableLocations[cityName].filter(city => city.driver_location !== true).map(
                              (location, locationIndex) => (
                                <Option
                                  key={`location_${cityIndex}_${locationIndex}`}
                                  value={location.id}
                                >
                                  {nameLang ==="en" ? location.name_en : location.name_ar}
                                </Option>
                              )
                            )}
                          </OptGroup>
                        )
                      )
                    )}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('to', {
                  rules: [
                    { required: true, message: t('Please enter the station!') },
                  ],
                })(
                  <Select
                    placeholder={t('Select Station')}
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    style={{ width: 400 }}
                  >
                    {loading ? (
                      <Option
                        value={null}
                        disabled
                        style={{ textAlign: 'center' }}
                      >
                        <Spin tip="Loading Stations..." />
                      </Option>
                    ) : (
                      Object.keys(availableLocations)
                        .filter((cityName) => cityName !== selectedCityName)
                        .map((cityName, cityIndex) => (
                          <OptGroup key={`city_${cityIndex}`} label={cityName}>
                            {availableLocations[cityName].filter(city =>
                                  roundTrip === true
                                    ? city.driver_location !== true
                                    : city
                                ).map(
                              (location, locationIndex) => (
                                <Option
                                  key={`location_${cityIndex}_${locationIndex}`}
                                  value={location.id}
                                >
                                  {nameLang ==="en" ? location.name_en : location.name_ar}
                                </Option>
                              )
                            )}
                          </OptGroup>
                        ))
                    )}
                  </Select>
                )}
              </Form.Item>
              <br />
              <Form.Item>
                <Checkbox onChange={(e) => {
                  handleRoundTrip(e.target.checked);
                  resetFields();
                }}>
                  {t('Round Trip')}
                </Checkbox>
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('trip_date', {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: t('Please specify the trip date!'),
                    },
                  ],
                })(
                  <DatePicker
                    placeholder={t('Select date')}
                    disabledDate={(current) => {
                      return current && current < moment().startOf('day');
                    }}
                  />
                )}
              </Form.Item>
              {roundTrip && (
                <Form.Item>
                  {getFieldDecorator('round_trip_date', {
                    rules: [
                      {
                        type: 'object',
                        required: roundTrip,
                        message: t('Please specify the return date!'),
                      },
                    ],
                  })(
                    <DatePicker
                      placeholder={t('Select date')}
                      disabledDate={(endValue) => {
                        const startValue = tripDate;
                        if (!endValue || !startValue) {
                          return false;
                        }
                        return endValue.valueOf() <= startValue.valueOf();
                      }}
                    />
                  )}
                </Form.Item>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('Search')}
                </Button>
              </Form.Item>
            </Form>
          </>
        );
      }}
    </Query>
  );
};

const SearchTripsForm = Form.create({})(SearchTrips);

export default SearchTripsForm;
