import gql from 'graphql-tag';

export default gql`
  mutation createBusSalon($seats: [[createNodeInput]], $name: String) {
    createBusSalon(name: $name, layout: $seats) {
      id
      name
    }
  }
`;
