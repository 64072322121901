import gql from 'graphql-tag';

const CREATE_SHIFT = gql`
  mutation createStationShift(
    $code: String!
    $name: String!
    $startDate: Date
    $endDate: Date
    $startTime: String!
    $endTime: String!
    $station: [Int!]!
  ) {
    createStationShift(
      code: $code
      name: $name
      start_time: $startTime
      end_time: $endTime
      start_date: $startDate
      end_date: $endDate
      stations_ids: $station
    ) {
      status
      message
      data {
        id
        name
      }
    }
  }
`;

export default CREATE_SHIFT;
