import gql from 'graphql-tag';

export const searchDrivers = gql`
  query searchDrivers($name: String!) {
    searchDrivers(name: $name) {
      id
      name
    }
  }
`;

export default searchDrivers;
