import React from 'react';

const ShipmentRules = () => {
  return (
    <div style={{ marginTop: '10px' }}>
      <h5 dir={'rtl'}>شروط وتعليمات الشحن:</h5>
      <ul dir={'rtl'}>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          يتم توصيل الشحن خلال 48 ساعة من معاد تسليم الشحن
        </li>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          ﻳﺤﻖ ﻟﻠشرﻛﺔ الإطلاع ﻋﻠﻰ محتويات الشحنة ﻟﻠﺘﺄﻛﺪ ﻣﻦ ﻋﺪم ﻣﺨﺎﻟﻔﺘﻬﺎ للائحة
          اﻟﺸﺤﻦ.
        </li>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          تسليم اﻟﺸﺤنة ﻟﻠﻌﻤﻴﻞ اﻟﻤﺪون إﺳﻤﻪ ﻋﻠﻰ ﺑﻮﻟﻴﺼﺔ اﻟﺸﺤﻦ وذلك بعد الإطلاع على
          تحقيق اﻟﺸﺨﺼﻴﺔ. أو ﻣﻦ ﻳﻨﻮب ﻋﻨﻪ ﺑﺘﻔﻮﻳﺾ ﻣﻮﻗﻊ وﺻﻮرة ﺑﻄﺎﻗﺔ الرقم القومي.{' '}
        </li>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          اﻟﺘﺄﻛﺪ ﻣﻦ ﻣﺤﺘﻮﻳﺎت اﻟﺸﺤﻨﺔ ﻛﺎﻣﻠﺔ ﻗﺒﻞ ﻣﻐﺎدرة اﻟﻔﺮع مسئولية العميل
          المستلم.
        </li>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          ﻣﺤﺘﻮﻳﺎت اﻟﺸﺤﻨﺔ ﻣﺴﺌﻮﻟﻴﺔ اﻟﻌﻤﻴﻞ واﻟشرﻛﺔ ﻏﻴﺮ ﻣﺴﺌﻮﻟﺔ ﻗﺎﻧﻮﻧﻴﺎً ﻋﻦ ﻣﺎ ﺑﺪاﺧﻞ
          اﻟﺸﺤنة.
        </li>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          يسمح بشحن أي مأكولات أو مجمدات داخل ايس بوكس محكم الغلق.
        </li>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          إستلام اﻟﺸﺤنة ﻣﻦ اﻟﻔﺮع ﺑﺤﺪ أقصى أﺳﺒﻮع وﺑﻌﺪ ذﻟﻚ تحول ﻟﻠﻔﺮع اﻟﺮﺋﻴسي.
        </li>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          أقصى ﻣﻴﻌﺎد لإستلام اﻟﺸﺤﻨﺔ ﺷﻬﺮ ﻣﻦ ﺗﺎرﻳﺦ إﺻﺪار ﺑﻮﻟﻴﺼﺔ اﻟﺸﺤﻦ, و 48 ساعة
          ﻟﻠﻤﺠﻤﺪات ولا ﻳﺤﻖ ﻟﻠﻌﻤﻴﻞ ﻣﻄﺎﻟﺒﺔ اﻟشركة ﺑﻬﺎ ﺑﻌﺪ ذﻟﻚ.
        </li>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          ﻓﻲ ﺣﺎﻟﺔ اﻟﻐﺎء اﻟﺸﺤنة ﻳﺨﺼﻢ ﻣﺒﻠﻎ 15 جنيه مصري من قيمة الشحن.
        </li>
        <li style={{ fontSize: '10px' }} dir={'rtl'}>
          ﺗﻌﻮﻳﺾ اﻟﻌﻤﻴﻞ ﻓﻲ ﺣﺎﻟﺔ ﻓﻘﺪ اﻟﺸﺤﻨﺔ ﻳﻜﻮن ضعفين ﻗﻴﻤﺔ اﻟﺸﺤﻦ, ﻓﻲ ﺣﺎﻟﺔ ﻋﺪم
          ﻣﺨﺎﻟﻔﺔ اﻟﺸﺤﻨﺔ ﻟﺘﻌﻠﻴﻤﺎت اﻟﺸﺤﻦ.
        </li>

        <h5 dir={'rtl'}> محظورات الشحن:</h5>
        <ul dir={'rtl'}>
          <li style={{ fontSize: '10px' }} dir={'rtl'}>
            المشروبات الكحولية وكذلك الحيوانات والطيور والأسماك الحية.
          </li>
          <li style={{ fontSize: '10px' }} dir={'rtl'}>
            المواد القابلة للإشتعال (المواد البترولية – أسطوانات الأكسجين –
            أسطوانات البوتجاز – العبوات المضغوطة سبراي - ...الخ).
          </li>
          <li style={{ fontSize: '10px' }} dir={'rtl'}>
            المعادن الثمينة والنقود وإيصالات الأمانة والشيك لحامله.
          </li>
          <li style={{ fontSize: '10px' }} dir={'rtl'}>
            الكيماويات والمواد المشعة او المؤكسدة والمعدات التي بها زئبق.
          </li>
          <li style={{ fontSize: '10px' }} dir={'rtl'}>
            الأسلحة والذخائر والمواد المتفجرة والألعاب النارية.
          </li>
        </ul>
      </ul>
      <h5 dir={'rtl'} style={{ marginTop: '-16px' }}>
        تتضمن هذه القوانين أمن وسلامة الأتوبيس والركاب، وعدم الإلتزام بهذه
        الضوابط يمكن أن يضع الشخص تحت طائلة القانون.
      </h5>
    </div>
  );
};

export default ShipmentRules;
