import gql from 'graphql-tag';

//TODO add facilities when implemented by BE
export default gql`
  query {
    allBusClasses {
      id
      name_en
      name_ar
      busTypes {
        id
        name
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;
