import gql from 'graphql-tag';

export const TOGGLE_OPEN_ROUND = gql`
  mutation toggleOpenRound($id: ID!, $open: Boolean!) {
    toggleOpenRound(id: $id, open: $open) {
      status
      message
    }
  }
`;

export default TOGGLE_OPEN_ROUND;
