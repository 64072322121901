import gql from 'graphql-tag';

export default gql`
  query allPromoCodes(
    $first: Int!
    $page: Int
    $promoCode: String
    $promoName: String
    $active: Boolean
    $startDate: Date
  ) {
    allPromoCodes(
      first: $first
      page: $page
      code: $promoCode
      name: $promoName
      start_date: $startDate
      active: $active
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
        currentPage
      }
      data {
        id
        start_date
        end_date
        trip_start_date
        trip_end_date
        name
        value
        value_type
        code
        first_use
        active
        max_quota
        total_revenue
        cost
        rounded
        min_tickets
        max_tickets
        offline
        payment_methods
        seat_types {
          id
          name_en
          name_ar
        }
        templates {
          id
          ref_code

        }
        cities {
          name_en
          pivot {
            id
            type
            city_id
          }
        }
      }
    }
  }
`;
