import gql from 'graphql-tag';

export const EXPORT_SHIFT_LOG_ACTIONS = gql`
  mutation exportStationShiftLogActions { 
    exportStationShiftLogActions {
      status
      message
      data {
        downloadable_link
      }
    }
  }
`;

export default EXPORT_SHIFT_LOG_ACTIONS;
