/**Component for creating new bus class with available seat types and facilities */
import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Select,
  Input,
  Button,
  Checkbox,
  Typography,
  Row,
  Col,
  Spin,
} from 'antd';
import { allSeatTypes, createBusClass } from 'services';
import { notify } from '../../utilities';

const { Title } = Typography;
const Option = Select.Option;
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

const BusClassForm = (props) => {
  const [handleCreateBusClass] = useMutation(createBusClass);

  const { data: seatTypesData, loading: busTypesLoading } = useQuery(
    allSeatTypes,
    {
      onError: (err) => {
        notify('error', 'account_opened_in_another_device');
        setTimeout(function () {
          window.location.replace('/');
        }, 3000);
      },
    }
  );

  /**Submit form for creating new bus class */
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    const {
      form: { validateFields },
    } = props;
    validateFields((err, values) => {
      if (!err) {
        const { englishName, arabicName, busTypeIds, facilities } = values;
        handleCreateBusClass({
          variables: {
            englishName,
            arabicName,
            busTypeIds,
            facilities,
          },
        });
        window.location.href = `/classes`;
      }
    });
  };

  const facilities = [
    { label: 'WC', value: 'Wc' },
    { label: 'AC', value: 'AC' },
    { label: 'Snacks', value: 'Snacks' },
    { label: 'Wifi', value: 'Wifi' },
    { label: 'Headphones', value: 'Headphones' },
  ];
  const {
    form: { getFieldDecorator },
  } = props;
  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 9 },
  };

  return (
    <>
      <Title level={2}>New Class</Title>
      {seatTypesData && (
        <Form {...formItemLayout} onSubmit={(e) => handleSubmit(e)}>
          <FormItem label="Code">
            {getFieldDecorator('code', {
              rules: [
                {
                  // required: true,
                  message: 'Please input the bus class code',
                },
              ],
            })(<Input placeholder="Please enter the bus class code" />)}
          </FormItem>
          <Form.Item label="English Name">
            {getFieldDecorator('englishName', {
              rules: [
                {
                  required: true,
                  message: 'Please input your English Name!',
                },
              ],
            })(<Input placeholder="English Name" />)}
          </Form.Item>
          <Form.Item label="Arabic Name">
            {getFieldDecorator('arabicName', {
              rules: [
                {
                  required: true,
                  message: 'Please input your Arabic Name!',
                },
              ],
            })(<Input placeholder="Arabic Name" />)}
          </Form.Item>

          <FormItem label="Assign Seat Types">
            {getFieldDecorator('busTypeIds', {
              rules: [
                {
                  required: true,
                  message: 'Please select at least one seat type',
                },
              ],
            })(
              <Select
                mode="multiple"
                placeholder="Please select"
                notFoundContent={busTypesLoading ? <Spin size="small" /> : null}
              >
                {seatTypesData &&
                  seatTypesData.allSeatTypes.map((seatType) => (
                    <Option value={seatType.id} key={seatType.id}>
                      {seatType.name_en}
                    </Option>
                  ))}
              </Select>
            )}
          </FormItem>

          <FormItem label="Facilities">
            {getFieldDecorator('facilities', {
              rules: [
                {
                  required: false,
                  message: 'Please select at least one bus salon',
                },
              ],
            })(<CheckboxGroup options={facilities} />)}
          </FormItem>
          <FormItem wrapperCol={{ span: 12, offset: 10 }}>
            <Row>
              <Col span={4}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </FormItem>
        </Form>
      )}
    </>
  );
};
const BusClass = Form.create()(BusClassForm);
export default BusClass;
