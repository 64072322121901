/** List all the available promo codes and an action button
 * to create a new one
 */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Spin, Table } from 'antd';
import styled from '@emotion/styled/macro';
import { GET_PROMO_CODE_DETAILS } from 'services';
import { useQuery } from '@apollo/react-hooks';
import { ListHeader, PrimaryTitle } from 'components';
import { notify, ValidateUser } from '../../utilities';
import { useTranslation } from 'react-i18next';

const ListItemTitle = styled.dt`
  font-weight: bold;
  margin-right: 10px;
`;

const ListItemContainer = styled.div`
  padding: 20px;
  display: flex;
`;

const PromoCodeDetails = () => {
  ValidateUser();
  const [t, i18n] = useTranslation();
  const { promoCodeId } = useParams();
  const { data, loading } = useQuery(GET_PROMO_CODE_DETAILS, {
    variables: {
      id: promoCodeId,
    },
  });

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: ['customer', 'name'],
    },
    {
      title: 'Customer Phone',
      dataIndex: ['customer', 'phone'],
    },
    {
      title: 'Trip Number',
      dataIndex: ['trip', 'ref_code'],
    },
    {
      title: 'Trip Date',
      dataIndex: ['trip', 'date'],
    },
    {
      title: 'Ticket Number',
      dataIndex: 'code',
    },
    {
      title: 'Original Price',
      key: 'originalPrice',
      render: (ticket) => {
        return `${ticket.original_price} L.E`;
      },
    },
    {
      title: 'Discount Cost',
      key: 'discountCost',
      render: (ticket) => {
        return `${ticket.discount_cost} L.E`;
      },
    },
    {
      title: 'Paid Amount',
      key: 'paidAmount',
      render: (ticket) => {
        return `${ticket.price} L.E`;
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  return (
    <Spin spinning={loading}>
      <ListHeader>
        <PrimaryTitle>{t('Promo Code Details')}</PrimaryTitle>
      </ListHeader>
      {data?.promoCode?.tickets[0] && (
        <ListItemContainer>
          <ListItemTitle>{t('Promo Code Burn Rate:')}</ListItemTitle>
          <dd>{data?.promoCode?.tickets[0]?.burn_rate} %</dd>
        </ListItemContainer>
      )}

      <Table
        bordered
        scroll={{ x: 400 }}
        dataSource={data?.promoCode?.tickets}
        columns={columns}
        loading={loading}
        rowKey="uuid"
      />
    </Spin>
  );
};

export default PromoCodeDetails;
