import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { AuthProvider } from 'authContext';
import { me, END_SHIFT, USER_LOGOUT } from 'services';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { notify } from 'utilities';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
const normalizeUserPermissions = (role) => {
  if (!role) return [];

  return role.permissions.map((permission) => permission.name);
};

const Auth = ({ children }) => {
  const localStorageToken = localStorage.getItem('token');
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const {t,i18n}= useTranslation();
  const [fetchingUser, setFetchingUser] = useState(!!localStorageToken);
  const [token, setToken] = useState(localStorageToken);
  const [endShift] = useMutation(END_SHIFT);
  const [userLogout] = useMutation(USER_LOGOUT);
  const { data: userData, refetch: refetchUser } = useQuery(me, {
    skip: !localStorageToken,
    onCompleted: () => {
      if (userData) {
        setIdentity(userData.me);
        setFetchingUser(false);
      }
    },
    onError: (err) => {
      if (err['graphQLErrors'][0]?.extensions?.validation) {
        const {
          extensions: { code, validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', t(message));
        }
      }
      localStorage.clear();
      console.info('err');
      window.location.reload();
    },
    notifyOnNetworkStatusChange: true,
  });

  const setSession = ({
    user: { id, name, ongoing_shift, station },
    role,
    token,
  }) => {
    const user = {
      id,
      permissions: normalizeUserPermissions(role),
      onGoingShift: ongoing_shift,
      name,
      station,
    };
    setAuthenticated(true);
    setUser(user);
    setToken(token);
    localStorage.setItem('token', token);
  };

  const setIdentity = (userData) => {
    const user = {
      id: userData.id,
      permissions: normalizeUserPermissions(userData.role),
      name: userData.name,
      station: userData.station,
    };
    setAuthenticated(true);
    setUser(user);
  };

  const logout = () => {
    setAuthenticated(false);
    setUser({
      permissions: [],
    });
    setToken('');
    localStorage.removeItem('token');
  };

  useEffect(() => {
    // check if there's an on going shift
    if (userData?.me.ongoing_shift?.id) {
      let logoutInterval;
      let notificationInterval;

      const shiftEndTime = moment(
        userData?.me.ongoing_shift?.end_time,
        'HH:mm:ss'
      );
      const currentTime = moment(moment().format('HH:mm:ss'), 'HH:mm:ss');

      // get time difference between current time and shift end time
      const shiftRemainTime = moment.duration(shiftEndTime.diff(currentTime));

      // get time difference in milliseconds
      let shiftRemainTimeMilliSeconds = shiftRemainTime.valueOf();

      // TODO: uncomment for handling 2 days shift
      // check if shift duration extends to next day
      // if (shiftRemainTimeMilliSeconds < 0) {
      //   // add 24hrs
      //   const oneDayMilliSecond = 86400000;
      //   shiftRemainTimeMilliSeconds += oneDayMilliSecond;
      // }

      // check if time difference is 30 min or more (1800000 ms)
      const thirtyMinInMilliSeconds = 1800000;
      if (shiftRemainTimeMilliSeconds >= thirtyMinInMilliSeconds) {
        // display notification before 30 min of shift end time
        const notificationTimeOut =
          shiftRemainTimeMilliSeconds - thirtyMinInMilliSeconds;
        notificationInterval = setTimeout(() => {
          notify('warning', 'Your Shift will end in 30 min', '', 0);
        }, notificationTimeOut);
      }

      const OneHourInMilliseconds = 3550000;
      const logoutTimeOut = shiftRemainTimeMilliSeconds + OneHourInMilliseconds;
      if (logoutTimeOut > 0) {
        // logout after 1 hour of shift end time
        logoutInterval = setTimeout(() => {
          if (userData?.me?.ongoing_shift?.id) {
            endShift({
              variables: {
                id: userData?.me.ongoing_shift?.id,
              },
            })
              .then(() => {
                userLogout();
                logout();
                notification.destroy();
                setUser(null);
              })
              .catch((err) => {
                const {
                  extensions: { validation },
                  message,
                } = err['graphQLErrors'][0];

                if (validation) {
                  for (let error in validation) {
                    notify('error', validation[error][0]);
                  }
                } else {
                  notify('error', message);
                }
              });
          }
        }, logoutTimeOut);
      } else {
        // end shift and logout instantly because it more than 1hr after shift end time
        if (userData?.me?.ongoing_shift?.id) {
          endShift({
            variables: {
              id: userData?.me.ongoing_shift?.id,
            },
          })
            .then(() => {
              userLogout();
              logout();
              notification.destroy();
              setUser(null);
            })
            .catch((err) => {
              const {
                extensions: { validation },
                message,
              } = err['graphQLErrors'][0];

              if (validation) {
                for (let error in validation) {
                  notify('error', validation[error][0]);
                }
              } else {
                notify('error', t(message));
              }
            });
        }
      }
      return () => {
        if (logoutInterval) clearTimeout(logoutInterval);

        if (notificationInterval) clearTimeout(notificationInterval);
      };
    }
  }, [user, userData, endShift, userLogout]);

  const newUser = {
    ...user,
    ...userData?.me,
    onGoingShift: userData?.me?.ongoing_shift,
  };

  const authProviderValue = {
    authenticated,
    user: newUser,
    token,
    setSession,
    setIdentity,
    logout,
    fetchingUser,
    refetchUser,
  };

  return <AuthProvider value={authProviderValue}>{children}</AuthProvider>;
};

export default Auth;
