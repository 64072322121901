/**Attempted component for creating new bus class
 * this component is currently not being used
 */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Input, Button, Row, Col, Typography } from 'antd';
import { SelectInput } from 'components';

const { Title } = Typography;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class HorizontalCreateBusSalon extends Component {
  types = ['FCv1', 'FCv2'];
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  onCancel = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values = {};
        console.log('Delete values of form: ', values);
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;

    // Only show error after a field is touched.
    const englishNameError =
      isFieldTouched('englishName') && getFieldError('englishName');
    const arabicNameError =
      isFieldTouched('arabicName') && getFieldError('arabicName');
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 9 },
    };
    const listDetails = { title: 'New Class' };
    return (
      <>
        <Title level={2}>New Class</Title>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item
            label="English Name"
            validateStatus={englishNameError ? 'error' : ''}
            help={englishNameError || ''}
          >
            {getFieldDecorator('englishName', {
              rules: [
                { required: true, message: 'Please input your English Name!' },
              ],
            })(<Input placeholder="English Name" />)}
          </Form.Item>
          <Form.Item
            label="Arabic Name"
            validateStatus={arabicNameError ? 'error' : ''}
            help={arabicNameError || ''}
          >
            {getFieldDecorator('arabicName', {
              rules: [
                {
                  required: true,
                  message: 'Please input your Arabic Name!',
                },
              ],
            })(<Input placeholder="Arabic Name" />)}
          </Form.Item>
          <Form.Item
            label="Assign Imported Type"
            validateStatus={arabicNameError ? 'error' : ''}
            help={arabicNameError || ''}
          >
            {getFieldDecorator('select-multiple', {
              rules: [
                {
                  type: 'array',
                  required: true,
                  message: 'Please input your Arabic Name!',
                },
              ],
            })(
              <SelectInput
                options={this.types}
                mode="multiple"
                placeholder="Select Types"
                style={{ width: '150px' }}
              />
            )}
          </Form.Item>
          <Form.Item label="Facilities">
            {getFieldDecorator('Facilities', {
              rules: [
                {
                  required: true,
                  message: 'Please Select Facilities',
                },
              ],
              initialValue: ['WC'],
            })(
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={8}>
                    <Checkbox value="Wifi">Wifi</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="DVD">DVD</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Headphones">Headphones</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Snacks">Snacks</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="AC">AC</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="WC">WC</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
            <Row>
              <Col span={4}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Add New
                </Button>
              </Col>
              <Col span={4}>
                <Button onClick={this.onCancel} type="danger" htmlType="reset">
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const CreateBusSalon = Form.create({ name: 'horizontal_new_bus' })(
  HorizontalCreateBusSalon
);

export default CreateBusSalon;
