/**List all system cashouts with an option to filter
 * cashouts based on  ticket number, refuned information*/
import React, { useState, useRef, useEffect } from 'react';
import {
  Table,
  Badge,
  Input,
  Space,
  Button,
  Popconfirm,
  Form,
  DatePicker,
  Select,
  Spin,
} from 'antd';
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled/macro';
import { colors, formatDate } from 'utilities';
import { GET_ACTIVE_LOCATIONS, GET_CASHOUTS } from 'services';
import { ListHeader, PrimaryTitle, Can, SelectUser } from 'components';
import ConfirmModal from './confirm-modal';
import StationsFilter from './station-filter';
import { notify } from 'utilities';
import EXPORT_SHIPPING_REPORT from '../../services/shipments/export-shipping-report';
import EXPORT_CASHOUT_REPORT from '../../services/cashout/export-casout-report';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

const CashoutsListContainer = styled.div`
  .ant-form-item-label {
    font-weight: 500;
    color: ${colors.primaryColor};

    label {
      color: ${colors.primaryColor};
      height: 100%;
    }
  }
`;

const TitleSearchWrapper = styled.div`
  background-color: ${colors.gray};
`;

const TicketSearchWrapper = styled.div`
  display: flex;
  justifycontent: space-between;
  .ant-form-item {
    margin: 16px;
  }

  .buttons {
    display: block;
    margin: 16px 0 0px 0px;
  }
`;

const DatePickerWrapper = styled.div`
  .ant-picker {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }
`;

const ButtonWrapper = styled.div`
  .ant-btn {
    width: 90px;
  }
`;

const FormItemWrapper = styled.div`
  margin-bottom: 0;
  flex: 1 1 0;

  .ant-form-item-label {
    text-align: left;
  }
`;

const FilterWrapper = styled.div`
  padding: 8px;
`;

const CashoutsList = () => {
  const { Option, OptGroup } = Select;
  const [t, i18n] = useTranslation();
  const nameLang =  i18n.language;
  const { data: locationsData, loading: loadingLocations } = useQuery(
    GET_ACTIVE_LOCATIONS
  );
  const [filteredValues, setFilteredValues] = useState({
    ticketId: '',
    created_at: '',
    refunded_at: '',
    station: '',
    foundedBy: '',
    confirmed_at_station: '',
  });
  const { activeLocations } = locationsData || { activeLocations: [] };
  const availableLocations = activeLocations.reduce((result, location) => {
    const cityName = location.city.name_en;
    const cityId = location.city.id;

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});

  const [visible, setVisible] = useState(false);
  const [cashout, setCashout] = useState(false);
  const [form] = Form.useForm();

  const searchInput = useRef(null);
  const [page, setPage] = useState(1);

  const { data, loading, refetch, error } = useQuery(GET_CASHOUTS, {
    variables: {
      first: 5,
      page: 1,
    },

    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (error) {
      let errorMessage = '';
      const messages = error?.graphQLErrors?.map(({ message }, i) => {
        errorMessage += message;
        return;
      });
      notify('error', errorMessage);
    }
  }, [error]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Order Number',

      render: (cashoutItem) => {
        if (cashoutItem.order) return cashoutItem.order?.order_number;
        else if (cashoutItem.shipping?.id)
          return 'Shipping# ' + cashoutItem.shipping?.id;

        return 'Not Assigned';
      },
      // dataIndex: ['order', 'order_number'],
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (cashoutItem) => `${cashoutItem.amount} L.E`,
    },
    {
      title: 'Refunded By',
      dataIndex: ['confirmed_by', 'name'],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterWrapper>
          <SelectUser
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <ButtonWrapper>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys, confirm, 'confirmed_by')
                }
                icon={<SearchOutlined />}
                size="small"
              >
                {t('Search')}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button onClick={() => handleReset(clearFilters)} size="small">
                {t('Reset')}
              </Button>
            </ButtonWrapper>
          </Space>
        </FilterWrapper>
      ),
      filteredValue: filteredValues.confirmedBy,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: 'Payment Method',
      render: (cashoutItem) => {
        if (cashoutItem.ticket) return t(cashoutItem.ticket?.payment_method);
        else if (cashoutItem.shipping)
          return t(cashoutItem.shipping?.payment_method);
      },
    },
    {
      title: 'Refund Station',
      key: 'refundStation',
      render: (cashoutItem) => {
        if (cashoutItem?.auto_confirmed === true) {
          return 'Online';
        }
        return nameLang === "en" ? cashoutItem?.confirmed_station?.name_en : cashoutItem?.confirmed_station?.name_ar;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterWrapper>
          <StationsFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <ButtonWrapper>
              <Button
                type="</FilterWrapper></FilterWrapper>y"
                onClick={() =>
                  handleSearch(selectedKeys, confirm, 'confirmed_station')
                }
                icon={<SearchOutlined />}
                size="small"
              >
                {t('Search')}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button onClick={() => handleReset(clearFilters)} size="small">
                {t('Reset')}
              </Button>
            </ButtonWrapper>
          </Space>
        </FilterWrapper>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filteredValue: filteredValues.station,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: 'Refund Date',
      key: 'confirmed_at',
      render: (cashoutItem) => formatDate(cashoutItem.confirmed_at),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterWrapper>
          <DatePickerWrapper>
            <DatePicker
              onChange={(date, dateString) =>
                setSelectedKeys(dateString || null)
              }
            />
          </DatePickerWrapper>
          <Space>
            <ButtonWrapper>
              <Button
                type="primary"
                onClick={() => {
                  handleSearch(selectedKeys, confirm, 'confirmed_at');
                }}
                icon={<SearchOutlined />}
                size="small"
              >
                {t('Search')}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button onClick={() => handleReset(clearFilters)} size="small">
                {t('Reset')}
              </Button>
            </ButtonWrapper>
          </Space>
        </FilterWrapper>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filteredValue: filteredValues.confirmedAt,
    },
    {
      title: 'Status',
      // dataIndex: 'status',
      render: (status) => (
        <Badge
          status={t(status.status )=== t('Pending') ? 'processing' : 'success'}
          text={t(status.status)}
        />
      ),
      filters: [
        { text: t('Confirmed'), value: 'Confirmed' },
        { text: t('Pending'), value: 'Pending' },
      ],
      filteredValue: filteredValues.status,
      filterMultiple: false,
    },
    {
      title: 'Operations',
      render: (cashoutItem) => {
        return cashoutItem.status === 'Confirmed' ? null : (
          <Can
            perform="CONFIRM_CASHOUT"
            yes={
              <a
                href="#!"
                onClick={() => {
                  setCashout(cashoutItem);
                  setVisible(true);
                }}
              >
                {t('Confirm')}
              </a>
            }
          />
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const expandedColumns = [
    {
      title: 'Trip Code',
      render: (cashoutItem) => {
        if (cashoutItem.ticket) return cashoutItem.ticket?.trip?.ref_code;
        else if (cashoutItem.shipping)
          return cashoutItem.shipping?.trip_route_line?.trip?.ref_code;
      },
    },
    {
      title: 'Trip Date',
      render: (cashoutItem) => {
        if (cashoutItem.ticket) return cashoutItem.ticket?.trip?.date;
        else if (cashoutItem.shipping)
          return cashoutItem.shipping?.trip_route_line?.trip?.date;
      },
    },
    {
      title: 'From',
      render: (cashoutItem) => {
        if (cashoutItem.ticket)
          return cashoutItem.ticket?.from_location?.name_en;
        else if (cashoutItem.shipping)
          return cashoutItem.shipping?.from_location?.name_en;
      },
    },
    {
      title: 'To',
      render: (cashoutItem) => {
        if (cashoutItem.ticket) return cashoutItem.ticket?.to_location?.name_en;
        else if (cashoutItem.shipping)
          return cashoutItem.shipping?.to_location?.name_en;
      },
    },
  ];

  const onChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setFilteredValues({
      ...filteredValues,
      status: filters.status,
      confirmedBy: filters['confirmed_by.name'],
      station: filters['confirmed_station.name_en'],
      confirmedAt: filters['confirmed_at'],
    });
    refetch({
      page: pagination.current,
      status: filters.status || undefined,
      confirmedBy: filters['confirmed_by.name'] || undefined,
      station: filters['confirmed_station.name_en'] || undefined,
      confirmedAt: filters['confirmed_at'] || undefined,
    });
  };

  const resetFilters = () => {
    form.resetFields();
    setFilteredValues({
      ...filteredValues,
      status: null,
      confirmedBy: null,
      station: null,
      confirmedAt: null,
      created_at: null,
    });
    refetch({
      page: page,
      status: undefined,
      confirmedBy: undefined,
      station: undefined,
      ticketCode: undefined,
      confirmedAt: undefined,
      created_at: undefined,
    });
  };
  const [exportReport, { loading: exporting }] = useMutation(
    EXPORT_CASHOUT_REPORT,
    {
      variables: {
        ticketCode: filteredValues.ticketId || '',
        created_at: filteredValues.created_at || '',
        confirmedAt: filteredValues.confirmedAt || '',
        confirmed_at_station: filteredValues.confirmed_at_station || '',
        foundedBy: filteredValues.foundedBy || '',
        status: filteredValues.status || '',
      },
    }
  );
  const handleExport = () => {
    exportReport()
      .then((res) => {
        const {
          data: {
            exportCasoutRepot: {
              message,
              status,
              data: { downloadable_link },
            },
          },
        } = res;
        var a = document.createElement('a');
        a.href = downloadable_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
          return;
        }
        notify('error', message);
      });
  };
  const onFinish = (values) => {
    const { ticketId, created_at, refunded_at, station, foundedBy } = values;

    setFilteredValues({
      ticketCode: ticketId || '',
      created_at: created_at ? created_at.toISOString().split('T')[0] : '',
      confirmedAt: refunded_at ? refunded_at.toISOString().split('T')[0] : '',
      confirmed_at_station: station || '',
      foundedBy: foundedBy || '',
    });
    refetch({
      ticketCode: ticketId || undefined,
      created_at: created_at
        ? created_at.toISOString().split('T')[0]
        : undefined,
      confirmedAt: refunded_at
        ? refunded_at.toISOString().split('T')[0]
        : undefined,
      confirmed_at_station: station || undefined,
      foundedBy: foundedBy || undefined,
      page: 0,
    });
  };

  return (
    <CashoutsListContainer>
      <TitleSearchWrapper>
        <ListHeader>
          <PrimaryTitle>{t('')}</PrimaryTitle>
          <div className={'export-button'}>
            <Can
              perform="EXPORT_TICKET_REPORT"
              yes={
                <Button
                  className={'ant-btn-danger'}
                  size="large"
                  loading={exporting}
                  onClick={() => handleExport()}
                >
                  {t('Export Report')}
                </Button>
              }
            />
          </div>
        </ListHeader>
      </TitleSearchWrapper>

      <TicketSearchWrapper>
        <Form
          form={form}
          onFinish={onFinish}
          layout={isMobile ? 'vertical' : 'inline'}
        >
          <Item name="ticketId" label={t('Find Ticket Number')}>
            <Input placeholder={t('Enter the ticket number')} />
          </Item>
          <Item name="foundedBy" label={t('Refunded By')}>
            <Input placeholder={t('Enter Name')} />
          </Item>
          <Item name="created_at" label={t('Created Date')}>
            <DatePicker placeholder={t('Select date')} />
          </Item>
          <Item name="refunded_at" label={t('Refunded Date')}>
            <DatePicker placeholder={t('Select date')} />
          </Item>
          <Item name="station" label={t('Station')}>
            <Select
              placeholder={t('Select Assigned Station')}
              size="middle"
              showSearch
              optionFilterProp="children"
              allowClear
            >
              {loadingLocations ? (
                <Option value={null} disabled style={{ textAlign: 'center' }}>
                  <Spin tip="Loading Locations..." />
                </Option>
              ) : (
                Object.keys(availableLocations).map((cityName, cityIndex) => (
                  <OptGroup key={`city_${cityIndex}`} label={cityName}>
                    {availableLocations[cityName].map(
                      (location, locationIndex) => (
                        <Option
                          key={`location_${cityIndex}_${locationIndex}`}
                          value={location.id}
                        >
                          {location.name_en}
                        </Option>
                      )
                    )}
                  </OptGroup>
                ))
              )}
            </Select>
          </Item>

          <Item>
            <Button type="primary" htmlType="submit" size="large">
              {t('Search')}
            </Button>
          </Item>

          <item className={'buttons'}>
            <Popconfirm
              title={t('Are you sure you want to reset all filters?')}
              okText={t('Yes')}
              cancelText={t('No')}
              onConfirm={() => resetFilters()}
              onCancel={() => {}}
            >
              <Button htmlType="button" type="primary" size="large" danger>
                {t('Reset All Filters')}
              </Button>
            </Popconfirm>
          </item>
        </Form>
      </TicketSearchWrapper>

      <Table
        bordered
        rowKey={(record) => record.id}
        dataSource={data?.allCashoutList?.data}
        columns={columns}
        loading={loading}
        onChange={onChange}
        scroll={{ x: 400 }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={expandedColumns}
              dataSource={[record]}
              pagination={false}
            />
          ),
        }}
        pagination={{
          total: data?.allCashoutList?.paginatorInfo?.total + 1,
          pageSize: 5,
          showSizeChanger: false,
        }}
      />

      <ConfirmModal
        visible={visible}
        setVisible={setVisible}
        refetch={refetch}
        cashoutId={cashout.id}
        page={page}
      />
    </CashoutsListContainer>
  );
};

export default CashoutsList;
