import gql from 'graphql-tag';

export const GET_DRIVERS = gql`
  query allDrivers($first: Int!, $page: Int, $driverId: Int) {
    allDrivers(
      first: $first
      page: $page
      id: $driverId
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
        currentPage
      }
      data {
        id
        name
        phone
        balance
        verification_code {
          code
          expiry_time
        }
      }
    }
  }
`;

export default GET_DRIVERS;
