import gql from 'graphql-tag';

const ASSIGN_PARTNERS = gql`
  mutation addPartnerSegmentAgents($segmentId: ID!, $usersList: [ID!]!) {
    addPartnerSegmentAgents(
      partner_segment_id: $segmentId
      users_ids: $usersList
    ) {
      status
      message
    }
  }
`;

export default ASSIGN_PARTNERS;
