import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
export const getCitiesCombination = (cities) => {
  const lang = i18next.language;

  const citiesCombination = [];
  for (let i = 0; i < cities.length - 1; i++) {
    for (let j = i + 1; j < cities.length; j++) {
      const fromCity = cities[i];
      const toCity = cities[j];
      citiesCombination.push({
        fromCity: lang === 'en' ? fromCity.name_en : fromCity.name_ar,
        toCity: lang === 'en' ? toCity.name_en : toCity.name_ar,
        id: `${fromCity.id},${toCity.id}`,
      });
    }
  }

  return citiesCombination;
};
