import React from 'react';

import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

import { DriverIcon, SeatIcon, WcIcon } from 'components';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Empty_pending_seat, me } from '../../../services';
import { notify } from '../../../utilities';
import i18next from 'i18next';

const GridContainer = styled.div`
  margin-right: 15px;
  .delete {
    display: contents;
    position: absolute;
    color: red;
    cursor: pointer;
  }
`;
const SeatsGrid = styled.div`
  text-align: center;
  display: inline-block;
  flex-direction: column;
  padding: 10px;
`;

const StyleIcon = css`
  display: inline-block;
  margin: 10px 12px;
  background-color: transparent;
  vertical-align: top;
  padding: 0;
  border: none;
  text-align: center;
`;

const Seat = styled.button`
  ${StyleIcon}
  fill: ${(props) => props.color};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  position: relative;

  svg {
    width: 35px;
    height: 35px;

    :active {
      fill: blue;
    }
  }

  p {
    margin-bottom: 0;
  }
    
`;

const NASeat = styled.div`
  width: 35px;
  height: 35px;
`;

const RowContainer = styled.div``;

const BusSalon = ({
  formValues,
  selectSeat,
  exit_modal,
  selectedSeats,
  allSeatTypes,
  reservedSeats,
  handleEditedBulkSeats,
  editBulkTicket,
  trip: {
    id: tripId,
    busSalon: { layout: seats },
  },
}) => {
  const { data: userData } = useQuery(me, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [empty_pending_seat] = useMutation(Empty_pending_seat);

const nameLang = i18next.language;

  let previousSeatType = '';
  return (
    <GridContainer>
      <SeatsGrid>
        {seats.map((row, idx) => {
          let currentSeatType;
          const firstSeatInRow = row.find((seat) => seat.type === 'SEAT');
          if (firstSeatInRow) {
            const { seat_type_id: firstSeatInRowType } = firstSeatInRow;
            const { name_en: seatTypeNameEn } = allSeatTypes.find(
              (seatType) => seatType.id === firstSeatInRowType
            );
            const { name_ar: seatTypeNameAr } = allSeatTypes.find(
              (seatType) => seatType.id === firstSeatInRowType
            );
            currentSeatType =
              seatTypeNameEn !== previousSeatType &&
              seatTypeNameAr !== previousSeatType
                ? nameLang === 'en'
                  ? seatTypeNameEn
                  : seatTypeNameAr
                : null;

            previousSeatType =
              currentSeatType !== null ? currentSeatType : previousSeatType;
          }

          return (
            <>
              {currentSeatType && <p>{currentSeatType}</p>}
              <RowContainer key={idx}>
                {row.map((seat) => {
                  const {
                    key,
                    numbering,
                    seat_type_id: seatTypeId,
                    type,
                  } = seat;
                  // const { name_en: seatTypeName } = allSeatTypes.find(
                  //   (seat) => seat.id == seatTypeId
                  // );
                  const reserved = reservedSeats.find(
                    (s) => s.seat_number == numbering
                  );
                  return (
                    <Seat
                      key={seat.id}
                      disabled={
                        type !== 'SEAT' ||
                        ((type !== 'SEAT' || reserved ? true : undefined) &&
                          reserved &&
                          (reserved.status !== 'Pending' ||
                            userData?.me?.role?.name != 'SUPER_ADMIN'))
                      }
                      title={
                        reserved &&
                        reserved.status === 'Pending' &&
                        'Set To Empty'
                      }
                      color={(() => {
                        if (selectedSeats.includes(numbering)) {
                          return 'blue';
                        } else if (reserved && reserved.status === 'Pending')
                          return 'yellow';
                        else if (reserved && reserved.status !== 'Pending')
                          return 'red';
                        else return 'lightgray';
                      })()}
                      onClick={() => {
                        if (
                          reserved &&
                          reserved.status === 'Pending' &&
                          !selectedSeats.includes(numbering)
                        ) {
                          empty_pending_seat({
                            variables: {
                              trip_id: tripId,
                              seat_number: numbering,
                            },
                          })
                            .then((response) => {
                              const {
                                data: { empty_pending_seat },
                              } = response;
                              exit_modal();
                              notify(
                                'success',
                                'seat is set to empty successfully '
                              );
                            })
                            .catch((err) => {
                              const {
                                extensions: { validation },
                                message,
                              } = err['graphQLErrors'][0];

                              if (validation) {
                                for (let error in validation) {
                                  notify('error', validation[error][0]);
                                }
                              } else {
                                notify('error', message);
                              }

                              exit_modal();
                            });
                        } else {
                          if (editBulkTicket) {
                            if (handleEditedBulkSeats(numbering))
                              selectSeat(numbering, seatTypeId);
                          } else {
                            selectSeat(numbering, seatTypeId);
                          }
                        }
                      }}
                    >
                      {type === 'SEAT' && <SeatIcon />}
                      {type === 'WC' && <WcIcon />}
                      {type === 'NA' && <NASeat />}
                      {type === 'DRIVER' && <DriverIcon />}
                      <p>{numbering}</p>
                      {reserved &&
                        reserved.status === 'Pending' &&
                        !selectedSeats.includes(numbering) &&
                        userData?.me?.role?.name == 'SUPER_ADMIN' && (
                          <div>
                            <i className={'delete'}>X</i>
                          </div>
                        )}
                    </Seat>
                  );
                })}
              </RowContainer>
            </>
          );
        })}
      </SeatsGrid>
    </GridContainer>
  );
};
export default BusSalon;
