import gql from 'graphql-tag';

const DEACTIVATE_CITY = gql`
  mutation deactivateCity($id: ID!) {
    deactivateCity(id: $id) {
      id
      name_en
    }
  }
`;

export default DEACTIVATE_CITY;
