import gql from 'graphql-tag';

const GET_JOBS = gql`
  query listJobs($first: Int!, $page: Int) {
    listJobs(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        link
        model
        status
        user
        created_at
      }
    }
  }
`;

export default GET_JOBS;
