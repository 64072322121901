import gql from 'graphql-tag';

const ADD_SEGMENT_CREDIT = gql`
  mutation addPartnerSegmentCredit($partnerSegmentId: ID!, $amount: Float!) {
    addPartnerSegmentCredit(
      partner_segment_id: $partnerSegmentId
      amount: $amount
    ) {
      status
      message
    }
  }
`;

export default ADD_SEGMENT_CREDIT;
