import gql from 'graphql-tag';

export const CancelPendingOrder = gql`
  mutation AdminCancelUnPaidOrder($order_number: String!) {
    AdminCancelUnPaidOrder(order_number: $order_number) {
      status
      message
    }
  }
`;
