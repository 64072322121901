import gql from 'graphql-tag';

const UPDATE_SHIPPING_ORDER = gql`
  mutation ShippingOrderApplyNextAction($id: ID!, $receiverNationalId: String) {
    ShippingOrderApplyNextAction(
      id: $id
      receiver_national_id: $receiverNationalId
    ) {
      status
      message
      data {
        id
        total_price
        order_number
        sender {
          id
          name
          phone
          national_id
        }
        receiver {
          id
          name
          phone
          national_id
        }
        status {
          value
          label
        }
        next_action_label
      }
    }
  }
`;

export default UPDATE_SHIPPING_ORDER;
