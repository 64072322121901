import gql from 'graphql-tag';

export const GET_USER_ROLES = gql`
  query roles {
    roles {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

export default GET_USER_ROLES;
