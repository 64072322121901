import gql from 'graphql-tag';

const GET_CATEGORIES = gql`
  query {
    shippingCategories {
      id
      name
    }
  }
`;

export default GET_CATEGORIES;
