import gql from 'graphql-tag';

export const MARK_INVALID = gql`
  mutation markInvalid($id: ID!) {
    markInvalid(id: $id) {
      status
      message
    }
  }
`;

export default MARK_INVALID;
