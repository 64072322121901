import React, { useState, useEffect, useContext } from 'react';

import { Layout, Menu } from 'antd';
import { AuthContext } from 'authContext';
import styled from '@emotion/styled/macro';
import { Link, useLocation, matchPath, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next'; 

import routes from './routes';
const { Sider } = Layout;
const { SubMenu, Item } = Menu;


const MenuWrapper = styled.div`
  .submenu.ant-menu-submenu > .ant-menu {
    background-color: #e7e7e7;
  }

  .submenu.ant-menu-submenu
    ul
    li.menuItem.ant-menu-item.ant-menu-item-only-child {
      font-weight:bold;
      display : flex
  }

  .submenu.ant-menu-submenu > .ant-menu-submenu-title {
    font-weight: 500;
    margin: 0;
    border-bottom: 1px solid #e3e3e3;
    font-weight:bold;
    display : flex;
    padding-right: ${props=>props.lang==='ar'? '16px':'0px'};
    transition: padding-right 0s;
  }
  .submenu.ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow::after ,.submenu.ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow::before{
    left:${props=>props.lang==='ar'? '-170px':''}
  }
  .ant-menu-inline .ant-menu-item-selected::after{
    right:${props=>props.lang==='ar'? '207px':''};
  }

  ul li.menuItem.ant-menu-item.ant-menu-item-only-child:not(:last-of-type) {
    font-weight: 500;
    margin: 0;
    padding: 0 24 12 16;
    border-bottom: 1px solid #e3e3e3;
    font-weight:bold;
    display : flex
  }

  ul li.menuItem.ant-menu-item.ant-menu-item-only-child:last-of-type {
    font-weight: 500;
    margin: 0;
    padding: 0 24 0 16;
    font-size: 15px;
  }
`;

const AppSider = () => {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [t, i18n] = useTranslation();
  const { language } = i18next;

  const [selectedKey, setSelectedKey] = useState('');
  const [openedKey, setOpenedKey] = useState('');

  const permissions =
    authContext && authContext.user && authContext.user.permissions;

  useEffect(() => {
    for (let i = 0; i < routes.length; i++) {
      const matchedPath = matchPath(location.pathname, {
        path: routes[i].path,
        exact: true,
        strict: false,
      });

      if (matchedPath) {
        setOpenedKey('');
        setSelectedKey(matchedPath);
        return;
      } else if (routes[i].subItems) {
        const matchedRoute = routes[i].subItems.find((item) =>
          matchPath(location.pathname, {
            path: item.path,
            exact: true,
            strict: false,
          })
        );
        if (matchedRoute) {
          setOpenedKey(`${i}`);
          setSelectedKey(matchedRoute);
          return;
        }
      }
      setSelectedKey('');
      setOpenedKey('');
    }
  }, [location]);

  const onOpenChange = (keys) => {
    setOpenedKey(keys[keys.length - 1]);
  };

  return (

    <Sider width="210" style={{ backgroundColor: '#fff' }}>
      <MenuWrapper lang={language}>
        {' '}
        <Menu
          mode="inline"
          style={{ backgroundColor: '#F2F2F2', borderRight: 0 }}
          defaultSelectedKeys={['/']}
          selectedKeys={[selectedKey && selectedKey.path]}
          openKeys={[openedKey]}
          onOpenChange={onOpenChange}
        >
          {routes.map((route, index) => {
            const routePermission = route.permission;
            const isRouteAllowed =
              routePermission && permissions
                ? permissions.includes(routePermission)
                : true;
            return !route.subItems ? (
              isRouteAllowed ? (
                <Item className="menuItem" key={route.path}>
                  <Link to={route.path}>
                    <span>{t(route.name)}</span>
                  </Link>
                </Item>
              ) : null
            ) : isRouteAllowed ? (
              <SubMenu className="submenu" key={index} title={t(route.name)}>
                {route.subItems.map((route) => {
                  const routePermission = route.permission;
                  const isRouteAllowed =
                    routePermission && permissions
                      ? permissions.includes(routePermission)
                      : true;
                  return isRouteAllowed ? (
                    <Item className="menuItem" key={route.path}>
                      <Link to={route.path}>
                        <span>{t(route.name)}</span>
                      </Link>
                    </Item>
                  ) : null;
                })}
              </SubMenu>
            ) : null;
          })}
        </Menu>
      </MenuWrapper>

    </Sider>
  );
};

export default withRouter(AppSider);
