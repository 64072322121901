import gql from 'graphql-tag';

const GET_TICKET = gql`
  query getTicket($id: ID!) {
    getTicket(id: $id) {
      id
      uuid
      price
      payment_method
      status
      created_at
      seat_number
      original_price
      price
      discount_cost
      burn_rate
      from_date
      from_time
      to_time
      to_date
      replaced_by
      updated_to {
        id
        price
      }
      old_ticket {
        id
        price
      }
      from_location {
        id
        name_en
        name_ar
        city {
          id
          name_en
          name_ar
        }
      }
      to_location {
        id
        name_en
        name_ar
        city {
          id
          name_en
          name_ar
        }
      }
      seat_type {
        id
        name_en
        name_ar
      }
      customer {
        id
        name
        phone
        email
      }
      trip {
        id
        date
        time
        ref_code
      }
      promo_code {
        id
        code
        value
        value_type
      }
      parent_ticket {
        id
      }
      new_tickets {
        id
      }
      order {
        id
        purchase_channel
        paid_by {
          ... on User {
            id
            name
          }
          ... on Driver {
            id
            name
          }
        }

        booked_by {
          ... on User {
            id
            name
          }
          ... on Customer {
            id
            name
          }
        }
      }
    }
  }
`;

export default GET_TICKET;
