import gql from 'graphql-tag';

const GET_CITY = gql`
  query city($id: ID!) {
    city(id: $id) {
      id
      code
      name_en
      name_ar
      lat
      long
      image
      is_seasonal
      seasonal_price
    }
  }
`;

export default GET_CITY;
