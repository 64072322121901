import gql from 'graphql-tag';

export default gql`
  mutation createCart ($customer_id:Int){
    createCart (customer_id:$customer_id){
      id
      token
      created_at
      updated_at
    }
  }
`;
