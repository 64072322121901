import React from 'react';
import { Card, Table } from 'antd';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Reference Code',
    dataIndex: 'ref_code',
  },
  {
    title: 'License Number',
    dataIndex: 'licence_no',
  },
  {
    title: 'Mileage Number',
    dataIndex: 'mileage_no',
  },
];

const DriversInfo = ({ data }) => {
  return (
    <Card>
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ x: 400 }}
      />
    </Card>
  );
};

export default DriversInfo;
