import gql from 'graphql-tag';

export const busSalonList = gql`
  query busSalons($first: Int!, $page: Int!) {
    busSalons(first: $first, page: $page) {
      paginatorInfo {
        total
      }
      data {
        id
        name
        seat_types {
          id
          name_en
        }
        layout {
          key
          rows
          cols
          type
          seat_type_id
          numbering
        }
      }
    }
  }
`;

export default busSalonList;
