import gql from 'graphql-tag';

const SEARCH_DRIVERS = gql`
  query searchDrivers($phone: String!) {
    searchDrivers(phone: $phone) {
      id
      name
      phone
    }
  }
`;

export default SEARCH_DRIVERS;
