import React from 'react';
import { Card, Table } from 'antd';
import { useTranslation } from 'react-i18next';

const TripInfo = ({ cardTitle, tripTemplate }) => {
  const { timeLines } = tripTemplate;
  const [t, i18n] = useTranslation();
  const nameLang = `name_${i18n.language}`;

  const columns = [
    {
      title: 'Location',
      dataIndex: ['location', nameLang],
    },
    {
      title: 'Day',
      // dataIndex: 'day',
      render : (record)=> t(record.day)
    },
    {
      title: 'Time',
      dataIndex: 'time',
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  if (!timeLines || !timeLines.length) return;
  const timeLineDataSource = timeLines.flatMap((timeLine) => {
    const { timeLineSlots } = timeLine;
    return timeLineSlots.map((t, idx) => ({
      ...t,
      key: idx,
    }));
  });
  return (
    <Card title={cardTitle}>
      <Table
        scroll={{ x: 400 }}
        dataSource={timeLineDataSource}
        columns={columns}
        pagination={false}
      />
    </Card>
  );
};

export default TripInfo;
