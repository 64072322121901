import gql from 'graphql-tag';

const GET_CASHCOLLECTION_PRINT = gql`
  query getCashPrint($id: [ID!]) {
    getCashPrint(id: $id) {
      id
      amount
      created_at
      printed_at
      shift_id
      station_id
      user {
        id
        name
        phone
      }
      driver {
        id
        name
        phone
      }
      station {
        id
        name_en
      }
      shift {
        id
        shift_log {
          id
          shift {
            id
            name
            code
          }
        }
      }
    }
  }
`;

export default GET_CASHCOLLECTION_PRINT;
