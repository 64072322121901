/**Component the responsible for filtering listed cashouts
 * based on stations
 */
import React from 'react';
import styled from '@emotion/styled/macro';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Select, Spin, Row, Col, Popconfirm } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  CREATE_USER,
  GET_USER_ROLES,
  GET_ACTIVE_LOCATIONS,
  UPDATE_USER,
} from 'services';
import { ListHeader, PrimaryTitle } from 'components';
import {
  notify,
  formatRole,
  FormContainer,
  ActionButton,
  CancelButton,
} from 'utilities';

const { Item } = Form;
const { Option, OptGroup } = Select;

const NewUserContainer = styled.section`
  ${FormContainer}
  width: 700px;

  ${ActionButton}
  ${CancelButton}
`;

const StationsFilter = ({ setSelectedKeys, selectedKeys, forwardedRef }) => {
  const { data: locationsData, loading: loadingLocations } = useQuery(
    GET_ACTIVE_LOCATIONS,
    {
      onError: (err) => {
        notify('error', 'account_opened_in_another_device');
        setTimeout(function () {
          window.location.replace('/');
        }, 3000);
      },
    }
  );

  const { activeLocations } = locationsData || { activeLocations: [] };
  const availableLocations = activeLocations.reduce((result, location) => {
    const cityName = location.city.name_en;
    const cityId = location.city.id;

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});

  const onChange = (value) => {
    if (!value) {
      setSelectedKeys(null);
    }
  };

  return (
    <Select
      placeholder="Search by station"
      showSearch
      optionFilterProp="children"
      allowClear
      onSelect={(value) => setSelectedKeys(value)}
      value={selectedKeys}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
      onChange={onChange}
      ref={forwardedRef}
    >
      {loadingLocations ? (
        <Option value={null} disabled style={{ textAlign: 'center' }}>
          <Spin tip="Loading Locations..." />
        </Option>
      ) : (
        Object.keys(availableLocations).map((cityName, cityIndex) => (
          <OptGroup key={`city_${cityIndex}`} label={cityName}>
            {availableLocations[cityName].map((location, locationIndex) => (
              <Option
                key={`location_${cityIndex}_${locationIndex}`}
                value={location.id}
              >
                {location.name_en}
              </Option>
            ))}
          </OptGroup>
        ))
      )}
    </Select>
  );
};

export default StationsFilter;
