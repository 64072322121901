import gql from 'graphql-tag';

export const CANCEL_TICKET = gql`
  mutation adminCancelTicket($id: ID!, $deduction_amount: Float!) {
    adminCancelTicket(id: $id, deduction_amount: $deduction_amount) {
      status
      message
      message_ar
    }
  }
`;

export default CANCEL_TICKET;
