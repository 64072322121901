import gql from 'graphql-tag';

export const CHECK_PRINT_TICKET_LABEL = gql`
  query checkPrintTicketLuggage($ticketId: [ID!], $luggageNumber: Int!) {
    checkPrintTicketLuggage(id: $ticketId, luggage_number: $luggageNumber) {
      status
      message
      ticketIds
      tickets {
        id
        code
        created_at
        seat_number
        seat_type {
          id
          name_en
          name_ar
        }
        trip {
          id
          date
          time
          ref_code
        }
      }
    }
  }
`;

export default CHECK_PRINT_TICKET_LABEL;
