/**Filter component to filter orders by phone number, order number or order status */
import React, { useState } from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, Select } from 'antd';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import GET_AGENTS from '../../services/agent/get-agents';
import { paymentMethods } from '../../config/paymentMethods';

const { Option } = Select;

const Filter = ({
  form: { getFieldDecorator, validateFields, setFieldsValue, resetFields },
  refetch,
  setFilteredValues,
  agent_list,
}) => {
  const affiliates = ['waffarx', 'checkmybus'];

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const {
          orderNumber,
          customerPhone,
          status,
          payment_method,
          agent_id,
          transaction_id,
          affiliate,
        } = values;

        const filter_values = {
          orderNumber: orderNumber || undefined,
          transaction_id: transaction_id || undefined,
          customerPhone: customerPhone || undefined,
          status: status === 'All Orders' ? undefined : status,
          payment_method:
            payment_method === 'All Payments' ? undefined : payment_method,
          agent_id: agent_id === 'all' ? undefined : agent_id,
          affiliate: affiliate === 'All Referrals' ? undefined : affiliate,
        };
        setFilteredValues({
          ...filter_values,
        });
        console.info(values);
        refetch({
          ...filter_values,
        });
      }
    });
  };

  const handleReset = () => {
    resetFields();
    const filter_values = {
      orderNumber: undefined,
      customerPhone: undefined,
      status: undefined,
      payment_method: undefined,
      agent_id: undefined,
      transaction_id: undefined,
      affiliate: undefined,
    };
    setFilteredValues({
      ...filter_values,
    });
    refetch({
      ...filter_values,
    });
  };

  const [t, i18n] = useTranslation();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const statusOptions = [
    'All Orders',
    'Paid',
    'Pending',
    'Cancelled',
    'Expired',
  ];
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item label={t('Order Number')}>
        {getFieldDecorator('orderNumber')(
          <Input size="large" placeholder={t('Enter Order Number')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('TransactionID')}>
        {getFieldDecorator('transaction_id')(
          <Input size="large" placeholder={t('Enter_Transaction_Id')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('Phone Number')}>
        {getFieldDecorator('customerPhone', {
          rules: [
            {
              // len: 11,
              message: t('Invalid Phone Number'),
              // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
            },
          ],
        })(
          <Input size="large" placeholder={t('Enter Customer Phone')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('Payment method')}>
        {getFieldDecorator('payment_method')(
          <Select
            size="large"
            defaultValue="All Payments"
            placeholder={t('payment method')}
          >
            {paymentMethods.map((method, index) => (
              <Option key={method}>{t(method)}</Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('Order Status')}>
        {getFieldDecorator('status', {
          initialValue: t('All Orders'),
        })(
          <Select size="large">
            {statusOptions.map((option, idx) => (
              <Option key={idx} value={option}>
                {t(option)}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('Referrals')}>
        {getFieldDecorator('affiliate', {
          initialValue: t('All Referrals'),
        })(
          <Select size="large">
            {affiliates.map((option, idx) => (
              <Option key={idx} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t('Agent')}>
        {getFieldDecorator('agent_id', {
          initialValue: t('all'),
        })(
          <Select size="large">
            <Option key={0} value={'all'}>
              {t('All Agents')}
            </Option>
            {agent_list &&
              agent_list.all_agents.map((agent, idx) => (
                <Option key={idx} value={agent.id}>
                  {t(agent.user_name)}
                </Option>
              ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" htmlType="submit">
          {t('Filter Records')}
        </Button>
        <Button type="primary" size="large" onClick={handleReset}>
          {t('Reset Fields')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create({})(Filter);
