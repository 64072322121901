import gql from 'graphql-tag';

const GET_USERLISTS = gql`
  query usersList(
    $first: Int!
    $page: Int
    $id: ID
    $phone: String
    $role_id: Int
    $station: Int
  ) {
    users(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
      id: $id
      phone: $phone
      role_id: $role_id
      station: $station
    ) {
      paginatorInfo {
        count
        total
        __typename
      }
      data {
        id
        name
        phone
        email
        is_verified
        active
        __typename
      }
      __typename
    }
  }
`;

export default GET_USERLISTS;
