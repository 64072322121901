import gql from 'graphql-tag';

export const MARK_AS_READ = gql`
  mutation markRead($id: ID!, $comment: String!, $status: Boolean!) {
    markRead(id: $id, comment: $comment, status: $status) {
      status
      message
    }
  }
`;

export default MARK_AS_READ;
