import gql from 'graphql-tag';

const GET_ACTIVE_CITIES = gql`
  query {
    activeCities {
      id
      code
      name_en
      name_ar
      lat
      long
      is_active
    }
  }
`;

export default GET_ACTIVE_CITIES;
