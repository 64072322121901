import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Divider,
  Checkbox,
  Table,
  Select,
  TimePicker,
  Popconfirm,
} from 'antd';
import { weekdays } from 'moment';

import { useTranslation } from 'react-i18next';

const Option = Select.Option;
const FormItem = Form.Item;

const days = weekdays();

const TimelineTableInstance = ({
  arrangedSelectedLocations,
  locations,
  allBusSalons,
  form: { getFieldDecorator, getFieldValue, setFieldsValue },
}) => {
  const [t,i18n]=useTranslation()
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dataSource, setDataSource] = useState([
    {
      key: Date.now(),
    },
  ]);

  const deleteRow = (index) => {
    setFieldsValue({
      [`timeLines.create[${index}].bus_salon_id`]: undefined,
    });
    const filteredDataSource = dataSource.filter((el, i) => i !== index);
    setDataSource(filteredDataSource);
  };

  useEffect(() => {
    // TODO: refactor -- bad performance
    const selectedLocationObj = arrangedSelectedLocations
      .map((selectedCity) => {
        return selectedCity.locations.map((selectedLocation) => {
          return locations.find((location) => {
            return (
              selectedLocation === location.name_en &&
              location.city.name_en === selectedCity.city
            );
          });
        });
      })
      .flat();

    const dynamicColumns = selectedLocationObj.map((location, locationIdx) => ({
      title: location.name_en,
      render: (name, row, i) => {
        const inTwoDays = getFieldValue(`timeLines.create[${i}].inTwoDays`);
        const prevInTwoDays =
          locationIdx !== 0 &&
          getFieldValue(
            `timeLines.create[${i}].timeLineSlots.create[${
              locationIdx - 1
            }].inTwoDays`
          );
        const prevTiming =
          locationIdx !== 0 &&
          getFieldValue(
            `timeLines.create[${i}].timeLineSlots.create[${
              locationIdx - 1
            }].time`
          );
        const currentInTwoDays =
          inTwoDays &&
          getFieldValue(
            `timeLines.create[${i}].timeLineSlots.create[${locationIdx}].inTwoDays`
          );
        return (
          <>
            <FormItem>
              {getFieldDecorator(
                `timeLines.create[${i}].timeLineSlots.create[${locationIdx}].location_id`,
                {}
              )(<></>)}
            </FormItem>
            <FormItem>
              {getFieldDecorator(
                `timeLines.create[${i}].timeLineSlots.create[${locationIdx}].time`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                    {
                      validator: (_, value, callback) => {
                        if (
                          prevTiming &&
                          !currentInTwoDays &&
                          prevTiming.valueOf() > value.valueOf()
                        ) {
                          callback(
                            'Arrival date cannot be after the departure date'
                          );
                        } else {
                          callback();
                        }
                      },
                    },
                  ],
                }
              )(
                <TimePicker
                  format={'HH:mm'}
                  onChange={() =>
                    setFieldsValue({
                      [`timeLines.create[${i}].timeLineSlots.create[${locationIdx}].location_id`]: location.id,
                    })
                  }
                />
              )}
            </FormItem>
            {inTwoDays && (
              <>
                <Divider type="horizontal" />
                <FormItem>
                  {getFieldDecorator(
                    `timeLines.create[${i}].timeLineSlots.create[${locationIdx}].inTwoDays`,
                    {
                      initialValue: prevInTwoDays ? true : false,
                      valuePropName: 'checked',
                    }
                  )(
                    <Checkbox disabled={prevInTwoDays} value={true}>
                      In Two Days
                    </Checkbox>
                  )}
                </FormItem>
              </>
            )}
          </>
        );
      },
    }));

    setDynamicColumns(dynamicColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrangedSelectedLocations]);

  const columns = [
    {
      title: t('Bus Salon'),
      key: 'bus-salon',
      render: (name, row, i) => (
        <FormItem>
          {getFieldDecorator(`timeLines.create[${i}].bus_salon_id`, {
            rules: [
              {
                required: true,
                message: 'This field is required!',
              },
            ],
          })(
            <Select style={{ width: 120 }}>
              {allBusSalons.map((option, idx) => (
                <Option key={idx} value={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>
      ),
    },
    ...dynamicColumns,
  ];

  return (
    <>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: true }}
      />
    </>
  );
};

const WrappedTimelineTable = Form.create({
  onValuesChange(props, _, values) {
    const { allBusSalons, setSelectedBusSalons } = props;
    const {
      timeLines: { create: timeLinesArray },
    } = values;
    const busSalons = [];
    const busSalonsIds = timeLinesArray
      .filter((tl) => tl.bus_salon_id !== undefined)
      .map((tl) => tl.bus_salon_id);
    busSalonsIds.forEach((busSalonId) => {
      const busSalon = allBusSalons.find(
        (busSalon) => busSalon.id === busSalonId
      );
      busSalons.push(busSalon);
    });
    setSelectedBusSalons(busSalons);
  },
})(TimelineTableInstance);
export default WrappedTimelineTable;
