import gql from 'graphql-tag';

export const STOP_TRIP = gql`
  mutation stopTrip($id: ID!) {
    stopTrip(id: $id) {
      status
      message
      message_ar
    }
  }
`;

export default STOP_TRIP;
