import gql from 'graphql-tag';

export const CONFIRM_CASHOUT = gql`
  mutation cashoutConfirm($id: ID!, $ticket_code: String!) {
    cashoutConfirm(id: $id, ticket_code: $ticket_code) {
      status
      message
      message_ar
    }
  }
`;

export default CONFIRM_CASHOUT;
