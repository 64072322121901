import styled from '@emotion/styled/macro';
import { colors } from '../../../utilities/theme';
import { isMobile } from 'react-device-detect';

/**
 * Base style for button component which change padding based on size prop(largeButton, mediumButton, smallButton)
 */

const Button = styled.button`
  border-radius: 0;
  font-size: 16px;
  padding: 0 30px;
  line-height: 1;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  color: #fff;
  &:focus {
    outline: none;
  }
`;

/**
 * Styled component which inherit style of Button and change its background color to the secondary color
 */

const PrimaryButton = styled(Button)`
  background-color: ${colors.primaryColor};
  border-color: ${colors.primaryColor};
  border-radius: 30px;
  font-size: ${isMobile ? '12px' : '16px'}
  align-items: 'center';
  margin-top: 10px;
  height 40px;
  margin-bottom: 10px;
  text-align: 'center';
`;

/**
 * Styled component which inherit style of Button and change its text and border color to the pirmary color
 */

const SecondaryButton = styled(Button)`
  min-width: 100px;
  background-color: ${colors.gary};
  border-color: ${colors.gary};
  padding: 15px 35px;
`;

export { PrimaryButton, SecondaryButton };

// TODO: Add hover, active, focus and disabled state for the buttons.
