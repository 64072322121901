import React, { PureComponent } from 'react';
import { Query } from '@apollo/react-components';
import PropTypes from 'prop-types';

import { GET_ACTIVE_CITIES } from 'services';
import { handleResponse } from 'utilities';

class ActiveCitiesSelectFactory extends PureComponent {
  render() {
    const { setCities } = this.props;
    return (
      <Query
        query={GET_ACTIVE_CITIES}
        onCompleted={response => {
          setCities(response.activeCities);
        }}
      >
        {({ error }) => <>{error && handleResponse('error', error.message)}</>}
      </Query>
    );
  }
}

export default ActiveCitiesSelectFactory;

ActiveCitiesSelectFactory.propTypes = {
  setCities: PropTypes.func,
};
