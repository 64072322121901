import React, { Fragment, useMemo } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, InputNumber, Col, Row } from 'antd';
import { getCitiesCombination } from 'utilities';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const formItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const SeatPricing = ({
  selectedCities: cities,
  busTypes,
  form: { getFieldDecorator },
}) => {
  // https://stackoverflow.com/a/56757215/8373219
  const [t,i18n]=useTranslation()
  const nameLang= i18next.language;
  const busSalons = busTypes.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i
  );

  const citiesCombination = useMemo(() => getCitiesCombination(cities), [
    cities,
  ]);

  return (
    <Row gutter={16}>
      {citiesCombination.map((trip) => (
        <Col key={trip.id} span={8}>
          <Card title={`${t("Seat pricing")} ${t("from")} ${trip.fromCity}  ${t("to")} ${trip.toCity}`}  style={{ width: 400 }}>
            {busSalons.map((busSalon) => (
              <Fragment key={busSalon.id}>
                <p>{busSalon.name}</p>
                {busSalon.seat_types.map((seatType) => {
                  return (
                    <Form.Item
                      key={`${trip.id}-${busSalon.id}-${seatType.id}`}
                      label={nameLang==="en"? seatType.name_en : seatType.name_ar}
                      {...formItemLayout}
                    >
                      {getFieldDecorator(
                        `${trip.id}[${busSalon.id}][${seatType.id}]`,
                        {
                          rules: [
                            {
                              required: true,
                              message: t('Please enter the seat pricing!'),
                            },
                          ],
                        }
                      )(<InputNumber min={0} max={1200} />)}
                    </Form.Item>
                  );
                })}
              </Fragment>
            ))}
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Form.create({})(SeatPricing);
