import gql from 'graphql-tag';

const CREATE_LOCATION = gql`
  mutation createLocation(
    $code: String!
    $name_en: String!
    $name_ar: String!
    $lat: Float!
    $long: Float!
    $type: LocationType!
    $city_id: ID!
    $driver_location: Boolean!
  ) {
    createLocation(
      code: $code
      name_en: $name_en
      name_ar: $name_ar
      lat: $lat
      long: $long
      type: $type
      city_id: $city_id
      driver_location: $driver_location
      is_active: Active
    ) {
      id
      code
      name_en
      name_ar
      lat
      long
      type
      city {
        name_en
      }
    }
  }
`;

export default CREATE_LOCATION;
