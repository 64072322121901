import gql from 'graphql-tag';

const TOGGLE_PRE_PAID = gql`
  mutation togglePrePaid($id: ID!) {
    togglePrePaid(id: $id) {
      id
    }
  }
`;

export default TOGGLE_PRE_PAID;
