import gql from 'graphql-tag';

export const CANCEL_MULTI_TICKETS = gql`
  mutation adminCancelMultiTickets($id: [ID!]) {
    adminCancelMultiTickets(id: $id) {
      status
      message
      message_ar
    }
  }
`;

export default CANCEL_MULTI_TICKETS;
