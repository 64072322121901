import React from 'react';
import { ListHeader, PrimaryTitle } from 'components';

const Unauthorized = () => {
  return (
    <>
      <ListHeader>
        <PrimaryTitle>Error 401</PrimaryTitle>
      </ListHeader>
      <p>Unauthorized ... </p>
      <a href={'/'}>Redirect to Home Page!!</a>
    </>
  );
};

export default Unauthorized;
