import gql from 'graphql-tag';

export const GET_CUSTOMER_CASHOUT = gql`
  query searchCashoutList(
    $customerId: ID!
    $status: [CashoutStatus!]
    $confirmedAt: Date
    $station: ID
    $confirmedBy: ID
    $ticketCode: String
    $first: Int!
    $page: Int
  ) {
    searchCashoutList(
      customer_id: $customerId
      status: $status
      confirmed_at: $confirmedAt
      confirmed_at_station: $station
      confirmed_by: $confirmedBy
      ticket_code: $ticketCode
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
        currentPage
      }
      data {
        id
        order {
          id
          order_number
        }
        ticket {
          id
          code
          from_location {
            id
            name_en
            name_ar
          }
          to_location {
            id
            name_en
            name_ar
          }
          trip {
            id
            ref_code
            date
          }
          payment_method
        }
        amount
        type
        status
        confirmed_at
        confirmed_by {
          id
          name
        }
        confirmed_station {
          id
          name_en
        }
        created_at
      }
    }
  }
`;

export default GET_CUSTOMER_CASHOUT;
