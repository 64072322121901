import gql from 'graphql-tag';

export default gql`
  query searchTrips(
    $from: ID!
    $to: ID!
    $trip_date: String!
    $admin_search: Boolean
    $get_suggestions: Boolean
  ) {
    searchTrips(
      from_location_id: $from
      to_location_id: $to
      travel_date: $trip_date
      admin_search: $admin_search
      get_suggestions: $get_suggestions
    ) {
      trips {
        id
        ref_code
        total_seats_count
        hold_seats_count
        booked_seats_count
        available_seats_count
        occupancy_percentage
        access_level
        date
        fromTime
        fromDate
        toDate
        toTime
        status
        is_active
        busSalon {
          id
          name
          layout {
            key
            rows
            cols
            type
            seat_type_id
            numbering
          }
          seat_types {
            id
            name_en
            name_ar
            rows
            cols
          }
        }
        locations {
          id
          name_en
          name_ar
        }
        routeLine {
          id
          from_city {
            id
            code
            name_en
            name_ar
          }
          to_city {
            id
            code
            name_en
            name_ar
          }
          prices {
            id
            seat_type {
              id
              name_en
              name_ar
              rows
              cols
            }
            price
          }
        }
      }
    }
  }
`;
