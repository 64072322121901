import gql from 'graphql-tag';

export const EXPORT_CASHOUT_REPORT = gql`
  mutation exportCashOut {
    exportCasoutRepot {
      status
      message
      data {
        downloadable_link
      }
    }
  }
`;

export default EXPORT_CASHOUT_REPORT;
