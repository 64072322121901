import gql from 'graphql-tag';

const CANCEL_SHIPPING_ORDER = gql`
  mutation CancelShippingOrder($id: ID!) {
    CancelShippingOrder(id: $id) {
      status
      message
    }
  }
`;

export default CANCEL_SHIPPING_ORDER;
