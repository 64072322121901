import gql from 'graphql-tag';

export const CheckEditTicket = gql`
  query getEditTicketDeductionAmount($ticketId: ID!, $upgrade: Boolean) {
    getEditTicketDeductionAmount(id: $ticketId, upgrade: $upgrade) {
      status
      message
      message_ar
      data {
        deduction_amount
        ticket {
          id
          price
          from_location_id
          to_location_id
          from_date
          trip {
            id
            date
            time
          }
          customer {
            id
            name
            phone
            email
            is_valid
            registered
          }
        }
      }
    }
  }
`;

export default CheckEditTicket;
