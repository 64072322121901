import gql from 'graphql-tag';

export const CheckBulkEditTicket = gql`
  query checkEditTicket($ticketId: [ID!]) {
    checkEditTicket(id: $ticketId) {
      status
      message
      message_ar
      data {
        deduction_amount
        tickets {
          id
          price
          customer {
            id
            name
            phone
            email
            is_valid
            registered
          }
        }
      }
    }
  }
`;

export default CheckBulkEditTicket;
