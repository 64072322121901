import gql from 'graphql-tag';

export const MultipleReplaceTickets = gql`
  mutation multipleReplaceTickets(
    $tickets: [ID!]
    $trip_id: ID!
    $from: ID!
    $to: ID!
  ) {
    multipleReplaceTickets(
      tickets: $tickets
      trip_id: $trip_id
      from: $from
      to: $to
    ) {
      status
      message
    }
  }
`;

export default MultipleReplaceTickets;
