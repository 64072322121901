/**This component is for allowing the access/visiblity
 * of component passed in yes props based on permission
 *
 */
import React, { useContext } from 'react';
import { AuthContext } from 'authContext';

const check = (permissions, action) => {
  if (!permissions || !permissions.length) {
    return false;
  }

  return permissions.some((permission) => permission === action);
};

// handling cases when passed components are functions or virtual DOMs
function unwrap(fn) {
  return typeof fn === 'function' ? fn() : fn;
}

const Can = ({ perform, yes, no }) => {
  const authContext = useContext(AuthContext);
  const user = authContext && authContext.user;
  const permissions = user && user.permissions;

  return check(permissions, perform) ? unwrap(yes) : unwrap(no);
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
