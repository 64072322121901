import gql from 'graphql-tag';
export const CASH_COLLECTOION_DATA = gql`
  query cashCollection($first: Int!, $page: Int!) {
    cashCollection(first: $first, page: $page) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        amount
        created_at
        printed_at
        station_id
        user {
          id
          name
          phone
        }
        driver {
          id
          name
          phone
        }
        station {
          id
          name
          code
          location {
            id
            name_en
          }
        }
      }
    }
  }
`;
export default CASH_COLLECTOION_DATA;
