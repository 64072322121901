/**Component for listing all available drivers in the system
 * with actions to collect money or view orders done by driver
 */
import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Spin, Button, Modal, Row, Col, InputNumber } from 'antd';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled/macro';

import { GET_DRIVERS, SETTLE_BALANCE } from 'services';
import { ListHeader, PrimaryButton, PrimaryTitle, Can } from 'components';
import { notify } from 'utilities';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Currency = styled.p`
  line-height: 8.2;
`;

const DriversList = ({
  form: { getFieldDecorator, validateFields, resetFields },
}) => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const [visible, setVisible] = useState(false);
  const [driver, setDriver] = useState(0);
  const [page, setPage] = useState(1);

  const { loading, data, fetchMore } = useQuery(GET_DRIVERS, {
    fetchPolicy: 'network-only',
    variables: {
      first: 5,
      page: 1,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [settleBalance] = useMutation(SETTLE_BALANCE);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Balance Amount',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: '',
      dataIndex: '',
      render: (driver) => {
        /**Collect amount of money from driver and update driver's balance */
        const CollectAmountButton = (
          <Button
            onClick={() => {
              setDriver(driver);
              setVisible(true);
            }}
          >
            Collect
          </Button>
        );

        return driver.balance > 0 ? (
          <Can perform="SETTLE_DRIVER_BALANCE" yes={CollectAmountButton} />
        ) : null;
      },
    },
    {
      title: '',
      dataIndex: '',
      render: (driver) => {
        // redirect to view orders done by current driver
        const ViewOrdersButton = (
          <Link to={`/driver-orders/${driver.id}`}>View Orders</Link>
        );

        return <Can perform="LIST_DRIVER_ORDERS" yes={ViewOrdersButton} />;
      },
    },
  ];

  /**Submit form to collect money from driver and update his balance */
  const handleOk = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const { amount } = values;

        settleBalance({
          variables: {
            driverId: driver.id,
            amount,
          },
          refetchQueries: () => [
            {
              query: GET_DRIVERS,
              variables: {
                page,
                first: 5,
              },
            },
          ],
        })
          .then((res) => {
            const {
              data: {
                driverSettleBalance: { message, status },
              },
            } = res;
            const notificationType = status ? 'success' : 'error';
            notify(notificationType, message);
          })
          .catch((err) => {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];

            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          });
        setTimeout(() => {
          resetFields();
        }, 1000);
        setVisible(false);
      }
    });
  };

  return (
    <>
      <Spin spinning={!data && loading}>
        {data && data.allDrivers && (
          <>
            <ListHeader>
              <PrimaryTitle>Drivers</PrimaryTitle>
              <Can
                perform="CREATE_DRIVER"
                yes={
                  <PrimaryButton
                    onClick={() => {
                      history.push('/new-driver');
                    }}
                  >
                    Add New
                  </PrimaryButton>
                }
              />
            </ListHeader>

            <Table
              scroll={{ x: 400 }}
              dataSource={data.allDrivers.data.map((driver, index) => {
                return {
                  ...driver,
                  key: index,
                };
              })}
              columns={columns}
              loading={loading}
              pagination={{
                total:
                  data &&
                  data.allDrivers &&
                  data.allDrivers.paginatorInfo &&
                  data.allDrivers.paginatorInfo.total,
                pageSize: 5,
                onChange: (page) => {
                  setPage(page);
                  fetchMore({
                    variables: {
                      page,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      return fetchMoreResult;
                    },
                  });
                },
              }}
            />
          </>
        )}
      </Spin>

      <Modal
        visible={visible}
        title="Enter the amount to be collected below:"
        onOk={handleOk}
        onCancel={() => {
          resetFields();
          setVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              resetFields();
              setVisible(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            form="amountForm"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Confirm
          </Button>,
        ]}
      >
        <Form id="amountForm">
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="Collecting Amount:">
                {getFieldDecorator('amount', {
                  rules: [
                    {
                      required: true,
                      message: 'Please, enter a valid number!',
                    },
                  ],
                })(
                  <InputNumber
                    style={{ width: '100%' }}
                    size="large"
                    min={0}
                    placeholder="Enter amount"
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Currency>{t('L.E.')}</Currency>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Form.create({})(DriversList);
