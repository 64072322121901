import gql from 'graphql-tag';

export const EXPORT_SHIPPING_REPORT = gql`
  mutation exportShipping {
    exportShipping {
      status
      message
      data {
        downloadable_link
      }
    }
  }
`;

export default EXPORT_SHIPPING_REPORT;
