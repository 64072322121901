import gql from 'graphql-tag';

export const REFUND_TICKET = gql`
  mutation refundTicket($id: ID!) {
    refundTicket(id: $id) {
      status
      message
      message_ar
    }
  }
`;

export default REFUND_TICKET;
