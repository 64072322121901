/**Component that displays a form for selecting bus and drivers for certain routeline */
import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Button } from 'antd';
import { Mutation } from '@apollo/react-components';
import { assignBusAndDrivers } from 'services';
import { notify } from 'utilities';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FormItem = Form.Item;
const Option = Select.Option;

const BusDriverAssign = ({
  bussesData,
  driversData,
  routeLineId,
  routeLine,
  handleVisibleChange,
  form: { getFieldDecorator, validateFields },
}) => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  /**Submit form for assigning bus and drivers */
  const handleAssignBusAndDrivers = (e, assignBusAndDriversAction) => {
    e.preventDefault();

    validateFields(['bus', 'drivers'], (err, values) => {
      if (!err) {
        const { bus, drivers } = values;
        assignBusAndDriversAction({
          variables: {
            buses_drivers: [
              {
                trip_route_line_id: routeLineId,
                bus_id: bussesData.filter(
                  (element) => element.ref_code === bus
                )[0].id,
                drivers,
              },
            ],
          },
        })
          .then((response) => {
            const {
              data: {
                assignBusAndDrivers: { message, status },
              },
            } = response;
            const notificationType = status ? 'success' : 'error';
            notify(notificationType, message);
            history.push('/trip-instances');
          })
          .catch((err) => {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];

            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          });
        handleVisibleChange(false);
      }
    });
  };

  const { drivers, bus } = routeLine;
  const driverIds = drivers.map((driver) => driver.id);
  const busRefCode = bus ? bus.ref_code : null;

  return (
    <Mutation mutation={assignBusAndDrivers}>
      {(assignBusAndDriversAction) => (
        <Form
          layout="vertical"
          onSubmit={(e) => {
            handleAssignBusAndDrivers(e, assignBusAndDriversAction);
          }}
        >
          <FormItem label={t('Bus')}>
            {getFieldDecorator('bus', {
              rules: [
                {
                  required: true,
                  message: 'Please enter the bus!',
                },
              ],
              initialValue: busRefCode,
            })(
              <Select>
                {bussesData.map((option, idx) => (
                  <Option key={idx} value={option.ref_code}>
                    {option.ref_code}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem label={t('Drivers')}>
            {getFieldDecorator('drivers', {
              rules: [
                {
                  required: true,
                  message: 'Please enter the drivers!',
                },
              ],
              initialValue: driverIds,
            })(
              <Select mode="multiple">
                {driversData.map((option, idx) => (
                  <Option key={idx} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          <Button type="primary" htmlType="submit">
            {t('Submit')}
          </Button>
          <Button onClick={() => handleVisibleChange(false)}>
            {t('Cancel')}
          </Button>
        </Form>
      )}
    </Mutation>
  );
};

const BusDriverAssignWrapper = Form.create({})(BusDriverAssign);

export default BusDriverAssignWrapper;
