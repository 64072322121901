import React from 'react';
import { ListHeader, PrimaryTitle } from 'components';

const NotFound = () => {
  return (
    <>
      <ListHeader>
        <PrimaryTitle>Error 404</PrimaryTitle>
      </ListHeader>
      <p>User Not Found ... </p>
      <a href={'/'}>Redirect to Login Page!!</a>
    </>
  );
};

export default NotFound;
