import gql from 'graphql-tag';

const AddCreditAmount = gql`
  mutation addCreditAmount($id: ID!, $amount: Int!) {
    addCreditAmount(id: $id, amount: $amount) {
      id
    }
  }
`;

export default AddCreditAmount;
