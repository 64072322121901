/**Component that list all available trips
 * trips could be fitered based on trip id, date etc..
 * display action buttons available for a selected trip.
 * export all trips in a single document.
 */
import React, { useState, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { useHistory, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import {
  Table,
  Button,
  Modal,
  Divider,
  Input,
  Space,
  DatePicker,
  Radio,
} from 'antd';

import { notify, ValidateUser } from 'utilities';
import { ListHeader, PrimaryTitle, Can } from 'components';
import {
  EXPORT_TRIPS_REPORT_BACKGROUND,
  GET_TRIP_INSTANCES,
  DEACTIVATE_TRIP,
  STOP_TRIP,
  EXPORT_TRIPS_REPORT,
  GET_ROUTELINES_SHIPMENTS,
  EXPORT_TICKETS_REPORT_BACKGROUND,
} from 'services';
import StationsFilter from 'lists/cashouts/station-filter';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

const TripInstancesListContainer = styled.div``;

const TripInstancesList = () => {
  ValidateUser();
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const searchInput = useRef(null);
  const nameLang = i18n.language
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [trip, setTrip] = useState(null);
  const [action, setAction] = useState('');
  const [dateOption, setDateOption] = useState('exact');
  const [filteredValues, setFilteredValues] = useState({});

  const { data, loading, refetch } = useQuery(GET_TRIP_INSTANCES, {
    variables: {
      page,
      first: 5,
      ...filteredValues,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    deactivateTrip,
    { loading: deactivating },
  ] = useMutation(DEACTIVATE_TRIP, { awaitRefetchQueries: true });

  const [stopTrip, { loading: stopping }] = useMutation(STOP_TRIP, {
    awaitRefetchQueries: true,
  });

  const [exportReport, { loading: exporting }] = useMutation(
    EXPORT_TRIPS_REPORT
  );
  const ExportingBackGround = () => {
    exportReportBackground()
      .then((response) => {
        const {
          data: {
            exportTripsBackground: { message, status },
          },
        } = response;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
            return;
          }
          notify('error', message);
        }
      });
  };

  const handleOk = () => {
    if (action === 'Cancel') {
      deactivateTrip({
        variables: {
          id: trip.id,
        },
        refetchQueries: () => [
          {
            query: GET_TRIP_INSTANCES,
            variables: {
              page,
              first: 5,
              ...filteredValues,
            },
          },
        ],
      })
        .then((response) => {
          const {
            data: {
              deactivateTrip: { message, status },
            },
          } = response;
          const notificationType = status ? 'success' : 'error';
          notify(notificationType, message);

          history.push(`/cancel-trip/${trip?.id}`);
        })
        .catch((err) => {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];

          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        });
    } else {
      stopTrip({
        variables: {
          id: trip.id,
        },
        refetchQueries: () => [
          {
            query: GET_TRIP_INSTANCES,
            variables: {
              page,
              first: 5,
              ...filteredValues,
            },
          },
        ],
      })
        .then((response) => {
          const {
            data: {
              stopTrip: { message, status },
            },
          } = response;
          const notificationType = status ? 'success' : 'error';
          notify(notificationType, message);
          if (trip?.unconfirmed_stopped_ticket?.length > 0) {
            history.push(`/stop-trip/${trip?.id}`);
          }
        })
        .catch((err) => {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];

          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        });
    }
    setVisible(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const [
    exportReportBackground,
    { loading: exportingBackground },
  ] = useMutation(EXPORT_TRIPS_REPORT_BACKGROUND);
  const columns = [
    {
      title: t('Trip ID'),
      dataIndex: 'id',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            ref={searchInput}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value)}
            placeholder="Enter the trip ID"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, 'tripId')}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filteredValue: filteredValues.tripId,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: t('Trip Code'),
      dataIndex: 'ref_code',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            ref={searchInput}
            value={selectedKeys}
            onChange={(e) => setSelectedKeys(e.target.value)}
            placeholder="Enter the reference code"
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys, confirm, 'templateCode')
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filteredValue: filteredValues.templateCode,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: t('Trip Date'),
      dataIndex: 'date',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Radio.Group
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            onChange={(e) => setDateOption(e.target.value)}
            value={dateOption}
          >
            <Radio value={'exact'}>Exact</Radio>
            <Radio value={'range'}>Range</Radio>
          </Radio.Group>
          {dateOption === 'exact' ? (
            <DatePicker
              style={{ width: 188, marginBottom: 8, display: 'block' }}
              onChange={(date, dateString) =>
                setSelectedKeys(dateString || null)
              }
            />
          ) : (
            <div style={{ width: 250, marginBottom: 8, display: 'block' }}>
              <RangePicker
                onChange={(date, dateString) =>
                  setSelectedKeys(dateString || null)
                }
              />
            </div>
          )}
          <Space>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(selectedKeys, confirm, 'date');
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filteredValue:
        dateOption === 'exact'
          ? filteredValues.date
          : dateOption === 'range' &&
            filteredValues.fromDate &&
            filteredValues.toDate
          ? [filteredValues.fromDate, filteredValues.toDate]
          : undefined,
    },
    {
      title: t('From Station'),
      key: 'from_station',
      render: (trip) => nameLang ==="en" ?  trip.locations_with_tickets[0]?.name_en : trip.locations_with_tickets[0]?.name_ar ,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <StationsFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, 'fromStation')}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filteredValue: filteredValues.fromStation,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: t('To Station'),
      key: 'to_station',
      render: (trip) =>
        nameLang === "en" ? trip.locations_with_tickets[trip.locations_with_tickets.length - 1]
          ?.name_en : trip.locations_with_tickets[trip.locations_with_tickets.length - 1]
          ?.name_ar ,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <StationsFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, 'toStation')}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filteredValue: filteredValues.toStation,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: t('Trip Status'),
      // dataIndex: 'status',
      render:(record)=> t(record.status),
    },
    {
      title: t('Bus Type'),
      key: '',
      render: (record) => {
        return (
          <ul
            style={{
              padding: 5,
              margin: 0,
            }}
          >
            {record?.routeLines.map((routeLine) => {
              if (routeLine?.bus?.bus_type)
                return (
                  <li key={routeLine.id}>{t(routeLine?.bus?.bus_type?.name)}</li>
                );
            })}
          </ul>
        );
      },
    },
    {
      title: t('Drivers'),
      key: 'driver_name',
      render: (record) => {
        return (
          <ul
            style={{
              padding: 5,
              margin: 0,
            }}
          >
            {record?.routeLines.map((routLine) => {
              return routLine?.drivers.map((driver) => {
                return <li key={driver.id}>{driver.name}</li>;
              });
            })}
          </ul>
        );
      },
    },
    {
      title: t('Actions'),
      render: (record) => {
        const {
          is_active,
          seats_tickets,
          date,
          time,
          unconfirmed_stopped_ticket,
          has_shipping,
        } = record;
        const todayDate = new Date();
        const tripDate = new Date([date, time].join(' '));
        const cancelTripButton =
          is_active === 'Inactive' &&
          ((seats_tickets?.length > 0 &&
            unconfirmed_stopped_ticket?.length === 0) ||
            has_shipping) ? (
            <Button
              style={{ height: 18, padding: 0, border: 0 }}
              type="link"
              onClick={() => {
                history.push(`/cancel-trip/${record.id}`);
              }}
            >
              Continue
            </Button>
          ) : (
            <>
              <Button
                style={{ height: 18, padding: 0, border: 0 }}
                danger
                type="link"
                disabled={
                  todayDate.setHours(0, 0, 0, 0) >
                    tripDate.setHours(0, 0, 0, 0) || is_active === 'Inactive'
                }
                onClick={() => {
                  setAction('Cancel');
                  setVisible(true);
                  setTrip(record);
                }}
              >
                {t('Cancel')}
              </Button>
              <Divider type="vertical" />
              <Button
                style={{ height: 18, padding: 0, border: 0 }}
                type="link"
                disabled={
                  todayDate.setHours(0, 0, 0, 0) >
                    tripDate.setHours(0, 0, 0, 0) || is_active === 'Inactive'
                }
                onClick={() => {
                  history.push({
                    pathname: `/update-trip/${record.id}`,
                    state: { trip: record },
                  });
                }}
              >
                {t('Edit')}
              </Button>
            </>
          );

        const stopTripButton =
          is_active === 'Inactive' && unconfirmed_stopped_ticket?.length > 0 ? (
            <Button
              style={{ height: 18, padding: 0, border: 0 }}
              type="link"
              onClick={() => {
                history.push(`/stop-trip/${record.id}`);
              }}
            >
              Continue
            </Button>
          ) : (
            <Button
              style={{ height: 18, padding: 0, border: 0 }}
              danger
              type="link"
              disabled={todayDate > tripDate || is_active === 'Inactive'}
              onClick={() => {
                setAction('Stop');
                setVisible(true);
                setTrip(record);
              }}
            >
              {t('Stop')}
            </Button>
          );

        const WorkOrderButton = (
          <Button
            onClick={() =>
              history.push({
                pathname: `/work-order/${record.id}`,
                state: {
                  trip: record,
                },
              })
            }
          >
            {t('WorK Order')}
          </Button>
        );

        const AssignButton = (
          <Button
            style={{ height: 18, padding: 0, border: 0 }}
            type="link"
            onClick={() => {
              history.push(
                `/driver-bus-assigning/${record.id}/${record.busSalon.id}`
              );
            }}
            disabled={record.status === 'Cancelled'}
          >
            {t('Assign')}
          </Button>
        );

        return (
          <>
            <Can perform="VIEW_ACTIVE_TRIP_DETAILS" yes={WorkOrderButton} />
            {AssignButton && <Divider type="vertical" />}
            <Can perform="ASSIGN_DRIVER" yes={AssignButton} />
            {cancelTripButton && <Divider type="vertical" />}
            <Can perform="DEACTIVATE_TRIP" yes={cancelTripButton} />
            {stopTripButton && <Divider type="vertical" />}
            <Can perform="STOP_TRIP" yes={stopTripButton} />
          </>
        );
      },
    },
  ];
  //Translate Function For columns


  const expandedColumns = [
    {
      title: t('Stations'),
      key: '',
      dataIndex: nameLang === "en" ?  'name_en' : 'name_ar',
    },
    {
      title: t('Pending Seats'),
      key: '',
      render: (location) => {
        let count_pending = 0;
        location.from_tickets.map((ticket) => {
          if (ticket.status == 'Pending') count_pending++;
        });
        return count_pending;
      },
    },
    {
      title: t('Paid Seats'),
      key: '',
      render: (location) => {
        let count_paid = 0;
        location.from_tickets.map((ticket) => {
          if (ticket.status == 'Paid') count_paid++;
        });
        return count_paid;
      },
    },
    {
      title: t('Revenue'),
      key: '',
      render: (location) => {
        let count_revenue = 0;
        location.from_tickets.map((ticket) => {
          if (ticket.status == 'Paid') count_revenue += ticket.price;
        });
        return count_revenue;
      },
    },
    {
      title: t('Execute Date'),
      key: '',
      dataIndex: ['pivot', 'date'],
    },
    {
      title: t('Departure Time'),
      key: '',
      dataIndex: ['pivot', 'time'],
    },
  ];

  const onChange = (pagination, filters) => {
    setPage(pagination.current);
    setFilteredValues({
      ...filteredValues,
      tripId: filters.id || undefined,
      templateCode: filters.ref_code || undefined,
      date:
        filters.date && !Array.isArray(filters.date) ? filters.date : undefined,
      fromDate:
        filters.date && Array.isArray(filters.date)
          ? filters.date[0]
          : undefined,
      toDate:
        filters.date && Array.isArray(filters.date)
          ? filters.date[1]
          : undefined,
      fromStation: filters.from_station || undefined,
      toStation: filters.to_station || undefined,
    });

    refetch({
      page: pagination.current,
      first: 5,
      tripId: filters.id || undefined,
      templateCode: filters.ref_code || undefined,
      date:
        filters.date && !Array.isArray(filters.date) ? filters.date : undefined,
      fromDate:
        filters.date && Array.isArray(filters.date)
          ? filters.date[0]
          : undefined,
      toDate:
        filters.date && Array.isArray(filters.date)
          ? filters.date[1]
          : undefined,
      fromStation: filters.from_station || undefined,
      toStation: filters.to_station || undefined,
    });
  };

  const handleExport = () => {
    notify('info', 'Export Limited', 'Max 500 trip will be exported', 5000);
    exportReport({
      variables: {
        ...filteredValues,
      },
    })
      .then((res) => {
        const {
          data: {
            exportTrips: {
              message,
              status,
              data: { downloadable_link },
            },
          },
        } = res;
        var a = document.createElement('a');
        a.href = downloadable_link;
        document.body.appendChild(a);
        a.click();
        a.remove();
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
          return;
        }
        notify('error', message);
      });
  };

  return (
    <TripInstancesListContainer>
      <ListHeader>
        <PrimaryTitle>{t('Trip Instances')}</PrimaryTitle>
        <Can
          perform="EXPORT_TRIP_REPORT"
          yes={
            <>
              <Button
                type="primary"
                loading={exporting}
                onClick={() => handleExport()}
              >
                {t('Export Report')}
              </Button>
            </>
          }
        />
        <Can
          perform="EXPORT_TRIP_REPORT_BACKGROUND"
          yes={
            <Button
              danger
              type="primary"
              loading={exportingBackground}
              onClick={() => ExportingBackGround()}
            >
              {t('Export Report Background')}
            </Button>
          }
        />
      </ListHeader>

      <Table
        bordered
        dataSource={data?.trips?.data}
        columns={columns}
        loading={loading || deactivating || stopping}
        onChange={onChange}
        scroll={{ x: 400 }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              bordered
              columns={expandedColumns}
              dataSource={record?.locations_with_tickets}
              pagination={false}
              rowKey={(record) => record.id}
            />
          ),
        }}
        rowKey={(record) => record.id}
        pagination={{
          total: data?.trips?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
        }}
      />
      <Modal
        visible={visible}
        title={t('Confirm Action')}
        onOk={handleOk}
        onCancel={() => {
          setVisible(false);
          setTrip(null);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
              setTrip(null);
            }}
          >
            {t('Cancel')}
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={handleOk}
            loading={deactivating || stopping}
          >
            {t('Confirm')}
          </Button>,
        ]}
      >
        <p>
          {t('You are about to')}{' '}
          {action === t('Cancel') ? t('cancel') : t('stop')} {t('trip')} (
          {trip?.id}). {t('Are you sure you want to proceed?')}
        </p>
      </Modal>
    </TripInstancesListContainer>
  );
};

export default TripInstancesList;
