import gql from 'graphql-tag';

export const CreateDriver = gql`
  mutation createDriver($name: String!, $phone: String!, $ssn: String!) {
    driverCreate(name: $name, ssn: $ssn, phone: $phone) {
      status
      message
      message_ar
      data {
        id
      }
    }
  }
`;

export default CreateDriver;
