import React, { useState } from 'react';

import { Layout } from 'antd';

import AppSider from './sider';
import MobileAppSider from './MobileAppSider';
import AppHeader from './header';
import { isMobile } from 'react-device-detect';

const { Content } = Layout;

const AppLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Layout
      style={{
        backgroundColor: '#fff',
      }}
    >
      <AppHeader onClick={() => setIsOpen(!isOpen)} />
      <Layout style={{ width: '100%' }}>
        {isMobile ? (
          isOpen ? (
            <MobileAppSider onClick={() => setIsOpen(!isOpen)} />
          ) : (
            <></>
          )
        ) : (
          <AppSider />
        )}
        {isMobile ? (
          isOpen ? (
            <></>
          ) : (
            <Content
              style={{
                padding: '5px',
                background: '#fff',
              }}
            >
              {children}
            </Content>
          )
        ) : (
          <Content
            style={{
              padding: '0 5px',
              background: '#fff',
            }}
          >
            {children}
          </Content>
        )}
      </Layout>
    </Layout>
  );
};

export default AppLayout;
