import React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, DatePicker } from 'antd';
import { isMobile, TabletView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const Filter = ({
  form: { getFieldDecorator, validateFields, resetFields },
  refetch,
  setIsSearching,
  resetPagination,
  currentPage,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields((err, values) => {
      console.info('values');
      console.info(values);
      if (!err) {
        setIsSearching();
        refetch({
          auditable_id:
            values.auditable_id?.length > 0 ? values.auditable_id : undefined,
          auditable_type:
            values.auditable_type?.length > 0
              ? values.auditable_type
              : undefined,
          page: 1,
          first: 5,
        });
      }
    });
  };

  const handleReset = () => {
    resetFields();
    refetch({
      auditable_id: undefined,
      auditable_type: undefined,
      page: currentPage,
      first: 5,
    });
    resetPagination();
  };
  const [t, i18n] = useTranslation();
  return (
    <Form
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: `${isMobile && !TabletView ? 'column' : 'row'}`,
        flexWrap: 'wrap',
        margin: '1%',
      }}
      layout={isMobile ? 'horizontal' : 'inline'}
      onSubmit={handleSubmit}
    >
      <Form.Item label={t('Module Id')}>
        {getFieldDecorator('auditable_id')(
          <Input size="large" placeholder={t('Order Id Or Ticket id')}></Input>
        )}
      </Form.Item>
      <Form.Item label={t('Module Name (case sensitive)')}>
        {getFieldDecorator('auditable_type')(
          <Input size="large" placeholder={t('Enter Module Name')}></Input>
        )}
      </Form.Item>
      <br />
      <Form.Item>
        <Button type="primary" size="large" htmlType="submit">
          {t('Filter Records')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" onClick={handleReset}>
          {t('Reset Fields')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create({})(Filter);
