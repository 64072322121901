import gql from 'graphql-tag';

export const allSeatTypes = gql`
  query allSeatTypes {
    allSeatTypes {
      id
      name_en
      name_ar
      created_at
      updated_at
    }
  }
`;

export default allSeatTypes;
