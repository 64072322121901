import gql from 'graphql-tag';

export const getCancelledTrip = gql`
  query getTrip($id: ID!) {
    getTrip(id: $id) {
      trip_template {
        id
      }
      ref_code
      updated_at
      grouped_tickets {
        id
        payment_method
        status
        from_time
        from_date
        code
        trip {
          id
        }
        id
        routeLine {
          id
        }
        seat_number
        from_location {
          id
          name_en
          city {
            id
            name_en
          }
        }
        to_location {
          id
          name_en
          city {
            id
            name_en
          }
        }
        seat_type {
          id
          name_en
        }
        price
        customer {
          id
          name
          phone
          registered
        }
      }
      routeLines {
        id
        from_city {
          id
          name_en
        }
        to_city {
          id
          name_en
        }
      }
    }
  }
`;

export default getCancelledTrip;
