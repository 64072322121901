import gql from 'graphql-tag';

const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $name: String!
    $email: String!
    $role: String!
    $station: ID
    $segment: ID
  ) {
    updateUser(
      id: $id
      name: $name
      email: $email
      role: $role
      station_id: $station
      partner_segment_id: $segment
    ) {
      status
      message
      data {
        id
        name
      }
    }
  }
`;

export default UPDATE_USER;
