/** Component for creating new shift */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled/macro';
import {
  Form,
  Input,
  DatePicker,
  TimePicker,
  Button,
  Select,
  Spin,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import { CREATE_SHIFT, GET_ACTIVE_LOCATIONS } from 'services';
import { ListHeader, PrimaryTitle } from 'components';
import { FormContainer, ActionButton, CancelButton, notify } from 'utilities';
import { useTranslation } from 'react-i18next';
const { Item } = Form;
const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
const NewShiftContainer = styled.section`
  ${FormContainer}
  width: 100%;
  flex-wrap: wrap:
  hegiht: 100%;

  ${ActionButton}
  ${CancelButton}
`;

const ShiftForm = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const [createShift, { loading: creatingShift }] = useMutation(CREATE_SHIFT);
  const { data: locationsData, loading: loadingLocations } = useQuery(
    GET_ACTIVE_LOCATIONS,
    {
      variables: {
        staff: true,
      },
    }
  );

  // transform array to city as key that contains all available stations for that city
  const { activeLocations } = locationsData || { activeLocations: [] };
  const availableLocations = activeLocations.reduce((result, location) => {
    const cityName = location.city.name_en;
    const cityId = location.city.id;

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});

  // form submit function
  const onFinish = (values) => {
    const { station, code, name, time, date } = values;
    createShift({
      variables: {
        code,
        name,
        station,
        startTime: time[0].format('HH:mm'),
        endTime: time[1].format('HH:mm'),
        startDate: date && date[0].format('YYYY-MM-DD'),
        endDate: date && date[1].format('YYYY-MM-DD'),
      },
    })
      .then((response) => {
        history.push('/shifts');
        const {
          data: {
            createStationShift: { status, message },
          },
        } = response;
        // status is a boolean that is returned true from BE if no error occurs
        if (status) {
          notify('success', message);
          history.push('/shifts');
        }
      })
      .catch((err) => {
        if (err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        }
      });
  };

  return (
    <NewShiftContainer>
      <ListHeader>
        <PrimaryTitle>{t('Create Shift')}</PrimaryTitle>
      </ListHeader>

      <Spin spinning={loadingLocations}>
        <Form layout="vertical" onFinish={onFinish}>
          <Row gutter={12}>
            <Col span={12}>
              <Item
                name="station"
                label={t('Station')}
                rules={[
                  {
                    required: true,
                    message: t('Please, enter the station for this shift!'),
                  },
                ]}
              >
                <Select
                  placeholder={t('Select Assigned Station')}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  mode="multiple"
                >
                  {loadingLocations ? (
                    <Option
                      value={null}
                      disabled
                      style={{ textAlign: 'center' }}
                    >
                      <Spin tip="Loading Locations..." />
                    </Option>
                  ) : (
                    Object.keys(availableLocations).map(
                      (cityName, cityIndex) => (
                        <OptGroup key={`city_${cityIndex}`} label={cityName}>
                          {availableLocations[cityName].map(
                            (location, locationIndex) => (
                              <Option
                                key={`location_${cityIndex}_${locationIndex}`}
                                value={location.id}
                              >
                                {location.name_en}
                              </Option>
                            )
                          )}
                        </OptGroup>
                      )
                    )
                  )}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Item
                name="code"
                label={t('Shift Code')}
                rules={[
                  {
                    required: true,
                    message: t('Please, enter the code for this shift!'),
                  },
                ]}
              >
                <Input placeholder="e.g. 123" size="large" />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name="name"
                label={t('Shift Name')}
                rules={[
                  {
                    required: true,
                    message: t('Please, enter the name for this shift!'),
                  },
                ]}
              >
                <Input placeholder="e.g. Afternoon Shift" size="large" />
              </Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Item
                name="time"
                label={t('Shift Time Range')}
                rules={[
                  {
                    required: true,
                    message: t('Please, enter the time range for this shift!'),
                  },
                ]}
              >
                <TimePicker.RangePicker
                  placeholder={[t('Start time'), t('End time')]}
                  style={{ width: '100%' }}
                  size="large"
                  format={'HH:mm'}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="date" label={t('Shift Date Range (Optional)')}>
                <RangePicker
                  placeholder={[t('Start date'), t('End date')]}
                  size="large"
                  disabledDate={(current) => {
                    return current && current < moment().startOf('day');
                  }}
                />
              </Item>
            </Col>
          </Row>
          <Row gutter={12} align="middle">
            <Col span={8}>
              <Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={creatingShift}
                >
                  {creatingShift ? t('Creating') : t('Create')}
                </Button>
              </Item>
            </Col>
            <Col span={8}>
              <Item style={{ margin: 0 }}>
                <Popconfirm
                  title={t('Are you sure you want to discard?')}
                  okText={t('Yes')}
                  cancelText={t('No')}
                  onConfirm={() => history.goBack()}
                  onCancel={() => {}}
                >
                  <Button type="link" size="large" danger>
                    {t('Cancel')}
                  </Button>
                </Popconfirm>
              </Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </NewShiftContainer>
  );
};

export default ShiftForm;
