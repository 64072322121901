import gql from 'graphql-tag';

export const GET_CASHOUTS = gql`
  query allCashoutList(
    $created_at: Date
    $foundedBy: String
    $confirmed_at_station: ID
    $status: [CashoutStatus!]
    $confirmedAt: Date
    $confirmedBy: ID
    $ticketCode: String
    $first: Int!
    $page: Int
  ) {
    allCashoutList(
      created_at: $created_at
      foundedBy: $foundedBy
      status: $status
      confirmed_at: $confirmedAt
      confirmed_at_station: $confirmed_at_station
      confirmed_by: $confirmedBy
      ticket_code: $ticketCode
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
      has_data: true
    ) {
      paginatorInfo {
        count
        total
        currentPage
      }
      data {
        id
        order {
          id
          order_number
        }
        ticket {
          id
          code
          from_location {
            id
            name_en
            name_ar
          }
          to_location {
            id
            name_en
            name_ar
          }
          trip {
            id
            ref_code
            date
          }
          payment_method
        }
        shipping {
          id

          from_location {
            id
            name_en
            name_ar
          }
          to_location {
            id
            name_en
            name_ar
          }
          trip_route_line {
            id
            trip {
              id
              ref_code
              date
            }
          }
          payment_method
        }
        amount
        type
        status
        confirmed_at
        confirmed_by {
          id
          name
        }
        confirmed_station {
          id
          name_en
          name_ar
        }
        created_at
        auto_confirmed
      }
    }
  }
`;

export default GET_CASHOUTS;
