import gql from 'graphql-tag';

export const UPDATE_SETTINGS = gql`
  mutation update_settings($id: ID!, $code: Int!, $build_number: String!) {
    update_settings(
      id: $id
      latest_active_code: $code
      build_number: $build_number
    ) {
      status
      message
    }
  }
`;

export default UPDATE_SETTINGS;
