import gql from 'graphql-tag';

const GET_USER_NOTIFICATIONS = gql`
  query getUserNotifications($page: Int) {
    getUserNotifications(first: 5, page: $page) {
      paginatorInfo {
        count
        total
        currentPage
        hasMorePages
        lastPage
      }
      data {
        id
        entity_type
        entity_id
        created_at
        notification {
          title_en
          message_en
        }
      }
    }
  }
`;

export default GET_USER_NOTIFICATIONS;
