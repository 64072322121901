export const colors = {
  primaryColor: '#4a4a4a',
  secondaryColor: '#f2f2f2',
  gray: '#F8F8F8',
  darkGrey: '#dfdfdf',
  blue: '#163260',
  lightBlue: '#3aa0ff',
  danger: '#D3072A',
  success: '#0BCA47',
};
