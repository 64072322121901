/** @jsx jsx */
import { jsx, Global, css } from '@emotion/core';
import { fontFace } from './font-generation-helper';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export const GlobalStyles = () => {
  const {language} = i18next;
  const [t,i18n]=useTranslation();
  return (
    <Global
      styles={css`
        ${fontFace('GE SS Two', 'GESSTwoLight-Light', '.woff', 300)};
        ${fontFace('GE SS Two', 'GESSTwoMedium-Medium', '.woff', 500)};
        ${fontFace('Lato', 'lato-regular', '.woff', 400)};
        ${fontFace('Cairo', 'Cairo-Medium', '.ttf', 500)};
        html{
          direction: rtl;
          box-sizing: border-box;
        }
        *,
        ::before,
        ::after {
          box-sizing: inherit;
        }
        html,
        body {
          scroll-behavior: smooth;
          padding: 0;
          margin: 0;
          line-height: 1.6;
          font-family:${language === 'en'?'Lato':'Cairo'};
        }
        body {
          background: #fff;
        }
      `}
    />
  );
};
