/** Search component for drivers based on entered phone number */
import React, { useState, useEffect } from 'react';

import { Select, Spin, Empty } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';

import { useDebounce } from 'utilities';
import { SEARCH_DRIVERS } from 'services';

const { Option } = Select;

const SearchDrivers = ({ setSelectedKeys, selectedKeys, forwardedRef }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [searchDrivers, { loading, data }] = useLazyQuery(SEARCH_DRIVERS, {
    variables: {
      phone: debouncedSearchTerm,
    },
  });

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchDrivers();
    }
  }, [debouncedSearchTerm, searchDrivers]);

  const onChange = (value) => {
    if (!value) {
      setSelectedKeys(null);
    }
  };

  return (
    <Select
      loading={loading}
      onSearch={(searchValue) => setSearchTerm(searchValue)}
      onSelect={(value) => setSelectedKeys(value)}
      showSearch
      showArrow={false}
      filterOption={false}
      allowClear={true}
      notFoundContent={
        !debouncedSearchTerm ? null : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      value={selectedKeys}
      onChange={onChange}
      placeholder="Search by phone"
      style={{ width: 188, marginBottom: 8, display: 'block' }}
      ref={forwardedRef}
    >
      {debouncedSearchTerm ? (
        loading ? (
          <Option value={null} disabled style={{ textAlign: 'center' }}>
            <Spin tip="Loading Drivers..." />
          </Option>
        ) : (
          data &&
          data.searchDrivers.map((driver) => (
            <Option key={driver.id} value={driver.id}>
              {driver.name}
            </Option>
          ))
        )
      ) : null}
    </Select>
  );
};

export default SearchDrivers;
