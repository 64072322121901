import gql from 'graphql-tag';

export const allBuses = gql`
  query allBusses {
    allBusses {
      id
      ref_code
      licence_no
      mileage_no
      created_at
      updated_at
    }
  }
`;

export default allBuses;
