import gql from 'graphql-tag';

export const CONFIRM_CASH_COLLECTION = gql`
  mutation updatePrintCashCollection($id: [ID!]) {
    updatePrintCashCollection(id: $id) {
      status
      message
    }
  }
`;

export default CONFIRM_CASH_COLLECTION;
