import gql from 'graphql-tag';

const GET_SETTINGS = gql`
  query settings {
    settings {
      id
      build_number
      latest_active_code
    }
  }
`;

export default GET_SETTINGS;
