import gql from 'graphql-tag';

export const CancelTripTemplateList = gql`
  query tripTemplate($tripTemplateId: ID!) {
    tripTemplate(id: $tripTemplateId) {
      id
      ref_code
      access_level
      from_date
      to_date
      is_active
      routeLines {
        id
        from_city {
          id
          code
          name_en
          name_ar
        }
        to_city {
          id
          code
          name_en
          name_ar
        }
        prices {
          id
          seat_type_id {
            id
            name_en
            name_ar
          }
          bus_salon_id
          price
        }
      }
      timeLines {
        timeLineSlots {
          time
          day
          location {
            name_en
            name_ar
          }
        }
      }
      grouped_tickets {
        id
        payment_method
        status
        from_time
        from_date
        code
        trip {
          id
        }
        id
        routeLine {
          id
        }
        seat_number
        from_location {
          id
          name_en
          name_ar
          city {
            id
            name_en
            name_ar
          }
        }
        to_location {
          id
          name_en
          name_ar
          city {
            id
            name_en
            name_ar
          }
        }
        seat_type {
          id
          name_en
          name_ar
        }
        price
        customer {
          id
          name
          phone
          registered
        }
      }

      created_at
      updated_at
    }
  }
`;
