import gql from 'graphql-tag';

export const GET_ACTIVE_LOCATIONS = gql`
  query activeLocations($staff: Boolean) {
    activeLocations(staff: $staff) {
      id
      name_en
      name_ar
      type
      is_active
      driver_location
      city {
        id
        name_en
        name_ar
        is_active
      }
    }
  }
`;

export default GET_ACTIVE_LOCATIONS;
