import React, { useState, useEffect } from 'react';

import { Form, Select, Spin, Empty } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';

import { useDebounce } from 'utilities';
import { SEARCH_CUSTOMERS } from 'services';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const { Option } = Select;

const SelectCustomer = ({ selectCustomer }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [t, i18n] = useTranslation();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [searchCustomers, { loading, data }] = useLazyQuery(SEARCH_CUSTOMERS, {
    variables: {
      customerPhoneNumber: debouncedSearchTerm,
    },
  });

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchCustomers();
    }
  }, [debouncedSearchTerm, searchCustomers]);

  const onChange = (value) => {
    if (!value) {
      selectCustomer(null);
    }
  };

  return (
    <Form>
      <Item
        name="customerPhone"
        label={t('Choose a Customer')}
        style={{ margin: 0 }}
      >
        <Select
          loading={loading}
          onSearch={(searchValue) => setSearchTerm(searchValue)}
          onSelect={selectCustomer}
          showSearch
          showArrow={false}
          filterOption={false}
          allowClear={true}
          notFoundContent={
            !debouncedSearchTerm ? null : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          onChange={onChange}
          placeholder="e.g. 01123456789"
          size="large"
        >
          {debouncedSearchTerm ? (
            loading ? (
              <Option value={null} disabled style={{ textAlign: 'center' }}>
                <Spin tip="Loading Customers..." />
              </Option>
            ) : (
              data &&
              data.searchCustomers.map((customer) => (
                <Option key={customer.id} value={JSON.stringify(customer)}>
                  {customer.name}
                </Option>
              ))
            )
          ) : null}
        </Select>
      </Item>
    </Form>
  );
};

export default SelectCustomer;
