import gql from 'graphql-tag';

const GET_SHIFTS = gql`
  query stationShifts($first: Int!, $page: Int) {
    stationShifts(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        code
        name
        start_time
        end_time
        start_date
        end_date
        station {
          id
          name_en
        }
      }
    }
  }
`;

export default GET_SHIFTS;
