import gql from 'graphql-tag';

const GET_SHIFTS_LOGS = gql`
  query stationShiftsLogs {
    stationShiftsLogs {
      shiftLogsRecords {
        ticketsCount
        shippingOrdersCount
        offlineRefundAmount
        offlineCreditCard
        cashRefundAmount
        totalSales
        otherFees
        cashPayments
        payStore
        cashDrawer
        ordersCount
        shippingAmount
        shiftLog {
          id
          started_at
          ended_at
          shift {
            id
            station {
              id
              name_en
              name_ar
            }
          }
          user {
            id
            name
          }
        }
      }
      total
    }
  }
`;

export default GET_SHIFTS_LOGS;
