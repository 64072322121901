/** Component for displaying input values to edit shift time  */
import React from 'react';
import { Form, TimePicker } from 'antd';

const { Item } = Form;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          <TimePicker size="small" format={'HH:mm a'} />
        </Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
