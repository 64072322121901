import gql from 'graphql-tag';

export default gql`
  mutation addCartTickets(
    $cartToken: String!
    $tickets: [ticketsInfo!]!
    $upgrade: Float
    $edit_id: Int
  ) {
    addCartTickets(
      token: $cartToken
      ticketsInfo: $tickets
      upgrade: $upgrade
      edit_id: $edit_id
    ) {
      cart {
        id
        token
        tickets {
          id
          seat_number
          from_time
          to_time
          from_date
          seat_type {
            id
          }
          price
          original_price
          routeLine {
            id
          }
          seat_type {
            id
            name_en
            name_ar
          }
          trip {
            id
            ref_code
            access_level
            date
            time
          }
          from_location {
            id
            code
            name_en
            name_ar
            type
            city {
              id
              code
              name_en
              name_ar
            }
          }
          to_location {
            id
            code
            name_en
            name_ar
            type
            city {
              id
              code
              name_en
              name_ar
            }
          }
        }
        created_at
        updated_at
      }
      message
      message_ar
      status
    }
  }
`;
