import gql from 'graphql-tag';

const SEARCH_CUSTOMERS = gql`
  query searchUsers($userPhoneNumber: String!, $role: RoleEnum) {
    searchUsers(phone: $userPhoneNumber, role: $role) {
      id
      name
      phone
    }
  }
`;

export default SEARCH_CUSTOMERS;
