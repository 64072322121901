import gql from 'graphql-tag';

const GET_lOCATION = gql`
  query getLocation($id: ID!) {
    location(id: $id) {
      id
      code
      name_en
      name_ar
      lat
      long
      driver_location
      city {
        id
        name_en
      }
      type
    }
  }
`;

export default GET_lOCATION;
