/**Component for stopping a trip and handling stop action from a trip instances list
 * handle passengers on stopped trip (refund or replace ticket)
 */
import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { Modal, Table, Form, Col, Input, Row, InputNumber } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { notify } from 'utilities';
import { ListHeader, PrimaryTitle, Can } from 'components';
import { getTrip, HANDLE_STOPPED_TICKET } from 'services';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Item } = Form;

const StopTripContainer = styled.div``;

const StopTrip = () => {
  const [visible, setVisible] = useState(false);
  const [ticket, setTicket] = useState(null);

  const history = useHistory();
  const [t, i18n] = useTranslation();

  const [form] = Form.useForm();

  const { tripId } = useParams();

  const { data, loading } = useQuery(getTrip, {
    variables: { id: tripId },
  });

  const [handleStoppedTicket, { loading: changingSeat }] = useMutation(
    HANDLE_STOPPED_TICKET
  );

  const columns = [
    {
      title: 'Passenger Name',
      dataIndex: ['customer', 'name'],
    },
    {
      title: 'Phone Number',
      dataIndex: ['customer', 'phone'],
    },
    ,
    {
      title: 'Seat Number',
      dataIndex: 'seat_number',
    },
    ,
    {
      title: 'Seat Type',
      dataIndex: ['seat_type', 'name_en'],
    },
    ,
    {
      title: 'Price',
      render: (ticket) => `${ticket.price} L.E.`,
    },
    {
      title: 'Operations',
      render: (ticket) => {
        return (
          <Can
            perform="CHANGE_STOPPED_TRIP_TICKET"
            yes={
              <a
                href="#!"
                onClick={() => {
                  setTicket(ticket);
                  setVisible(true);
                }}
              >
                Change Seat
              </a>
            }
          />
        );
      },
    },
  ];
  /**submit stop trip request */
  const onSubmit = (values) => {
    const { seatNumber, refundAmount } = values;

    handleStoppedTicket({
      variables: {
        id: ticket.id,
        seatNumber,
        refundAmount,
      },
      refetchQueries: () => [
        {
          query: getTrip,
          variables: {
            id: tripId,
          },
        },
      ],
    })
      .then((res) => {
        const {
          data: {
            changeStoppedTripTicket: { message, status },
          },
        } = res;
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
        if (data.getTrip.unconfirmed_stopped_ticket.length <= 1) {
          history.push(`/trip-instances`);
        }
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', message);
        }
      });
    setVisible(false);
  };

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <StopTripContainer>
      <ListHeader>
        <PrimaryTitle>Move Passengers</PrimaryTitle>
      </ListHeader>

      <Table
        scroll={{ x: 400 }}
        bordered
        dataSource={data?.getTrip?.unconfirmed_stopped_ticket}
        columns={columns}
        loading={loading || changingSeat}
        rowKey="id"
        pagination={false}
      />

      <Modal
        destroyOnClose
        visible={visible}
        title={t('Change Seat')}
        okText={t('Confirm')}
        cancelText={t('Cancel')}
        onCancel={onCancel}
        confirmLoading={changingSeat}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onSubmit(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="change_seat_form"
          preserve={false}
          initialValues={{ ticketNumber: ticket?.code }}
        >
          <Row gutter={10}>
            <Col span={12}>
              <p style={{ marginBottom: 20 }}>
                Ticket Number:{' '}
                <span style={{ fontWeight: 'bold' }}>{ticket?.code}</span>
              </p>
            </Col>
          </Row>

          <Item
            label={t('Seat Number')}
            name="seatNumber"
            rules={[
              {
                required: true,
                pattern: '^(0|[1-9][0-9]{0,9})$',
                message: t('Please, enter a valid seat number!'),
              },
            ]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder={t('Enter Seat Number')}
            />
          </Item>

          <Item
            label={t('Refund Amount (L.E.)')}
            name="refundAmount"
            rules={[
              {
                pattern: '^(0|[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$',
                message: t('Please, enter a valid amount!'),
              },
            ]}
          >
            <Input placeholder={t('Enter Refund Amount')} />
          </Item>
        </Form>
      </Modal>
    </StopTripContainer>
  );
};

export default StopTrip;
