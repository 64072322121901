import gql from 'graphql-tag';

const me = gql`
  query me {
    me {
      id
      name
      phone
      email
      role {
        id
        name
        permissions {
          id
          name
        }
      }
      ongoing_shift {
        id
        code
        name
        start_time
        end_time
      }
      station {
        id
        name_en
      }
      partner_segment {
        id
        name
        phone
        credit_balance
        credit_total_added
      }
    }
  }
`;

export default me;
