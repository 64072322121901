import gql from 'graphql-tag';
const GET_MY_SHIFTS = gql`
  query myTodayShifts {
    myTodayShifts {
      id
      code
      name
      start_time
      end_time
      start_date
      end_date
      station {
        id
        name_en
      }
    }
  }
`;

export default GET_MY_SHIFTS;
