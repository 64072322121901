import gql from 'graphql-tag';

export const ACTIVE_TRIP_DETAILS = gql`
  query activeTripDetails($tripId: ID!) {
    activeTripDetails(id: $tripId) {
      id
      ref_code
      date
      time
      from_city {
        id
        name_en
        name_ar
      }
      to_city {
        id
        name_en
        name_ar
      }
      status
      total_tickets
      picked_up_tickets
      is_active
      locations {
        name_en
        name_ar
      }
      routeLines {
        id
        drivers {
          id
          name
        }
        bus {
          id
          ref_code
          name
        }
      }
    }
  }
`;
