import gql from 'graphql-tag';

const ACTIVATE_LOCATION = gql`
  mutation activateLocation($id: ID!) {
    activateLocation(id: $id) {
      id
      name_en
    }
  }
`;

export default ACTIVATE_LOCATION;
