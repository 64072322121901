import React, { useState } from 'react';
import { Table, Spin, Button, Space, Form, Input, Modal, Select } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Can, ListHeader, PrimaryTitle } from 'components';
import {
  PRIVATE_TRIPS,
  MARK_AS_READ,
  MARK_INVALID,
  GET_LOCATIONS,
} from 'services';
import { notify } from 'utilities';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
const PrivateTrips = () => {
  const { Item } = Form;
  const { Option } = Select;
  const [page, setPage] = useState(1);
  const [t, i18n] = useTranslation();

  const {
    loading,
    error,
    data,
    refetch: refetchPrivateTrip,
    fetchMore,
  } = useQuery(PRIVATE_TRIPS, {
    fetchPolicy: 'network-only',
    variables: {
      first: 5,
      page: 1,
    },
  });
  const [markInvalid] = useMutation(MARK_INVALID);
  const [markRead, { loading: updateLoading }] = useMutation(MARK_AS_READ);
  const [visible, SetVisible] = useState(false);
  const [activeId, SetActiveId] = useState(0);
  const handleMarkAsRead = (values) => {
    const { comment, status } = values;
    markRead({
      variables: {
        id: activeId,
        comment,
        status,
      },
    })
      .then((res) => {
        const {
          data: {
            markRead: { message, status },
          },
        } = res;
        SetVisible(false);
        const notificationType = status ? 'success' : 'error';
        notify(notificationType, message);
        refetchPrivateTrip({
          page: page,
        });
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
          return;
        }
        notify('error', t(message));
      });
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Traveler Number',
      dataIndex: 'traveler_number',
    },
    {
      title: 'From City',
      dataIndex: ['from_city', 'name_en'],
    },
    {
      title: 'To City',
      dataIndex: ['to_city', 'name_en'],
    }, {
      title: 'From Location',
      dataIndex: ['from_location', 'name_en'],
    },
    {
      title: 'To Location',
      dataIndex: ['to_location', 'name_en'],
    },
    {
      title: 'From Date',
      dataIndex: 'from_date',
    },
    {
      title: 'To Date',
      dataIndex: 'to_date',
    },
    {
      title: 'Bus Type',
      dataIndex: ['bus_type', 'name'],
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
    },
    {
      title: 'Actions',
      render: (bus) => {
        const color = bus.is_valid ? 'green' : 'red';
        return (
          <Space size="middle">
            {!bus.is_read ? (
              <Button
                type="link"
                disabled={bus.is_read}
                onClick={() => {
                  SetActiveId(bus.id);
                  SetVisible(true);
                }}
              >
                {t('Action')}
              </Button>
            ) : (
              <Button style={{ color: color, border: 'none' }}>
                {bus.is_valid ? 'Done' : 'Invalid'}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  return (
    <Spin spinning={loading}>
      {data && data.privateTripsList && (
        <>
          <Modal
            title={
              <Space size={'middle'}>
                <ExclamationCircleOutlined
                  style={{ fontSize: 22, color: '#FFCC00' }}
                />
                {t('Apply action on private request')} #
                {activeId ?? 'loading..'}
              </Space>
            }
            visible={visible}
            onCancel={() => {
              SetVisible(false);
            }}
            footer={[
              <Button key="cancel" onClick={() => SetVisible(false)}>
                {t('Return')}
              </Button>,
            ]}
          >
            <Spin spinning={updateLoading}>
              <Form onFinish={handleMarkAsRead} layout={'vertical'}>
                <Item
                  style={{ width: '50%' }}
                  rules={[
                    {
                      required: true,
                      message: t('Please, Select Action!'),
                    },
                  ]}
                  name="status"
                  label={t('action')}
                >
                  <Select placeholder={t('Select Action')} size="large">
                    <Option value={true} style={{ textAlign: 'center' }}>
                      {t('Done')}
                    </Option>
                    <Option value={false} style={{ textAlign: 'center' }}>
                      {t('Invalid')}
                    </Option>
                  </Select>
                </Item>
                <Item
                  style={{ width: '100%', marginBottom: '20px' }}
                  rules={[
                    {
                      required: true,
                      message: t('Please, enter the comment!'),
                    },
                  ]}
                  name="comment"
                  label={t('comment')}
                >
                  <Input placeholder={t('Enter comment')} defaultValue="" />
                </Item>

                <Item>
                  <Button type="danger" htmlType="submit">
                    {t('Confirm')}
                  </Button>
                </Item>
              </Form>
            </Spin>
          </Modal>
          <ListHeader>
            <PrimaryTitle>{t('Private Trips Requests')}</PrimaryTitle>
          </ListHeader>
          <Table
            scroll={{ x: 400 }}
            dataSource={data.privateTripsList.data}
            columns={columns}
            loading={loading}
            pagination={{
              total:
                data &&
                data.privateTripsList &&
                data.privateTripsList.paginatorInfo &&
                data.privateTripsList.paginatorInfo.total,
              pageSize: 5,
              showSizeChanger: false,
              onChange: (page) => {
                setPage(page);
                fetchMore({
                  variables: {
                    page,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return fetchMoreResult;
                  },
                });
              },
            }}
          />
        </>
      )}
    </Spin>
  );
};

export default PrivateTrips;
