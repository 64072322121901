import gql from 'graphql-tag';

const GET_LOGS = gql`
  query listActivityLogs(
    $first: Int!
    $page: Int
    $actorId: ID
    $actorType: ActorType
    $date: Date
    $id: ID
    $entity: logActions
    $entityId: ID
  ) {
    listActivityLogs(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
      actor_id: $actorId
      actor_type: $actorType
      date: $date
      id: $id
      entity: $entity
      entity_id: $entityId
    ) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        causer_type
        description
        entity
        subject_id
        created_at
        properties
        causer {
          ... on Customer {
            id
            name
            phone
          }
          ... on Driver {
            id
            name
            phone
          }
          ... on User {
            id
            name
            phone
          }
        }
      }
    }
  }
`;

export default GET_LOGS;
