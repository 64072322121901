import gql from 'graphql-tag';

export default gql`
  mutation adminCreateCustomer($name: String!, $phone: String!) {
    adminCreateCustomer(name: $name, phone: $phone) {
      id
      name
      phone
      created_at
      updated_at
    }
  }
`;
