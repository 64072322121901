import gql from 'graphql-tag';

// [TODO] add type in the data
export const getCustomer = gql`
  query customer($id: ID) {
    customer(id: $id) {
      id
      name
      phone
    }
  }
`;

export default getCustomer;
