/**Component for listing all available notifications with date of creation */
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { ALL_NOTIFICATIONS } from 'services';
import { useHistory } from 'react-router-dom';
import { ListHeader, PrimaryButton, PrimaryTitle } from 'components';
import { notify } from '../../utilities';
import { useTranslation } from 'react-i18next';

const NotificationsList = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title_ar',
      key: 'title_ar',
    },
    {
      title: 'Content',
      dataIndex: 'message_ar',
      key: 'message_ar',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });
  const { loading, error, data, fetchMore } = useQuery(ALL_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      first: 5,
      page: 1,
    },
  });

  if (error) return <p>{error}</p>;

  return (
    <Spin spinning={loading}>
      {data && data.allNotification && (
        <>
          <ListHeader>
            <PrimaryTitle>{t('Notifications')}</PrimaryTitle>
            <PrimaryButton onClick={() => history.push('/new-notification')}>
              {t('Add Notification')}
            </PrimaryButton>
          </ListHeader>
          <Table
            scroll={{ x: 400 }}
            dataSource={data.allNotification.data}
            columns={columns}
            loading={loading}
            pagination={{
              total:
                data &&
                data.allNotification &&
                data.allNotification.paginatorInfo &&
                data.allNotification.paginatorInfo.total,
              pageSize: 5,
              showSizeChanger: false,
              onChange: (page) => {
                fetchMore({
                  variables: {
                    page,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return fetchMoreResult;
                  },
                });
              },
            }}
          />
        </>
      )}
    </Spin>
  );
};

export default NotificationsList;
