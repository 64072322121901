import gql from 'graphql-tag';

export default gql`
  mutation createBusClass(
    $englishName: String!
    $arabicName: String!
    $busTypeIds: [ID!]!
    $facilities: [Facilities]
  ) {
    createBusClass(
      name_en: $englishName
      name_ar: $arabicName
      bus_type_ids: $busTypeIds
      facilities: $facilities
    ) {
      id
      name_en
      name_ar
      busTypes {
        id
        name
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;
