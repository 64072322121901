import gql from 'graphql-tag';

export const extendOrderExpiration = gql`
  mutation extendOrderExpiration(
    $order_number: String!
    $date: String!
    $time: String!
  ) {
    extendOrderExpiration(
      order_number: $order_number
      date: $date
      time: $time
    ) {
      status
      message
    }
  }
`;
