import gql from 'graphql-tag';

export const UpdateTripData = gql`
  mutation UpdateTrip($input: UpdateTripInput!) {
    UpdateTrip(input: $input) {
      message
      status
    }
  }
`;
