/**Component that displays work order of a trip instance */
import React, { useContext, useEffect, useRef } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import { ListHeader, PrimaryTitle, PrimaryButton, Can } from 'components';
import { PRINT_WORK_ORDER } from 'services';
import { useState } from 'react';
import { notify } from '../../utilities';

import ReactToPrint from 'react-to-print';

import { AuthContext } from '../../authContext';
import { useTranslation } from 'react-i18next';

const Container = styled.div``;

const WorkOrderContainer = styled.div`
  float: right;
  width: 100%;
  padding: 40px 40px;
  margin: 0 auto;
  .passenger {
    margin-bottom: 20px;
  }
  .printable {
    display: none;
    font-size: 10px;
  }
  @media print {
    font-size: 10px !important;
    .printable {
      display: block;
    }
    .tabel,
    .info {
      font-size: 10px !important;
    }
  }
`;

const Information = styled.div`
  margin-left: auto;
  justify-content: space-between;
  text-align: right;
  font-size: 16px;
  color: black;

  @media print {
    font-size: 10px !important;
  }
  .tabel {
    min-width: 20%;
    font-size: 14px;
  }

  .head {
    font-weight: bold;
    min-width: 20%;
  }
  .break {
    border-bottom: 2px dashed black;
    width: 100%;
    margin-top: 20px;
  }

  .info {
    min-width: 33.333%;
    font-size: 16px;
    font-weight: bold;
  }
  p {
    margin-bottom: 5px;
  }
`;

const Label = styled.p`
  padding-left: 20px;
  display: inline-block;
  margin-left: auto;
  font-weight: bold;
  flex: 1 1 0px;
  text-align: right;
  direction: rtl;
  min-width: 20%;
`;

const WorkOrder = ({ location: { state: { trip } = {} } }) => {
  const [t, i18n] = useTranslation();
  const [buses, setBuses] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [locations, setLocations] = useState([]);

  const authContext = useContext(AuthContext);
  const user = authContext && authContext.user;
  const [printWorkOrder] = useMutation(PRINT_WORK_ORDER);
  const componentRef = useRef();

  useEffect(() => {
    let busesList = [];
    let driversList = [];
    let locationList = [];
    trip.routeLines.map((routeLines) => {
      if (
        routeLines?.bus &&
        !busesList.find((bus) => bus == routeLines?.bus?.ref_code)
      )
        busesList.push(routeLines.bus.ref_code);

      routeLines.drivers.map((driverEntity) => {
        if (!driversList.find((driver) => driver == driverEntity.name))
          driversList.push(driverEntity.name);
      });
    });
    trip.locations_with_tickets.map((location) => {
      let count_paid = 0;
      let count_driver = 0;
      let total_amount_driver = 0;
      location.from_tickets.map((ticket) => {
        if (ticket.status == 'Paid') {
          count_paid++;
          if (ticket.order.driver_id > 0) {
            count_driver++;
            total_amount_driver += ticket.price;
          }
        }
      });
      locationList.push({
        name: location.name_ar,
        count: count_paid,
        date: location.pivot.date,
        time: location.pivot.time,
        count_driver,
        total_amount_driver,
      });
    });

    setLocations(locationList);
    setBuses(busesList);
    setDrivers(driversList);
  }, [trip]);

  return (
    <>
      <Container>
        <ListHeader>
          <PrimaryTitle>{t('Work Order')}</PrimaryTitle>
          <Can
            perform="VIEW_ACTIVE_TRIP_DETAILS"
            yes={
              user.onGoingShift && (
                <ReactToPrint
                  trigger={() => (
                    <PrimaryButton clean>{t("Print Work Order")}</PrimaryButton>
                  )}
                  onAfterPrint={() => {
                    printWorkOrder({
                      variables: {
                        id: trip.id,
                      },
                    })
                      .then((r) => {})
                      .catch((err) => {
                        const {
                          extensions: { validation },
                          message,
                        } = err['graphQLErrors'][0];

                        if (validation) {
                          for (let error in validation) {
                            notify('error', validation[error][0]);
                          }
                        } else {
                          notify('error', t(message));
                        }
                      });
                  }}
                  content={() => componentRef.current}
                />
              )
            }
          />
        </ListHeader>

        {trip && (
          <>
            <WorkOrderContainer ref={componentRef} dir="ltr">
              <Information>
                <Label className={'tabel'}>
                  {buses.map((bus) => {
                    return `${bus}  `;
                  })}
                </Label>
                <Label className={'head'}>رقم الاتوبيس:</Label>

                <Label className={'head'}>{trip.id}</Label>
                <Label className={'head'}>رقم امر الشغل:</Label>
              </Information>
              <Information>
                <Label>{trip.date}</Label>
                <Label>تاريخ الرحلة :</Label>

                <Label>{trip.ref_code}</Label>
                <Label>رقم الرحلة:</Label>
              </Information>
              <Information>
                {drivers.map((driver) => {
                  return <Label className={'tabel'}>{driver}</Label>;
                })}

                <Label>السائقين :</Label>
              </Information>
              <Information>
                <Label className={'head'}>تحرك</Label>
                <Label className={'head'}>تاريخ</Label>
                <Label className={'head'}>ركاب</Label>
                <Label className={'head'}>محطات</Label>
              </Information>
              {locations.map((location) => {
                return (
                  <Information>
                    <Label className={'tabel'}>{location.time}</Label>
                    <Label className={'tabel'}>{location.date}</Label>
                    <Label className={'tabel'}>{location.count}</Label>
                    <Label className={'tabel'}>{location.name}</Label>
                  </Information>
                );
              })}

              <Information className={'passenger'}>
                <Label className={'head'}>التاريخ</Label>
                <Label className={'head'}>مبلغ التحصيل</Label>
                <Label className={'head'}>عدد ركاب</Label>
                <Label className={'head'}>اركاب طريق </Label>
              </Information>

              {locations.map((location) => {
                if (location.count_driver)
                  return (
                    <Information>
                      <Label className={'tabel'}>{location.date}</Label>
                      <Label className={'tabel'}>
                        {location.total_amount_driver}
                      </Label>
                      <Label className={'tabel'}>{location.count_driver}</Label>
                      <Label className={'tabel'}>{location.name}</Label>
                    </Information>
                  );
              })}
              <Information>
                <Label className={'head info'}>عهدة مالية للسائق :</Label>
                <Label className={'head info'}>عدد الوجبات :</Label>
                <Label className={'head info'}>
                  عدد بوليصات الشحن : {trip.has_shipping}
                </Label>
              </Information>
              <Information>
                <Label className={'head info'}>عدد لترات السولار :</Label>
                <Label className={'head info'}>
                  {' '}
                  عداد الكيلومتر عند النهاية :
                </Label>
                <Label className={'head info'}>
                  {' '}
                  عداد الكيلومتر عند البداية :
                </Label>
              </Information>
              <Information>
                <Label className={'head info'}>توقيع السائقين :</Label>
              </Information>
              <Information>
                <Label className={'head info'}>توقيع قسم التشغيل :</Label>
              </Information>
              <Information>
                <Label className={'head info'}>ملاحظات :</Label>
              </Information>

              <div className={'printable'}>
                <Information>
                  <Label className={'break'}> </Label>
                </Information>
                <Information>
                  <Label className={'tabel'}>
                    {buses.map((bus) => {
                      return `${bus}  `;
                    })}
                  </Label>
                  <Label className={'head'}>رقم الاتوبيس:</Label>

                  <Label className={'head'}>{trip.id}</Label>
                  <Label className={'head'}>رقم امر الشغل:</Label>
                </Information>
                <Information>
                  <Label>{trip.date}</Label>
                  <Label>تاريخ الرحلة :</Label>

                  <Label>{trip.ref_code}</Label>
                  <Label>رقم الرحلة:</Label>
                </Information>
                <Information>
                  {drivers.map((driver) => {
                    return <Label className={'tabel'}>{driver}</Label>;
                  })}

                  <Label>السائقين :</Label>
                </Information>
                <Information>
                  <Label className={'head'}>تحرك</Label>
                  <Label className={'head'}>تاريخ</Label>
                  <Label className={'head'}>ركاب</Label>
                  <Label className={'head'}>محطات</Label>
                </Information>
                {locations.map((location) => {
                  return (
                    <Information>
                      <Label className={'tabel'}>{location.time}</Label>
                      <Label className={'tabel'}>{location.date}</Label>
                      <Label className={'tabel'}>{location.count}</Label>
                      <Label className={'tabel'}>{location.name}</Label>
                    </Information>
                  );
                })}

                <Information className={'passenger'}>
                  <Label className={'head'}>التاريخ</Label>
                  <Label className={'head'}>مبلغ التحصيل</Label>
                  <Label className={'head'}>عدد ركاب</Label>
                  <Label className={'head'}>اركاب طريق </Label>
                </Information>

                {locations.map((location) => {
                  if (location.count_driver)
                    return (
                      <Information>
                        <Label className={'tabel'}>{location.date}</Label>
                        <Label className={'tabel'}>
                          {location.total_amount_driver}
                        </Label>
                        <Label className={'tabel'}>
                          {location.count_driver}
                        </Label>
                        <Label className={'tabel'}>{location.name}</Label>
                      </Information>
                    );
                })}
                <Information>
                  <Label className={'head info'}>عهدة مالية للسائق :</Label>
                  <Label className={'head info'}>عدد الوجبات :</Label>
                  <Label className={'head info'}>
                    عدد بوليصات الشحن : {trip.has_shipping}
                  </Label>
                </Information>
                <Information>
                  <Label className={'head info'}>عدد لترات السولار :</Label>
                  <Label className={'head info'}>
                    {' '}
                    عداد الكيلومتر عند النهاية :
                  </Label>
                  <Label className={'head info'}>
                    {' '}
                    عداد الكيلومتر عند البداية :
                  </Label>
                </Information>
                <Information>
                  <Label className={'head info'}>توقيع السائقين :</Label>
                </Information>
                <Information>
                  <Label className={'head info'}>توقيع قسم التشغيل :</Label>
                </Information>
                <Information>
                  <Label className={'head info'}>ملاحظات :</Label>
                </Information>
              </div>
            </WorkOrderContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default WorkOrder;
