import gql from 'graphql-tag';

export const EXPORT_TICKETS_REPORT = gql`
  mutation exportTickets {
    exportTickets {
      status
      message
      data {
        downloadable_link
      }
    }
  }
`;

export default EXPORT_TICKETS_REPORT;
