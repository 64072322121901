import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { colors } from 'utilities';
import { isMobile, TabletView } from 'react-device-detect';
const ListHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  border-bottom: 1px solid ${colors.darkGray};
  justify-content: space-between;
  padding-bottom: 1%;
  flex-direction: ${isMobile ? 'column' : 'row'};
  align-items: ${isMobile ? 'unset' : 'center'};
  h1 {
    margin: 17px 0;
  }
`;

const ListHeader = ({ children }) => (
  <ListHeaderContainer>{children}</ListHeaderContainer>
);

ListHeader.propTypes = {
  /**
   * prop pagination of type object passed from list component
   */
  listDetails: PropTypes.object,
};

export default ListHeader;
