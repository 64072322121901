import gql from 'graphql-tag';

export const REPLACE_TICKET_ORDER = gql`
  mutation replaceTicketOrder($ticket_id: ID!, $cartToken: String!) {
    replaceTicketOrder(ticket_id: $ticket_id, cart_token: $cartToken) {
      status
      message
      order {
        id
        user_id
        price
        payment_method
        order_number
        transaction_id
        status
        tickets {
          id
          seat_number
          price
          created_at
          updated_at
        }
        created_at
        updated_at
      }
      edit_ticket {
        deduction_amount
      }
    }
  }
`;

export default REPLACE_TICKET_ORDER;
