import gql from 'graphql-tag';

export const ADD_CART_REPLACED = gql`
  mutation addReplacementTicketCart($ticket_id: ID!, $tickets: ticketsInfo!) {
    addReplacementTicketCart(ticket_id: $ticket_id, ticket_info: $tickets) {
      status
      message
    }
  }
`;

export default ADD_CART_REPLACED;
