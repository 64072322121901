import gql from 'graphql-tag';

export const GET_CREDITS_TRANSACTIONS = gql`
  query partnerSegmentCreditsTransactionsView($id: ID!) {
    partnerSegmentCreditsTransactionsView(id: $id) {
      transactions {
        id
        actor_name
        order_type
        order_id
        order_amount
        type
        created_at
        __typename
      }
      credits {
        id
        description
        user
        creditable_id
        type
        amount
        balance
        created_at
        __typename
      }
      exports {
        id
        link
        created_at
        user
        status
        __typename
      }
      __typename
    }
  }
`;

export default GET_CREDITS_TRANSACTIONS;
