import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Can from './Can';

const ProtectedRoute = ({
  perform,
  path,
  component: Component,
  ...routeProps
}) => {
  const view = props => (
    <Can
      perform={perform}
      yes={<Component {...props} />}
      no={() => <Redirect to="/unauthorized" />}
    />
  );

  return <Route render={props => view(props)} {...routeProps} />;
};

export default ProtectedRoute;
