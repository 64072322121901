import gql from 'graphql-tag';

export const DEACTIVATE_TRIP = gql`
  mutation deactivateTrip($id: ID!) {
    deactivateTrip(id: $id) {
      status
      message
      message_ar
    }
  }
`;

export default DEACTIVATE_TRIP;
