import styled from '@emotion/styled/macro';
import { colors } from 'utilities';

const PrimaryTitle = styled.h1`
  color: ${colors.primaryColor};
  font-size: 33px;
  line-height: 1.6;
  margin: 17px 25px !important;
  font-weight:bold;
`;

export { PrimaryTitle };
