import React from 'react';
import { Table, Card } from 'antd';
import { useTranslation } from 'react-i18next';

const RouteLines = ({ cardTitle, routeLines }) => {
  const routeLinesPerSeatType = [];
  const [t, i18n] = useTranslation();
  const seatLang = i18n.language;
  const nameLang = i18n.language;
  const columns = [
    {
      title: 'From',
      dataIndex: ['fromCityName', `name_${nameLang}`],
    },
    {
      title: 'To',
      dataIndex: ['toCityName', `name_${nameLang}`],
    },
    {
      title: 'Seat Type',
      dataIndex: ['seatType', `name_${seatLang}`],
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  routeLines.forEach((route) => {
    route.prices.forEach((pricePerSeatType) => {
      const {
        id: routeLineId,
        from_city:  fromCityName ,
        to_city:  toCityName ,
      } = route;
      const {
        seat_type_id: seatType,
        price,
        bus_salon_id: busSalonId,
      } = pricePerSeatType;
      routeLinesPerSeatType.push({
        key: `${routeLineId}-${busSalonId}-${seatType.id}`,
        fromCityName,
        toCityName,
        seatType,
        price,
      });
    });
  });
  return (
    <Card title={cardTitle}>
      <Table
        scroll={{ x: 400 }}
        dataSource={routeLinesPerSeatType}
        columns={columns}
        pagination={false}
      />
    </Card>
  );
};

export default RouteLines;
