import gql from 'graphql-tag';

export const GET_ALL_AGENTS = gql`
  query all_agents {
    all_agents {
      id
      user_name
      totals
      credit_balance
      enable_borrowing
    }
  }
`;

export default GET_ALL_AGENTS;
