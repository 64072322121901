import gql from 'graphql-tag';

const GET_CITIES = gql`
  query getCitiesList($first: Int!, $page: Int!) {
    cities(first: $first, page: $page) {
      paginatorInfo {
        total
      }
      data {
        id
        code
        name_en
        name_ar
        lat
        long
        is_active
        is_seasonal
      }
    }
  }
`;

export default GET_CITIES;
