import gql from 'graphql-tag';

const DELETE_LOCATION = gql`
  mutation deleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      id
      name_en
    }
  }
`;

export default DELETE_LOCATION;
