/**Component that displays the available seat types in a bus salon */
import React from 'react';
import { Card, Table } from 'antd';
import { useTranslation } from 'react-i18next';

const SeatTypeInfo = ({ seatTypes }) => {
  const [t, i18n] = useTranslation();

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
    },
    {
      title: t('Seat Type Name'),
      dataIndex: 'name_en',
    },
  ];

  return (
    <Card>
      <Table
        dataSource={seatTypes.map((seatType) => ({
          ...seatType,
          key: seatType.id,
        }))}
        columns={columns}
        pagination={false}
        scroll={{ x: 400 }}
      />
    </Card>
  );
};

export default SeatTypeInfo;
