import gql from 'graphql-tag';

export const ADD_MONEY_TO_SHIFT = gql`
  mutation addOrDownToShiftLog($id: ID!, $amount: Float!, $comment: String!) {
    addOrDownToShiftLog(id: $id, amount: $amount, comment: $comment) {
      status
      message
    }
  }
`;

export default ADD_MONEY_TO_SHIFT;
