import gql from 'graphql-tag';

export const FORCE_CANCEL_TICKET = gql`
  mutation adminForceCancelTicket($id: ID!, $deduction_amount: Float!) {
    adminForceCancelTicket(id: $id, deduction_amount: $deduction_amount) {
      status
      message
      message_ar
    }
  }
`;

export default FORCE_CANCEL_TICKET;
