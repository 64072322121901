import gql from 'graphql-tag';

const ACTIVATE_CITY = gql`
  mutation activateCity($id: ID!) {
    activateCity(id: $id) {
      id
      name_en
    }
  }
`;

export default ACTIVATE_CITY;
