/** This component is responsible for creating new partner segment */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import styled from '@emotion/styled/macro';
import { Form, Input, Button, Spin, Row, Col, Popconfirm } from 'antd';
import { FormContainer, ActionButton, CancelButton, notify } from 'utilities';
import { ListHeader, PrimaryTitle } from 'components';
import { CREATE_SEGMENT } from 'services';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const NewSegmentContainer = styled.section`
width: 100%;
height: 100%;
flex-wrap: wrap;
  ${FormContainer}
  ${ActionButton}
  ${CancelButton}
`;

const SegmentForm = () => {
  const history = useHistory();
  const [t, i18n] = useTranslation();
  const [createPartnerSegment, { loading }] = useMutation(CREATE_SEGMENT);

  // submit (create segment form) inputs
  const onFinish = (values) => {
    const { name, phone } = values;
    createPartnerSegment({
      variables: {
        name,
        phone,
      },
    })
      .then((response) => {
        const {
          data: {
            createPartnerSegment: { status, message },
          },
        } = response;
        if (status) {
          notify('success', message);
          history.push('/segments');
        }
      })
      .catch((err) => {
        const {
          extensions: { validation },
          message,
        } = err['graphQLErrors'][0];

        if (validation) {
          for (let error in validation) {
            notify('error', validation[error][0]);
          }
        } else {
          notify('error', t(message));
        }
      });
  };
  return (
    <NewSegmentContainer>
      <ListHeader>
        <PrimaryTitle>{t('Create Partner Segment')}</PrimaryTitle>
      </ListHeader>
      <Spin spinning={loading}>
        <Form onFinish={onFinish} layout="vertical">
          <Row gutter={10}>
            <Col span={12}>
              <Item
                name={t('name')}
                label={t('Segment Name')}
                rules={[
                  {
                    required: true,
                    message: t('Please, enter the segments name!'),
                  },
                ]}
              >
                <Input placeholder={t('Segment Name')} size="large" />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                name={t('phone')}
                label={t('Phone Number')}
                rules={[
                  {
                    required: true,
                    message: t('Please, enter a valid phone number!'),
                    // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                  },
                ]}
              >
                <Input placeholder="e.g. 01123456789" size="large" />
              </Item>
            </Col>
          </Row>
          <Row gutter={10} align="middle">
            <Col span={28}>
              <Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                >
                  {loading ? 'Creating' : 'Create'}
                </Button>
              </Item>
            </Col>

            <Col span={8}>
              <Item style={{ margin: 0 }}>
                <Popconfirm
                  title={t('Are you sure you want to discard?')}
                  okText={t('Yes')}
                  cancelText={t('No')}
                  onConfirm={() => history.goBack()}
                  onCancel={() => {}}
                >
                  <Button type="link" size="large" danger>
                    {t('Cancel')}
                  </Button>
                </Popconfirm>
              </Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </NewSegmentContainer>
  );
};

export default SegmentForm;
