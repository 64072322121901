import gql from 'graphql-tag';

export const CONFIRM_ORDER = gql`
  mutation order(
    $orderNumber: String!
    $paymentMethod: ConfirmPaymentMethods!
    $transactionId: String
  ) {
    confirmStorePayment(
      order_number: $orderNumber
      payment_method: $paymentMethod
      transaction_id: $transactionId
    ) {
      status
      message
    }
  }
`;
