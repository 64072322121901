import gql from 'graphql-tag';

export const GENERATE_CUSTOMER_CODE = gql`
  mutation adminGenerateCustomerCode($id: ID!) {
    adminGenerateCustomerCode(id: $id) {
      status
      message
      message_ar
    }
  }
`;

export default GENERATE_CUSTOMER_CODE;
