import gql from 'graphql-tag';

const USER_LOGOUT = gql`
  mutation userLogout {
    userLogout {
      code
      success
      message
    }
  }
`;

export default USER_LOGOUT;
