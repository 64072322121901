import gql from 'graphql-tag';

export const GET_PRINT_TICKET_FEES = gql`
  query checkPrintTicketFees($id: [ID!]) {
    checkPrintTicketFees(id: $id) {
      status
      message
      printFees
    }
  }
`;

export default GET_PRINT_TICKET_FEES;
