import gql from 'graphql-tag';

export const SEND_DAILY_REPORT = gql`
  query sendDailyMailReport {
    sendDailyMailReport {
      status
      message
    }
  }
`;

export default SEND_DAILY_REPORT;
