import gql from 'graphql-tag';

export const HANDLE_STOPPED_TICKET = gql`
  mutation changeStoppedTripTicket(
    $id: ID!
    $seatNumber: Int!
    $refundAmount: Int
  ) {
    changeStoppedTripTicket(
      id: $id
      seat_number: $seatNumber
      refund_amount: $refundAmount
    ) {
      status
      message
      message_ar
    }
  }
`;

export default HANDLE_STOPPED_TICKET;
