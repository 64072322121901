import gql from 'graphql-tag';

const CREATE_CITY = gql`
  mutation createCity(
    $code: String!
    $name_en: String!
    $name_ar: String!
    $lat: Float!
    $long: Float!
    $image: Upload!
    $isSeasonal: Boolean!
    $seasonalPrice: Int
  ) {
    createCity(
      code: $code
      name_en: $name_en
      name_ar: $name_ar
      lat: $lat
      long: $long
      is_active: Active
      image: $image
      is_seasonal: $isSeasonal
      seasonal_price: $seasonalPrice
    ) {
      id
      code
      name_en
      name_ar
      lat
      long
      created_at
      updated_at
    }
  }
`;

export default CREATE_CITY;
