import gql from 'graphql-tag';

export const GET_TRIP_INSTANCES = gql`
  query trips(
    $first: Int!
    $page: Int
    $tripId: ID
    $templateCode: String
    $date: Date
    $fromDate: Date
    $toDate: Date
    $fromStation: ID
    $toStation: ID
  ) {
    trips(
      first: $first
      page: $page
      id: $tripId
      ref_code: $templateCode
      date: $date
      from_date: $fromDate
      to_date: $toDate
      from_station: $fromStation
      to_station: $toStation
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
        currentPage
      }
      data {
        id
        ref_code
        is_active
        has_shipping
        seats_tickets {
          id
        }
        date
        status
        time
        unconfirmed_stopped_ticket {
          id
          code
          status
        }
        locations_with_tickets {
          id
          name_en
          name_ar
          pivot {
            date
            time
          }
          from_tickets {
            id
            price
            status

            order {
              id
              driver_id
            }
          }
        }
        busSalon {
          id
          name
        }
        routeLines {
          id
          bus {
            id
            ref_code
            bus_type {
              id
              name
            }
          }
          drivers {
            id
            name
          }
        }
      }
    }
  }
`;

export default GET_TRIP_INSTANCES;
