import gql from 'graphql-tag';

export default gql`
  mutation userLogin($phone: String!, $password: String!) {
    userLogin(phone: $phone, password: $password) {
      code
      success
      message
      token: access_token
      user {
        id
        name
        station {
          id
          name_en
        }
      }
      role {
        id
        name
        permissions {
          id
          name
        }
      }
    }
  }
`;
