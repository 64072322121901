import gql from 'graphql-tag';

export const FETCH_ORDERS = gql`
  query orders(
    $first: Int!
    $page: Int!
    $orderNumber: String
    $customerPhone: String
    $agent_id: String
    $payment_method: OrderPaymentMethods
    $status: OrderStatus
    $transaction_id: String
    $affiliate: String
  ) {
    orders(
      first: $first
      page: $page
      order_number: $orderNumber
      customer_phone: $customerPhone
      status: $status
      agent_id: $agent_id
      payment_method: $payment_method
      transaction_id: $transaction_id
      affiliate: $affiliate
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        hasMorePages
        total
      }
      data {
        id
        customer {
          id
          name
          phone
        }
        price
        payment_method
        order_number
        kiosk_number
        transaction_id
        affiliate
        created_at
        source
        status
        agent {
          id
          user_name
        }
        tickets {
          id
          code
          seat_number
          seat_type {
            id
            name_en
            name_ar
          }
          price
          trip {
            id
            ref_code
            date
            time
          }
        }
        created_at
        updated_at
      }
    }
  }
`;
