import gql from 'graphql-tag';

export const GET_ROUTELINES_SHIPMENTS = gql`
  query getTripShipments($routeLinesIds: [ID]!) {
    getTripShipments(
        routeLinesIds: $routeLinesIds
    ) {
        id
        order_number
        sender {
          id
          name
          phone
          national_id
        }
        receiver {
          id
          name
          phone
          national_id
        }
        from_location {
          id
          name_en
          name_ar
        }
        to_location {
          id
          name_en
          name_ar
        }
        trip_route_line {
          id
          trip {
            id
            ref_code
            date
            time
          }
          from_city {
            id
            name_en
            name_ar
          }
          to_city {
            id
            name_en
            name_ar
          }
        }
        status {
          value
          label
        }
        total_price
        items {
          id
          name
          price
          initial_weight
          extra_weight
          quantity
          category {
            id
            name
          }
        }
    }
  }
`;

export default GET_ROUTELINES_SHIPMENTS;
