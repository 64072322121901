import gql from 'graphql-tag';

export const EXPORT_SEGMENTS_CREDITS_REPORT = gql`
  mutation exportPartnerSegmentsCredits($id: ID!, $from: String, $to: String) {
    exportPartnerSegmentsCredits(id: $id, from: $from, to: $to) {
      status
      message
      data {
        downloadable_link
      }
      __typename
    }
  }
`;

export default EXPORT_SEGMENTS_CREDITS_REPORT;
