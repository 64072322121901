import gql from 'graphql-tag';

export const EXPORT_TRIPS_REPORT_BACKGROUND = gql`
  mutation exportTripsBackground {
    exportTripsBackground {
      status
      message
    }
  }
`;

export default EXPORT_TRIPS_REPORT_BACKGROUND;
