import gql from 'graphql-tag';

const SEARCH_CUSTOMERS = gql`
  query searchCustomers($customerPhoneNumber: String!) {
    searchCustomers(phone: $customerPhoneNumber) {
      id
      name
      phone
      email
      registered
    }
  }
`;

export default SEARCH_CUSTOMERS;
