import gql from 'graphql-tag';

const UPDATE_SHIFT = gql`
  mutation updateStationShift($id: ID!, $startTime: String, $endTime: String) {
    updateStationShift(id: $id, start_time: $startTime, end_time: $endTime) {
      status
      message
      data {
        id
        code
        name
        start_time
        end_time
      }
    }
  }
`;

export default UPDATE_SHIFT;
