import gql from 'graphql-tag';

const SEND_NOTIFICATION = gql`
  mutation sendNotification(
    $title_en: String!
    $title_ar: String!
    $message_en: String!
    $message_ar: String!
    $to: Terget!
    $csv_file: Upload
    $customers_ids: [ID]
  ) {
    sendNotification(
      title_en: $title_en
      title_ar: $title_ar
      message_en: $message_en
      message_ar: $message_ar
      to: $to
      csv_file: $csv_file
      customers_ids: $customers_ids
    ) {
      id
      created_at
    }
  }
`;

export default SEND_NOTIFICATION;
