import gql from 'graphql-tag';

export const getTrip = gql`
  query getTrip($id: ID!) {
    getTrip(id: $id) {
      trip_template {
        id
      }
      ref_code
      updated_at
      seats_tickets {
        id
        payment_method
        status
        from_time
        from_date
        code
        trip {
          id
        }
        id
        routeLine {
          id
        }
        seat_number
        from_location {
          id
          name_en
          city {
            id
            name_en
          }
        }
        to_location {
          id
          name_en
          city {
            id
            name_en
          }
        }
        seat_type {
          id
          name_en
        }
        price
        customer {
          id
          name
          phone
          registered
        }
      }
      routeLines {
        id
        from_city {
          id
          name_en
          name_ar
        }
        to_city {
          id
          name_en
          name_ar
        }
        bus {
          id
          ref_code
        }
        drivers {
          id
          name
        }
      }
      unconfirmed_stopped_ticket {
        id
        code
        status
        trip {
          id
        }
        id
        routeLine {
          id
        }
        seat_number
        from_location {
          id
          name_en
          city {
            id
            name_en
          }
        }
        to_location {
          id
          name_en
          city {
            id
            name_en
          }
        }
        seat_type {
          id
          name_en
        }
        price
        customer {
          id
          name
          phone
          registered
        }
      }
    }
  }
`;

export default getTrip;
