import gql from 'graphql-tag';

const GET_NOTIFICATIONS = gql`
  query allNotification($first: Int!, $page: Int) {
    allNotification(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        total
        count
      }
      data {
        id
        title_en
        title_ar
        message_ar
        created_at
      }
    }
  }
`;

export default GET_NOTIFICATIONS;
