import gql from 'graphql-tag';

const forgotPassword = gql`
  mutation userForgotPassword($phone: String!) {
    userForgotPassword(phone: $phone) {
      code
      success
      message
    }
  }
`;

export default forgotPassword;
