import gql from 'graphql-tag';

const START_SHIFT = gql`
  mutation startStationShift($id: ID!) {
    startStationShift(id: $id) {
      status
      message
      data {
        id
        shift {
          id
          code
        }
        started_at
        ended_at
      }
    }
  }
`;

export default START_SHIFT;
