import gql from 'graphql-tag';

export const GET_AGENTS = gql`
  query agents($first: Int!, $page: Int) {
    agents(first: $first, page: $page) {
      paginatorInfo {
        count
        total
        currentPage
      }
      data {
        id
        user_name
        totals
        credit_balance
        enable_borrowing
      }
    }
  }
`;
export const GET_AGENT_LOGS = gql`
    query agentLogs($first: Int!, $page: Int, $agent_id: ID!) {
        agentLogs(first: $first, page: $page,agent_id:$agent_id) {
            paginatorInfo {
                count
                total
                currentPage
            }
            data {
                id
                action
                amount
                transaction
            }
        }
    }
`;

export default GET_AGENTS;
