import gql from 'graphql-tag';

const EDIT_SHIPPING_ORDER = gql`
  mutation editShippingOrder($input: editShippingOrderInput!) {
    editShippingOrder(input: $input) {
      status
      message
      data {
        id
        id
        order_number
        sender {
          id
          name
          phone
          national_id
        }
        receiver {
          id
          name
          phone
          national_id
        }
        total_price
        items {
          id
          name
          price
          initial_weight
          extra_weight
          quantity
          category {
            id
            name
          }
        }
      }
    }
  }
`;

export default EDIT_SHIPPING_ORDER;
