import gql from 'graphql-tag';

const PRIVATE_TRIPS = gql`
  query privateTripsList($first: Int!, $page: Int) {
    privateTripsList(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        total
        count
      }
      data {
        id
        name
        phone
        traveler_number
        comment
        created_at
        bus_type {
          id
          name
        }
        from_location {
          id
          name_en
          name_ar
        }
        to_location {
          id
          name_en
          name_ar
        }
        from_city {
          id
          name_en
          name_ar
        }
        to_city {
          id
          name_en
          name_ar
        }
        from_date
        to_date
        is_read
        is_valid
      }
    }
  }
`;

export default PRIVATE_TRIPS;
