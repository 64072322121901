import gql from 'graphql-tag';

export const EXPORT_SEGMENTS_REPORT = gql`
  mutation exportPartnerSegments($from: String!, $to: String!) {
    exportPartnerSegments(from: $from, to: $to) {
      status
      message
      data {
        downloadable_link
      }
    }
  }
`;

export default EXPORT_SEGMENTS_REPORT;
