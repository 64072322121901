export const handleSeatPricing = values => {
  const finalValues = {
    create: [],
  };
  Object.keys(values).forEach(trip => {
    const [from_city_id, to_city_id] = trip.split(',');
    const tripX = {
      from_city_id,
      to_city_id,
      prices: { create: [] },
    };
    Object.keys(values[trip]).forEach(busSalon => {
      Object.keys(values[trip][busSalon]).forEach(seatType => {
        tripX.prices.create.push({
          seat_type_id: seatType,
          bus_salon_id: busSalon,
          price: values[trip][busSalon][seatType],
        });
      });
    });
    finalValues.create.push(tripX);
  });
  return finalValues;
};
