import gql from 'graphql-tag';

export const searchBusses = gql`
  query searchBusses($code: String!, $bus_salon_id: Int!) {
    searchBusses(code: $code, bus_salon_id: $bus_salon_id) {
      id
      ref_code
    }
  }
`;

export default searchBusses;
