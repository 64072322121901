import gql from 'graphql-tag';

export const PRINT_TICKET = gql`
  mutation printTicket(
    $id: ID!
  ) {
    printTicket(
      id: $id
    ) {
      status
      message
    }
  }
`;

export default PRINT_TICKET;
