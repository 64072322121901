import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { Space, Table, Form, Button, Modal, InputNumber, Input } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { notify, ValidateUser } from 'utilities';
import { ListHeader, PrimaryTitle } from 'components';
import { Get_Settings } from '../../services/settings';
import updateSettings from '../../services/settings/update-settings';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const SettingsListContainer = styled.div``;

const SettingsList = () => {
  ValidateUser();
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();

  const { data, loading, fetchMore, refetch } = useQuery(Get_Settings, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [updateVersionVisible, SetUpdateVersionVisible] = useState(false);
  const [updateSetting, { loading: updating }] = useMutation(updateSettings);
  const updateVersion = () => {
    form.validateFields().then((values) => {
      const { code, build_number } = values;
      updateSetting({
        variables: {
          code: code || null,
          build_number: build_number || null,
          id: 1,
        },
      })
        .then((response) => {
          refetch();
          const res = response?.data?.update_settings;
          if (res.status === true) {
            notify('success', res.message);
          } else {
            notify('error', 'some thing went wrong');
          }
          form.resetFields();
          SetUpdateVersionVisible(false);
        })
        .catch((err) => {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];

          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', message);
          }
        });
    });
  };
  const dataSource = [
    {
      key: '1',
      id: data?.settings?.id,
      latest_active_code: data?.settings?.latest_active_code,
      build_number: data?.settings?.build_number,
    },
  ];
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Latest Active code Android',
      dataIndex: 'latest_active_code',
    },
    {
      title: 'build ios',
      dataIndex: 'build_number',
    },
    {
      title: 'Action',
      render: () => {
        return (
          <>
            <Button onClick={() => SetUpdateVersionVisible(true)}>
              {t('Update Setting')}
            </Button>
          </>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  return (
    <SettingsListContainer>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>
          {t('Settings List')}
        </PrimaryTitle>
      </ListHeader>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        loading={loading || updating}
        rowKey="id"
      />

      <Modal
        visible={updateVersionVisible}
        onOk={updateVersion}
        onCancel={() => {
          SetUpdateVersionVisible(false);
        }}
      >
        <Form onFinish={updateVersion} layout="vertical" form={form}>
          <Item
            name="code"
            label={t('Please Android Code')}
            rules={[
              {
                required: true,
                message: t('Please, enter the Android Code!'),
              },
            ]}
          >
            <InputNumber></InputNumber>
          </Item>
          <Item
            name="build_number"
            label={t('Please Insert Build Number IOS')}
            rules={[
              {
                required: true,
                message: t('Please, enter the Build Number IOS!'),
              },
            ]}
          >
            <Input></Input>
          </Item>
        </Form>
      </Modal>
    </SettingsListContainer>
  );
};

export default SettingsList;
