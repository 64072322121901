import React from 'react';
import { Table } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { ValidateUser } from 'utilities';
import { useParams } from 'react-router-dom';
import { GET_CREDITS_TRANSACTIONS } from 'services';
import { PrimaryTitle } from 'components';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';

const SegmentWrapper = styled.div`
  padding-top: 15px;
`;

const ViewSegment = () => {
  ValidateUser();
  const { id } = useParams();
  const [t,i18n]=useTranslation();
  const { data, loading } = useQuery(GET_CREDITS_TRANSACTIONS, {
    variables: {
      id: id,
    },
  });
  const transactionsColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Username',
      dataIndex: ['actor_name'],
    },
    {
      title: 'Order Type',
      render: (item) => {
        let type = item.order_type.split('\\');
        return type.pop();
      },
    },
    {
      title: 'Order No.',
      dataIndex: 'order_id',
    },
    {
      title: 'Total',
      dataIndex: 'order_amount',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
    },
  ];
  const creditsColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'User',
      dataIndex: 'user',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Credit Type',
      dataIndex: 'type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
    },
  ];
  const jobcolumns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'status',
      render: (job) => {
        if (job?.link) return 'Ended';
        else return 'Started';
      },
    },
    {
      title: 'user',
      dataIndex: 'user',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
    },
    {
      title: 'link',
      render: (job) => {
        if (job?.link)
          return (
            <a href={job?.link} target="_blank" rel="noopener noreferrer">
              Download
            </a>
          );
        else return 'processing';
      },
    },
  ];
  
  //Translate Function For columns
  const ti81n = (columns) =>
    columns.map((ele) => {
      ele.title = t(ele.title);
    });
  ti81n(transactionsColumns);
  ti81n(creditsColumns);
  ti81n(jobcolumns);

  return (
    <SegmentWrapper>
      {data?.partnerSegmentCreditsTransactionsView?.exports ? (
        <>
          <PrimaryTitle>{t("Job Lists")}</PrimaryTitle>
          <Table
            style={{ paddingBottom: '20px' }}
            scroll={{ x: 400, y: 200 }}
            dataSource={data?.partnerSegmentCreditsTransactionsView?.exports}
            columns={jobcolumns}
            loading={loading}
            rowKey="id"
            pagination={false}
            bordered
          />
        </>
      ) : null}

      <PrimaryTitle>{t("Partner Segment Transactions")}</PrimaryTitle>
      <Table
        style={{ paddingBottom: '20px' }}
        scroll={{ x: 400, y: 200 }}
        dataSource={data?.partnerSegmentCreditsTransactionsView?.transactions}
        columns={transactionsColumns}
        loading={loading}
        rowKey="id"
        pagination={false}
        bordered
      />
      <PrimaryTitle>{t("Partner Segment Credits")}</PrimaryTitle>
      <Table
        scroll={{ x: 400, y: 200 }}
        dataSource={data?.partnerSegmentCreditsTransactionsView?.credits}
        columns={creditsColumns}
        loading={loading}
        rowKey="id"
        pagination={false}
        bordered
      />
    </SegmentWrapper>
  );
};

export default ViewSegment;
