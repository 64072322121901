import gql from 'graphql-tag';

const DELETE_CITY = gql`
  mutation deleteAddons($id: ID!) {
    deleteAddons(id: $id) {
      id
      name_en
    }
  }
`;

export default DELETE_CITY;
