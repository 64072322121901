import { css } from '@emotion/core';

import { colors } from 'utilities';

const FormContainer = css`
  display: inline-block;
  background-color: ${colors.gray};

  button {
    display: inline-block;
  }

  .ant-form {
    padding: 40px;
  }

  .ant-form-item-label {
    color: ${colors.primaryColor};
    font-weight: 500;
  }
`;

const ActionButton = css`
  .ant-btn-primary {
    height: 50px;
    min-width: 170px;
    border-radius: 0;
    background-color: ${colors.primaryColor};
    border-color: ${colors.primaryColor};

    &:focus {
      background-color: ${colors.lightBlue};
      border-color: ${colors.lightBlue};
    }

    :active {
      background-color: ${colors.lightBlue};
      border-color: ${colors.lightBlue};
    }

    &:hover {
      background-color: ${colors.blue};
      border-color: ${colors.blue};
    }
  }
`;

const CancelButton = css`
  .ant-btn-link {
    color: ${colors.primaryColor};

    span {
      text-decoration: underline;
    }

    &:focus {
      color: ${colors.lightBlue};
    }

    :active {
      color: ${colors.lightBlue};
    }

    &:hover {
      color: ${colors.blue};
    }
  }
`;

export { FormContainer, ActionButton, CancelButton };
