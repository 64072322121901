import gql from 'graphql-tag';

const GENERATE_DRIVER_CODE = gql`
  mutation generateDriverCode($id: ID!) {
    generateDriverCode(id: $id) {
      status
      message
    }
  }
`;

export default GENERATE_DRIVER_CODE;
