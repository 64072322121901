import gql from 'graphql-tag';

export const EXPORT_Orders_REPORT = gql`
  mutation exportOrdersReport {
    exportOrdersReport {
      status
      message
      data {
        downloadable_link
      }
    }
  }
`;

export default EXPORT_Orders_REPORT;
