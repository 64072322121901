import gql from 'graphql-tag';

export const GET_LOCATIONS = gql`
  query getLocationsList($first: Int!, $page: Int!, $orderBy: OrderLang!) {
    locations(first: $first, page: $page, orderBy: $orderBy) {
      paginatorInfo {
        total
      }
      data {
        id
        code
        name_en
        name_ar
        type
        city {
          id
          name_en
          name_ar
        }
        is_active
      }
    }
  }
`;

export default GET_LOCATIONS;
