import gql from 'graphql-tag';

const GENERATE_CODE = gql`
  mutation userGenerateVerificationCode($id: ID!) {
    userGenerateVerificationCode(id: $id) {
      success
      message
    }
  }
`;

export default GENERATE_CODE;
