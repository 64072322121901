import gql from 'graphql-tag';

const CREATE_USER = gql`
  mutation createUser(
    $name: String!
    $email: String!
    $phone: String!
    $role: String!
    $station: ID
    $segment: ID
  ) {
    userCreate(
      name: $name
      email: $email
      phone: $phone
      role: $role
      station_id: $station
      partner_segment_id: $segment
    ) {
      status
      message
      data {
        id
        name
      }
    }
  }
`;

export default CREATE_USER;
