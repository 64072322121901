import gql from 'graphql-tag';

const CREATE_SEGMENT = gql`
  mutation createPartnerSegment($name: String!, $phone: String!) {
    createPartnerSegment(name: $name, phone: $phone) {
      status
      message
      data {
        id
        name
      }
    }
  }
`;

export default CREATE_SEGMENT;
