import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

const CheckoutWrapper = styled.div`
  width: 90%;
  margin: 40px auto;
  max-width: 1400px;
  display: flex;
  align-items: flex-start;

  iframe {
    border: none;
  }
`;

const Payment = () => {
  const history = useHistory(),
    {
      location: {
        state: { paymentOption, paymentToken, fawryChargeRequest } = {},
      },
    } = history;

  if (!paymentOption || !(paymentToken || fawryChargeRequest)) {
    history.push('/checkout');
  }

  if (paymentOption === 'Fawry') {
    window.FawryPay.checkout(
      fawryChargeRequest,
      window.location.origin + '/orders', // Success URL
      window.location.origin + '/ticketing' // Failure URL.
    );
    return null;
  }

  // TODO: move those iframe ids into named constants
  const iframeId = paymentOption === 'Valu' ? '19865' : process.env.REACT_APP_ACCEPT_IFRAME_ID;

  return (
    <CheckoutWrapper>
      <iframe
        width="100%"
        height="1000"
        title="payment"
        src={`https://accept.paymobsolutions.com/api/acceptance/iframes/${iframeId}?payment_token=${paymentToken}`}
      />
    </CheckoutWrapper>
  );
};

export default Payment;
