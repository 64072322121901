/**This component renders a view to enter
 * verification code that was sent (sms), new password and
 * updates the account with the new password
 */
import React, { useContext } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Divider, Spin } from 'antd';
import styled from '@emotion/styled/macro';
import { notify } from 'utilities';
import { useHistory, Redirect, Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { resetPassword as RESET_PASSWORD } from 'services';

const { Item } = Form;

const ChangePasswordContainer = styled.div`
  padding: 10%;

  .loginBtn {
    margin-right: 32px;
  }
`;

const ChangePassword = ({
  form: { validateFields, getFieldDecorator, getFieldValue },
  phone,
}) => {
  const [resetPassword, { loading: loggingIn }] = useMutation(RESET_PASSWORD);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        values.phone = phone;

        resetPassword({
          variables: values,
        })
          .then((res) => {
            const {
              data: { userResetPassword },
            } = res;

            if (!userResetPassword.success) {
              return notify('error', userResetPassword.message);
            }

            notify('success', userResetPassword.message);
            history.push('/login');
          })
          .catch((err) => {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];

            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          });
      }
    });
  };

  return (
    <ChangePasswordContainer>
      <h1>Blue Bus Dashboard</h1>
      <Divider />
      <h2>Reset password for your {phone} account</h2>
      <p>
        Enter the verification code you received, along with your new password
        to reset your login credentials
      </p>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col span={8}>
            <Item>
              {getFieldDecorator('code', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter the verification code',
                  },
                ],
              })(<Input size="large" placeholder="verification code"></Input>)}
            </Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Item>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Password must be at least 8 characters',
                    min: 8,
                  },
                ],
              })(<Input size="large" placeholder="password" type="password" />)}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Item>
              {getFieldDecorator('password_confirmation', {
                rules: [
                  {
                    required: true,
                    message: 'Please re-enter your password',
                  },
                  {
                    validator: (_, value, callback) => {
                      if (value !== getFieldValue('password')) {
                        callback('Passwords do not match');
                      } else {
                        callback();
                      }
                    },
                  },
                ],
              })(
                <Input
                  size="large"
                  placeholder="confirm password"
                  type="password"
                />
              )}
            </Item>
          </Col>
        </Row>

        <Item>
          <Button
            className="loginBtn"
            loading={loggingIn}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
          <Link to="/login">Back to login</Link>
        </Item>
      </Form>
    </ChangePasswordContainer>
  );
};

const ChangePasswordForm = Form.create({})(ChangePassword);

export default ChangePasswordForm;
