import gql from 'graphql-tag';

const END_SHIFT = gql`
  mutation endStationShift($id: ID!) {
    endStationShift(id: $id) {
      status
      message
      data {
        id
        shift {
          id
          code
        }
        started_at
        ended_at
      }
    }
  }
`;

export default END_SHIFT;
