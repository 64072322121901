import gql from 'graphql-tag';

export const UPDATE_TRIP_PRICE = gql`
  mutation updateTripPrice(
    $templateId: ID!
    $price: Float!
    $type: Int!
    $from: Int!
    $to: Int!
    $new_price: Float!
  ) {
    updateTripPrice(
      templateId: $templateId
      price: $price
      type: $type
      from: $from
      to: $to
      new_price: $new_price
    ) {
      message
      status
    }
  }
`;
export default UPDATE_TRIP_PRICE;
