import gql from 'graphql-tag';

export default gql`
  query allPermissions($first: Int!, $page: Int, $id: ID) {
    allPermissions(
      first: $first
      page: $page
      id: $id
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
        currentPage
      }
      data {
        id
        name
      }
    }
  }
`;
