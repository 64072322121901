import gql from 'graphql-tag';

export const FETCH_VEHICLES = gql`
  query {
    allBusses {
      id
      resource
      ref_code
      licence_no
      mileage_no
      bus_type {
        id
        name
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;
