import gql from 'graphql-tag';

export const PRINT_WORK_ORDER = gql`
  mutation printWorkOrder(
    $id: ID!
  ) {
    printWorkOrder(
      id: $id
    ) {
      status
      message
    }
  }
`;

export default PRINT_WORK_ORDER;
