import gql from 'graphql-tag';

export default gql`
  mutation createAdminOrder(
    $customerId: ID!
    $cartToken: String!
    $paymentMethod: AdminPaymentMethods!
    $transactionId: String
    $editTicket: editTicketOrder
    $adminOrder: Boolean
    $upgrade: Boolean
  ) {
    createAdminOrder(
      customer_id: $customerId
      cart_token: $cartToken
      payment_method: $paymentMethod
      transaction_id: $transactionId
      edit_ticket: $editTicket
      admin_order: $adminOrder
      upgrade: $upgrade
    ) {
      status
      message
      message_signature
      order {
        id
        user_id
        price
        payment_method
        order_number
        transaction_id
        status
        tickets {
          id
          seat_number
          price
          created_at
          updated_at
        }
        created_at
        updated_at
      }
      edit_ticket {
        deduction_amount
      }
    }
  }
`;
