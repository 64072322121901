import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';
import i18n from "./i18n";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const authLink = setContext((_, { headers }) => {



  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  const language = i18n.language??'en';
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'Accept-Language': language,
      'source': 'dashboard',
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
  link: authLink.concat(
    createUploadLink({
      uri: process.env.REACT_APP_BACKEND_URL,
    })
  ),
});

export default client;
