import gql from 'graphql-tag';

const TOGGLE_AGENT = gql`
  mutation togglePartnerSegmentAgent($partnerSegmentId: ID!) {
    togglePartnerSegmentAgent(partner_segment_id: $partnerSegmentId) {
      status
      message
    }
  }
`;

export default TOGGLE_AGENT;
