import React from 'react';
import { Table, Card, Space } from 'antd';
import { Can } from '../../components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RouteLinesPrices = ({
  cardTitle,
  routeLines,
  setActivePrice,
  setPricesModalVisible,
  setPriceFormModalVisible,
  setActiveType,
  setFrom,
  setTo,
}) => {
  const [t, i18n] = useTranslation();

  const columns = [
    {
      title: 'From',
      dataIndex: 'fromCityName',
    },
    {
      title: 'To',
      dataIndex: 'toCityName',
    },
    {
      title: 'Seat Type',
      dataIndex: ['seatType', 'name_en'],
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: 'Actions',
      render: (record) => {
        return (
          <Space>
            <Can
              perform="CREATE_TRIP"
              yes={
                <Link
                  onClick={() => {
                    console.info(record);
                    setActivePrice(record.price);
                    setActiveType(record.seatType?.id);
                    setFrom(record.formCityId);
                    setTo(record.ToCityId);
                    setPricesModalVisible(false);
                    setPriceFormModalVisible(true);
                  }}
                >
                  {t('Change Price')}
                </Link>
              }
            />
          </Space>
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const routeLinesPerSeatType = [];
  routeLines.forEach((route) => {
    route.prices.forEach((pricePerSeatType) => {
      const {
        id: routeLineId,
        from_city: { name_en: fromCityName, id: formCityId },
        to_city: { name_en: toCityName, id: ToCityId },
      } = route;
      const {
        seat_type_id: seatType,
        price,
        bus_salon_id: busSalonId,
      } = pricePerSeatType;
      routeLinesPerSeatType.push({
        key: `${routeLineId}-${busSalonId}-${seatType.id}`,
        fromCityName,
        toCityName,
        seatType,
        price,
        formCityId,
        ToCityId,
      });
    });
  });
  return (
    <Card title={cardTitle}>
      <Table
        dataSource={routeLinesPerSeatType}
        columns={columns}
        pagination={false}
      />
    </Card>
  );
};

export default RouteLinesPrices;
