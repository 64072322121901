import gql from 'graphql-tag';

export const SETTLE_BALANCE = gql`
  mutation driverSettleBalance($amount: Float!, $driverId: ID!) {
    driverSettleBalance(amount: $amount, driver_id: $driverId) {
      status
      message
      message_ar
    }
  }
`;

export default SETTLE_BALANCE;
