import gql from 'graphql-tag';

const SUSPEND_USER = gql`
  mutation userUpdateStatus($id: ID!, $status: Int!) {
    userUpdateStatus(id: $id, status: $status) {
      status
      message
      data {
        id
        name
      }
    }
  }
`;

export default SUSPEND_USER;
