import gql from 'graphql-tag';

const GET_USERS = gql`
  query users(
    $first: Int!
    $page: Int
    $id: ID
    $phone: String
    $role_id: Int
    $station: Int
  ) {
    users(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
      id: $id
      phone: $phone
      role_id: $role_id
      station: $station
    ) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        name
        phone
        email
        is_verified
        active
        verification_code {
          code
          expiry_time
        }
        role {
          id
          name
        }
        station {
          id
          name_en
        }
        partner_segment {
          id
          name
        }
      }
    }
  }
`;

export default GET_USERS;
