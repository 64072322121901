/**This component renders the view to enter phone number to reset password
 * or forgot password form to enter the new password based on submitted phone number
 */
import React, { useState } from 'react';
import ForgotPasswordForm from './forgot-password';
import ChangePasswordForm from './change-password';
import { Redirect } from 'react-router-dom';

const ResetPassword = () => {
  const [phone, setPhone] = useState('');

  if (localStorage.getItem('token')) return <Redirect to="/"></Redirect>;

  return phone ? (
    <ChangePasswordForm phone={phone}></ChangePasswordForm>
  ) : (
    <ForgotPasswordForm setPhone={setPhone}></ForgotPasswordForm>
  );
};

export default ResetPassword;
