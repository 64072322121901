import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import search_customers from 'services/customers/search-customers';
import { useDebounce } from 'utilities';
const { Option } = Select;

const SearchCustomersSelect = ({ setRecipientsValue }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [value, setValue] = useState([]);
  const [results, setResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [searchCustomers, { loading, data }] = useLazyQuery(search_customers, {
    variables: {
      customerPhoneNumber: debouncedSearchTerm,
    },
    onCompleted: () => {
      setResults(data && data.searchCustomers);
    },
  });

  useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (debouncedSearchTerm && debouncedSearchTerm.length > 5) {
        // Fire off our API call
        searchCustomers();
      }
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm, searchCustomers]
  );

  const handleChange = (value) => {
    setValue(value);

    const ids = value.map((val) => val.key);
    setRecipientsValue(ids);
  };

  return (
    <Select
      mode="multiple"
      notFoundContent={loading ? <Spin size="small" /> : null}
      onSearch={(searchValue) => setSearchTerm(searchValue)}
      onChange={handleChange}
      labelInValue
      value={value}
      filterOption={false}
      placeholder="Type at least 5 digits of the phone number"
    >
      {data &&
        data.searchCustomers.map((customer) => (
          <Option key={customer.id} value={customer.id}>
            {customer.name}
          </Option>
        ))}
    </Select>
  );
};

export default SearchCustomersSelect;
