import gql from 'graphql-tag';

const resetPassword = gql`
  mutation userResetPassword(
    $code: Int!
    $phone: String!
    $password: String!
    $password_confirmation: String!
  ) {
    userResetPassword(
      code: $code
      phone: $phone
      password: $password
      password_confirmation: $password_confirmation
    ) {
      code
      success
      message
    }
  }
`;

export default resetPassword;
