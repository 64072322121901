import { notification } from 'antd';

const notify = (type, message, description, duration) => {
  if (duration === 0) {
    notification[type]({
      message: message,
      description: description,
      duration: duration,
    });
  } else {
    notification[type]({
      message: message,
      description: description,
    });
  }
};

export default notify;
