import gql from 'graphql-tag';

export const EXPORT_TRIPS_REPORT = gql`
  mutation exportTrips {
    exportTrips {
      status
      message
      data {
        downloadable_link
      }
    }
  }
`;

export default EXPORT_TRIPS_REPORT;
