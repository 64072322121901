/**List cashouts for a certain customer and an option
 * to filtered listed cashouts based on ticket number
 * and refund information
 */
import React, { useState, useRef, useEffect } from 'react';
import {
  Table,
  Badge,
  Input,
  Space,
  Button,
  Popconfirm,
  Form,
  DatePicker,
} from 'antd';
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled/macro';

import { colors, formatDate } from 'utilities';
import { GET_CUSTOMER_CASHOUT } from 'services';
import {
  ListHeader,
  PrimaryTitle,
  Can,
  SelectCustomer,
  SelectUser,
} from 'components';
import ConfirmModal from './confirm-modal';
import StationsFilter from './station-filter';
import { notify } from 'utilities';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
const { Item } = Form;

const CashoutsListContainer = styled.div`
  .ant-form-item-label {
    font-weight: 500;
    color: ${colors.primaryColor};

    label {
      color: ${colors.primaryColor};
      height: 100%;
    }
  }
`;

const TitleSearchWrapper = styled.div`
  background-color: ${colors.gray};
`;

const SelectCustomerWrapper = styled.div`
  width: 500px;
  padding: 25px 40px;

  p {
    margin: 25px 0 0 0;
    color: ${colors.lightBlue};
  }
`;

const CustomerInfo = styled.div`
  display: flex;
  font-size: 16px;
  padding: 10px 0;
  border-bottom: 1px solid gray;
  height: 100%;
  line-height: 40px;

  p {
    margin: 0;
    padding: 10px 40px;

    :first-of-type {
      border-right: 1px dotted lightgrey;
    }
  }

  span {
    font-weight: bold;
    font-size: 18px;
  }
`;

const CustomerCashoutsList = () => {
  const [customer, setCustomer] = useState(null);
  const [visible, setVisible] = useState(false);
  const [cashout, setCashout] = useState(false);
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();
  const nameLang = i18n.language;
  const [filteredValues, setFilteredValues] = useState({
    status: null,
    station: null,
    confirmedBy: null,
    confirmedAt: null,
  });
  const searchInput = useRef(null);
  const [page, setPage] = useState(1);

  const [
    getCustomerCashout,
    { data, loading, fetchMore, refetch, error },
  ] = useLazyQuery(GET_CUSTOMER_CASHOUT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (error) {
      let errorMessage = '';
      const messages = error?.graphQLErrors?.map(({ message }, i) => {
        errorMessage += message;
        return;
      });
      notify('error', errorMessage);
    }
  }, [error]);

  const selectCustomer = (selectedCustomer) => {
    if (!selectedCustomer) setCustomer(null);
    else {
      const customer = JSON.parse(selectedCustomer);
      setCustomer(customer);
      getCustomerCashout({
        variables: {
          customerId: customer.id,
          first: 5,
        },
      });
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Order Number',
      dataIndex: ['order', 'order_number'],
    },
    {
      title: 'Amount_1',
      key: 'amount',
      render: (cashoutItem) => `${cashoutItem.amount} L.E`,
    },
    {
      title: 'Refunded By',
      dataIndex: ['confirmed_by', 'name'],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <SelectUser
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys, confirm, 'confirmed_by')
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filteredValue: filteredValues.confirmedBy,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: 'Refund Station',
      key: 'refundStation',
      render: (cashoutItem) => {
        if (cashoutItem?.auto_confirmed === true) {
          return 'Online';
        }
        return cashoutItem?.confirmed_station?.name_en;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <StationsFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            forwardedRef={searchInput}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys, confirm, 'confirmed_station')
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filteredValue: filteredValues.station,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      },
    },
    {
      title: 'Refund Date',
      key: 'confirmed_at',
      render: (cashoutItem) => formatDate(cashoutItem.confirmed_at),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            onChange={(date, dateString) => setSelectedKeys(dateString || null)}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(selectedKeys, confirm, 'confirmed_at');
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filteredValue: filteredValues.confirmedAt,
      /*    onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.focus(), 100);
        }
      }, */
    },
    {
      title: 'Status',
      // dataIndex: 'status',
      render: (status) => (
        <Badge
          status={t(status.status )=== t('Pending') ? 'processing' : 'success'}
          text={t(status.status)}
        />
      ),
      filters: [
        { text: t('Confirmed'), value: 'Confirmed' },
        { text: t('Pending'), value: 'Pending' },
      ],
      filteredValue: filteredValues.status,
      filterMultiple: false,
    },
    {
      title: 'Operations',
      render: (cashoutItem) => {
        return cashoutItem.status === 'Confirmed' ? null : (
          <Can
            perform="CONFIRM_CASHOUT"
            yes={
              <a
                href="#!"
                onClick={() => {
                  setCashout(cashoutItem);
                  setVisible(true);
                }}
              >
                {t('Confirm')}
              </a>
            }
          />
        );
      },
    },
  ];
  //Translate Function For columns
  const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });

  const expandedColumns = [
    {
      title: t('Trip Code'),
      dataIndex: ['ticket', 'trip', 'ref_code'],
    },
    {
      title: t('Trip Date'),
      dataIndex: ['ticket', 'trip', 'date'],
    },
    {
      title: t('From'),
      dataIndex: ['ticket', 'from_location', nameLang==="en" ?  'name_en' : 'name_ar'],
    },
    {
      title: t('To'),
      dataIndex: ['ticket', 'to_location', nameLang==="en" ?  'name_en' : 'name_ar'],
    },
    {
      title: t('Payment Method'),
      // dataIndex: ['ticket', 'payment_method'],
      render : (record)=> t(record.ticket.payment_method),

    },
  ];

  const onChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setFilteredValues({
      ...filteredValues,
      status: filters.status,
      confirmedBy: filters['confirmed_by.name'],
      station: filters['confirmed_station.name_en'],
      confirmedAt: filters['confirmed_at'],
    });
    refetch({
      page: pagination.current,
      status: filters.status || undefined,
      confirmedBy: filters['confirmed_by.name'] || undefined,
      station: filters['confirmed_station.name_en'] || undefined,
      confirmedAt: filters['confirmed_at'] || undefined,
    });
  };

  const resetFilters = () => {
    form.resetFields();
    setFilteredValues({
      ...filteredValues,
      status: null,
      confirmedBy: null,
      station: null,
      confirmedAt: null,
    });
    refetch({
      page: page,
      status: undefined,
      confirmedBy: undefined,
      station: undefined,
      ticketCode: undefined,
      confirmedAt: undefined,
    });
  };

  const onFinish = (values) => {
    const { ticketId } = values;
    refetch({
      ticketCode: ticketId || undefined,
    });
  };

  return (
    <CashoutsListContainer>
      <TitleSearchWrapper>
        <ListHeader>
          <PrimaryTitle>{t('Cashout')}</PrimaryTitle>
        </ListHeader>

        <SelectCustomerWrapper>
          <SelectCustomer selectCustomer={selectCustomer} />
        </SelectCustomerWrapper>
      </TitleSearchWrapper>

      {customer && (
        <div>
          <CustomerInfo>
            <p>
              <span>{t("Customer Name")} :</span> {customer.name}
            </p>
            <p>
              <span>{t("Customer Phone")} :</span> {customer.phone}
            </p>
          </CustomerInfo>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '30px 40px',
              borderRight: '1px solid #f0f0f0',
              borderLeft: '1px solid #f0f0f0',
            }}
          >
            <Form
              form={form}
              onFinish={onFinish}
              layout={isMobile ? 'vertical' : 'inline'}
            >
              <Item
                style={{ marginBottom: 0, flex: '1 1 0' }}
                name="ticketId"
                label= {t("Find Ticket Number")}
              >
                <Input placeholder={t("Enter the ticket number")} />
              </Item>

              <Item>
                <Button type="primary" htmlType="submit">
                  {t('Search')}
                </Button>
              </Item>
            </Form>
            <Popconfirm
              title={t("Are you sure you want to reset all filters?")}
              okText={t("Yes")}
              cancelText={t("No")}
              onConfirm={() => resetFilters()}
              onCancel={() => {}}
            >
              <Button htmlType="button" type="primary" danger>
                {t('Reset All Filters')}
              </Button>
            </Popconfirm>
          </div>
        </div>
      )}

      {customer && (
        <Table
          bordered
          rowKey={(record) => record.id}
          dataSource={data?.searchCashoutList?.data}
          columns={columns}
          loading={loading}
          scroll={{ x: 400 }}
          onChange={onChange}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                columns={expandedColumns}
                dataSource={[record]}
                pagination={false}
              />
            ),
          }}
          pagination={{
            total: data?.searchCashoutList?.paginatorInfo?.total,
            pageSize: 5,
            showSizeChanger: false,
          }}
        />
      )}

      <ConfirmModal
        visible={visible}
        setVisible={setVisible}
        refetch={refetch}
        cashoutId={cashout.id}
        page={page}
      />
    </CashoutsListContainer>
  );
};

export default CustomerCashoutsList;
