/**Contain all the side bar menu items in the dashboard panel
 * with a perform prop to control the visibilty access based on the role type
 */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GlobalStyles } from 'utilities';
import 'styles.css';
import 'public.css';
import {
  Vehicles,
  CitiesList,
  LocationsList,
  CitiesForm,
  LocationsForm,
  BusBuilder,
  TicketingPage,
  TripList,
  BusSalonList,
  CustomerForm,
  DriverForm,
  CustomerDetails,
  BusClassForm,
  BusClassList,
  TripTempleteList,
  EditTripInstance,
  Orders,
  OrderDetails,
  PromoCodesList,
  PromoCodeForm,
  LoginForm,
  NotificationsList,
  PrivateTrips,
  NotificationsForm,
  ResetPassword,
  WorkOrder,
  CancelTrip,
  DriverOrdersList,
  Payment,
  TicketDetails,
  StopTrip,
} from 'scenes';

import CreateTrip from './scenes/trip-composer/create-trip-form.js';
import UpdateTrip from './scenes/trip-composer/edit-trip-form';
import 'antd/dist/antd.css';
import { AppLayout, LoginLayout, NotFound, Unauthorized } from 'components';
import Auth from 'components/Auth.js';
import { AuthConsumer } from 'authContext.js';
import ProtectedRoute from 'components/ProtectedRoute.js';
import { Spin } from 'antd';
import { ListHeader, PrimaryTitle } from 'components';

import { UserForm, ShiftForm, SegmentForm, ShipmentForm } from 'forms';
import {
  UsersList,
  CustomerCashoutsList,
  CashoutsList,
  cashCollectionList,
  TicketsList,
  ViewTickets,
  ViewCashCollection,
  CustomersList,
  DriversList,
  TripInstancesList,
  ShiftsList,
  ShiftsLogs,
  SegmentsList,
  ShipmentDetails,
  ShipmentsList,
  LogsList,
  PromoCodeDetails,
  ViewLuggageLabels,
  ViewCashOtherFees,
  ViewSegment,
  AuditList,
} from 'lists';
import styled from '@emotion/styled/macro';
import useScript from 'react-script-hook';
import PermissionList from './lists/permission';
import RoleList from './lists/roles';
import RolePermissionForm from './lists/roles/edit-role-permssions';
import CancelTemplate from './scenes/cancel-template';
import JobList from './lists/jobs';
import SettingsList from './lists/settings';
import AgentList from './lists/agents';
import { useTranslation } from 'react-i18next';
import { ViewAgentLogs } from './lists/agents/view';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const Home = () => {
  const [t, i18n] = useTranslation();
  return (
    <ListHeader>
      <PrimaryTitle>{t('Welcome to our home page')}</PrimaryTitle>
    </ListHeader>
  );
};

const App = () => {
  useScript({
    src: process.env.REACT_APP_FAWRY_PLUGIN,
    // eslint-disable-next-line no-undef
    onload: () => (window.FawryPay = FawryPay),
  });

  return (
    <>
      <GlobalStyles />

      <Auth>
        <AuthConsumer>
          {({ authenticated, fetchingUser }) => {
            // TODO: wrap the spinner in a sized component
            if (fetchingUser)
              return (
                <SpinnerContainer>
                  <Spin />
                </SpinnerContainer>
              );

            return (
              <>
                {authenticated ? (
                  <AppLayout>
                    <Switch>
                      <ProtectedRoute
                        path="/stations"
                        perform="LIST_STATIONS"
                        component={LocationsList}
                      />

                      <ProtectedRoute
                        path="/new-location"
                        perform="CREATE_STATION"
                        component={LocationsForm}
                      />

                      <ProtectedRoute
                        path="/edit-location/:id"
                        perform="UPDATE_STATION"
                        component={LocationsForm}
                      />

                      <ProtectedRoute
                        path="/cities"
                        perform="LIST_CITIES"
                        component={CitiesList}
                      />

                      <ProtectedRoute
                        path="/new-city"
                        perform="CREATE_CITY"
                        component={CitiesForm}
                      />

                      <ProtectedRoute
                        path="/edit-city/:id"
                        perform="UPDATE_CITY"
                        component={CitiesForm}
                      />

                      <ProtectedRoute
                        path="/ticketing"
                        perform="CREATE_ORDER"
                        component={TicketingPage}
                      />

                      <ProtectedRoute
                        path="/customers"
                        perform="LIST_CUSTOMERS"
                        component={CustomersList}
                      />

                      <ProtectedRoute
                        path="/new-customer"
                        perform="CREATE_CUSTOMER"
                        component={CustomerForm}
                      />

                      <ProtectedRoute
                        path="/customer-details/:id"
                        perform="VIEW_CUSTOMER"
                        component={CustomerDetails}
                      />

                      <ProtectedRoute
                        path="/drivers"
                        perform="LIST_DRIVERS"
                        component={DriversList}
                      />

                      <ProtectedRoute
                        path="/new-driver"
                        perform="CREATE_DRIVER"
                        component={DriverForm}
                      />

                      <ProtectedRoute
                        path="/vehicles"
                        perform="LIST_BUSES"
                        component={Vehicles}
                      />

                      <ProtectedRoute
                        path="/classes"
                        perform="LIST_BUS_CLASSES"
                        component={BusClassList}
                      />

                      <ProtectedRoute
                        path="/bus-class/new"
                        perform="CREATE_BUS_CLASSES"
                        component={BusClassForm}
                      />

                      <ProtectedRoute
                        path="/salons"
                        perform="LIST_SALONS"
                        component={BusSalonList}
                      />

                      <ProtectedRoute
                        path="/new-bus-salon"
                        perform="CREATE_SALON"
                        component={BusBuilder}
                      />

                      <ProtectedRoute
                        path="/trips"
                        perform="LIST_TRIP_TEMPLATES"
                        component={TripTempleteList}
                      />

                      <ProtectedRoute
                        path="/trip-instances"
                        perform="LIST_TRIP_INSTANCES"
                        component={TripInstancesList}
                      />

                      <ProtectedRoute
                        path="/trip-details/:id"
                        perform="VIEW_TRIP_DETAILS"
                        component={TripList}
                      />

                      <ProtectedRoute
                        path="/driver-bus-assigning/:id/:salon_id"
                        perform="ASSIGN_DRIVER"
                        component={EditTripInstance}
                      />

                      <ProtectedRoute
                        path="/new-trip"
                        perform="CREATE_TRIP"
                        component={CreateTrip}
                      />
                      <ProtectedRoute
                        path="/update-trip"
                        perform="CREATE_TRIP"
                        component={UpdateTrip}
                      />
                      <ProtectedRoute
                        path="/orders"
                        perform="LIST_ORDERS"
                        component={Orders}
                      />

                      <ProtectedRoute
                        path="/order/:id"
                        perform="VIEW_ORDER_DETAILS"
                        component={OrderDetails}
                      />

                      <ProtectedRoute
                        path="/promo-codes"
                        perform="LIST_PROMO_CODES"
                        component={PromoCodesList}
                      />

                      <ProtectedRoute
                        path="/new-promo-code"
                        perform="CREATE_PROMO_CODE"
                        component={PromoCodeForm}
                      />

                      <ProtectedRoute
                        path="/promo-code/:promoCodeId"
                        perform="VIEW_PROMO_CODE"
                        component={PromoCodeDetails}
                      />

                      <ProtectedRoute
                        path="/notifications"
                        perform="LIST_NOTIFICATIONS"
                        component={NotificationsList}
                      />

                      <ProtectedRoute
                        path="/trip-private"
                        perform="LIST_PRIVATE_TRIPS"
                        component={PrivateTrips}
                      />

                      <ProtectedRoute
                        path="/new-notification"
                        perform="CREATE_NOTIFICATIONS"
                        component={NotificationsForm}
                      />

                      <ProtectedRoute
                        path="/search-cashout"
                        perform="LIST_CUSTOMER_CASHOUT"
                        component={CustomerCashoutsList}
                      />

                      <ProtectedRoute
                        path="/cashout"
                        perform="LIST_CASHOUT"
                        component={CashoutsList}
                      />
                      <ProtectedRoute
                        path="/cash-collection"
                        perform="LIST_CASHOUT"
                        component={cashCollectionList}
                      />
                      <ProtectedRoute
                        path="/other-fees"
                        perform="LIST_CASHOUT"
                        component={ViewCashOtherFees}
                      />
                      <ProtectedRoute
                        path="/work-order/:tripId"
                        perform="VIEW_ACTIVE_TRIP_DETAILS"
                        component={WorkOrder}
                      />

                      <ProtectedRoute
                        path="/cancel-trip/:tripId"
                        perform="DEACTIVATE_TRIP"
                        component={CancelTrip}
                      />
                      <ProtectedRoute
                        path="/cancel-template/:templateId"
                        perform="DEACTIVATE_TRIP"
                        component={CancelTemplate}
                      />

                      <ProtectedRoute
                        path="/driver-orders/:driverId"
                        perform="LIST_DRIVER_ORDERS"
                        component={DriverOrdersList}
                      />

                      <ProtectedRoute
                        path="/staff"
                        perform="LIST_USERS"
                        component={UsersList}
                      />

                      <ProtectedRoute
                        path="/new-user"
                        perform="CREATE_USER"
                        component={UserForm}
                      />

                      <ProtectedRoute
                        path="/edit-user/:id"
                        perform="CREATE_USER"
                        component={UserForm}
                      />
                      <ProtectedRoute
                        path="/tickets/view/:ticketIds"
                        perform="PRINT_TICKETS"
                        component={ViewTickets}
                      />
                      <ProtectedRoute
                        path="/cashCollection/view/:cashCollectionIds"
                        perform="PRINT_TICKETS"
                        component={ViewCashCollection}
                      />
                      <ProtectedRoute
                        path="/tickets/view-luggage/:ticketIds"
                        perform="PRINT_TICKETS"
                        component={ViewLuggageLabels}
                      />
                      <ProtectedRoute
                        path="/tickets"
                        perform="LIST_TICKETS"
                        component={TicketsList}
                      />

                      <ProtectedRoute
                        path="/ticket/:ticketId"
                        perform="LIST_TICKETS"
                        component={TicketDetails}
                      />

                      <ProtectedRoute
                        path="/payment"
                        perform="CREATE_ORDER"
                        component={Payment}
                      />

                      <ProtectedRoute
                        path="/new-shift"
                        perform="CREATE_STATION_SHIFT"
                        component={ShiftForm}
                      />

                      <ProtectedRoute
                        path="/shifts"
                        perform="LIST_STATION_SHIFTS"
                        component={ShiftsList}
                      />

                      <ProtectedRoute
                        path="/shifts-logs"
                        perform="LIST_SHIFTS_LOGS"
                        component={ShiftsLogs}
                      />
                      <ProtectedRoute
                        path="/new-partner-segment"
                        perform="CREATE_PARTNER_SEGMENT"
                        component={SegmentForm}
                      />

                      <ProtectedRoute
                        path="/segments"
                        perform="LIST_PARTNER_SEGMENT"
                        component={SegmentsList}
                      />
                      <ProtectedRoute
                        path="/agents"
                        perform="LIST_PARTNER_SEGMENT"
                        component={AgentList}
                      />
                      <ProtectedRoute
                        path="/segments-view/:id"
                        perform="LIST_PARTNER_SEGMENT"
                        component={ViewSegment}
                      />
                      <ProtectedRoute
                        path="/agent-logs/:id"
                        perform="LIST_PARTNER_SEGMENT"
                        component={ViewAgentLogs}
                      />
                      <ProtectedRoute
                        path="/permissions"
                        perform="LIST_ALL_PERMISSION"
                        component={PermissionList}
                      />
                      <ProtectedRoute
                        path="/roles"
                        perform="LIST_ALL_PERMISSION"
                        component={RoleList}
                      />
                      <ProtectedRoute
                        path="/edit-role/:id"
                        perform="LIST_ALL_PERMISSION"
                        component={RolePermissionForm}
                      />

                      <ProtectedRoute
                        path="/shipments"
                        perform="CAN_LIST_SHIPPING_ORDERS"
                        component={ShipmentsList}
                      />

                      <ProtectedRoute
                        path="/new-shipment"
                        perform="CAN_CREATE_SHIPPING_ORDER"
                        component={ShipmentForm}
                      />

                      <ProtectedRoute
                        path="/edit-shipment/:id"
                        perform="CAN_EDIT_SHIPPING_ORDER"
                        component={ShipmentForm}
                      />

                      <ProtectedRoute
                        path="/shipping-order/:orderId"
                        perform="CAN_VIEW_SHIPPING_ORDER"
                        component={ShipmentDetails}
                      />

                      <ProtectedRoute
                        path="/stop-trip/:tripId"
                        perform="STOP_TRIP"
                        component={StopTrip}
                      />

                      <ProtectedRoute
                        path="/logs"
                        perform="LIST_LOGS"
                        component={LogsList}
                      />
                      <ProtectedRoute
                        path="/audits"
                        perform="LIST_LOGS"
                        component={AuditList}
                      />
                      <ProtectedRoute
                        path="/jobs"
                        perform="LIST_ALL_JOBS"
                        component={JobList}
                      />
                      <ProtectedRoute
                        path="/settings"
                        perform="LIST_SETTINGS"
                        component={SettingsList}
                      />

                      <Route exact path="/" component={Home} />
                      <Route path="/unauthorized" component={Unauthorized} />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </AppLayout>
                ) : (
                  <LoginLayout>
                    <Switch>
                      <Route path="/" exact component={LoginForm} />
                      <Route path="/login" component={LoginForm} />
                      <Route
                        path="/forgot-password"
                        component={ResetPassword}
                      />
                      <Redirect from="*" to="/" />
                    </Switch>
                  </LoginLayout>
                )}
              </>
            );
          }}
        </AuthConsumer>
      </Auth>
    </>
  );
};

export default App;
