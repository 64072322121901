import gql from 'graphql-tag';

export default gql`
  mutation addCartPromoCode(
    $token: String!
    $promo_code: String!
    $customer_id: ID!
  ) {
    addCartPromoCode(
      token: $token
      promo_code: $promo_code
      customer_id: $customer_id
    ) {
      status
      message
      data {
        id
        token
        created_at
        updated_at
        promo_code {
          code
          active
          value
        }
        tickets {
          id
          seat_number
          price
          from_date
          original_price
          seat_type {
            id
            name_en
            name_ar
          }
          routeLine {
            id
          }
          trip {
            id
            ref_code
            access_level
            date
            time
            status
            is_active
            busSalon {
              id
              name
              layout {
                key
                rows
                cols
                type
                seat_type_id
                numbering
              }
              seat_types {
                id
                name_en
                name_ar
                rows
                cols
              }
            }
            locations {
              id
              name_en
              name_ar
              pivot {
                time
              }
            }
          }
          from_location {
            id
            code
            name_en
            name_ar
            type
            city {
              id
              code
              name_en
              name_ar
            }
          }
          to_location {
            id
            code
            name_en
            name_ar
            type
            city {
              id
              code
              name_en
              name_ar
            }
          }
        }
      }
    }
  }
`;
