import gql from 'graphql-tag';

export const EXPORT_SEGMENTS_TRANSACTIONS_REPORT = gql`
  mutation exportPartnerSegmentsTransactions(
    $id: ID!
    $from: String
    $to: String
  ) {
    exportPartnerSegmentsTransactions(id: $id, from: $from, to: $to) {
      status
      message
      __typename
    }
  }
`;

export default EXPORT_SEGMENTS_TRANSACTIONS_REPORT;
