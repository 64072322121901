/** Component for creating/editing staff member */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Select, Spin, Row, Col, Popconfirm } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  CREATE_USER,
  GET_USER_ROLES,
  GET_ACTIVE_LOCATIONS,
  UPDATE_USER,
  GET_ALL_SEGMENTS,
} from 'services';
import { ListHeader, PrimaryTitle } from 'components';
import {
  notify,
  formatRole,
  FormContainer,
  ActionButton,
  CancelButton,
} from 'utilities';
import { useTranslation } from 'react-i18next';

const { Item } = Form;
const { Option, OptGroup } = Select;

const NewUserContainer = styled.section`
  ${FormContainer}
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  ${ActionButton}
  ${CancelButton}
`;

const UserForm = ({ location: { state: { user, segment } = {} } }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [t, i18n] = useTranslation();

  const [role, setRole] = useState('');

  const [createUser, { loading: creatingUser }] = useMutation(CREATE_USER);
  const [updateUser, { loading: updatingUser }] = useMutation(UPDATE_USER);
  const { data: rolesData, loading: loadingRoles } = useQuery(GET_USER_ROLES);
  const { data: locationsData, loading: loadingLocations } = useQuery(
    GET_ACTIVE_LOCATIONS,
    {
      variables: {
        staff: true,
      },
    }
  );
  const { data: segmentsData, loading: loadingSegments } = useQuery(
    GET_ALL_SEGMENTS,
    {
      onError: (err) => {
        notify('error', 'account_opened_in_another_device');
        setTimeout(function () {
          window.location.replace('/');
        }, 3000);
      },
    }
  );

  const editing = user ? true : false;

  const { activeLocations } = locationsData || { activeLocations: [] };
  const availableLocations = activeLocations.reduce((result, location) => {
    const cityName = location.city.name_en;
    const cityId = location.city.id;

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});

  useEffect(() => {
    if (editing) setRole(user?.role?.name);
  }, [editing, user]);

  const onFinish = (values) => {
    const { role, station, name, phone, email, segment } = values;

    editing
      ? updateUser({
          variables: {
            id: user.id,
            role,
            station,
            name,
            email,
            segment,
          },
        })
          .then((response) => {
            const {
              data: {
                updateUser: { status, message },
              },
            } = response;

            if (status) {
              notify('success', message);
              history.push('/staff');
            }
          })
          .catch((err) => {
            if (err['graphQLErrors'][0]?.extensions) {
              const {
                extensions: { validation },
                message,
              } = err['graphQLErrors'][0];
              if (validation) {
                for (let error in validation) {
                  notify('error', validation[error][0]);
                }
              } else {
                notify('error', t(message));
              }
            }
          })
      : createUser({
          variables: {
            role,
            station,
            name,
            phone,
            email,
            segment,
          },
        })
          .then((response) => {
            const {
              data: {
                userCreate: { status, message },
              },
            } = response;

            if (status) {
              notify('success', message);
              history.push('/staff');
            }
          })
          .catch((err) => {
            if (err['graphQLErrors'][0]?.extensions) {
              const {
                extensions: { validation },
                message,
              } = err['graphQLErrors'][0];
              if (validation) {
                for (let error in validation) {
                  notify('error', validation[error][0]);
                }
              } else {
                notify('error', t(message));
              }
            }
          });
  };

  const onSelect = (value) => {
    setRole(value);
  };

  return (
    <NewUserContainer>
      <ListHeader>
        <PrimaryTitle>
          {editing ? t('Edit User') : t('Create User')}
        </PrimaryTitle>
      </ListHeader>

      <Spin spinning={loadingLocations || loadingRoles || loadingSegments}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={
            editing
              ? {
                  name: user.name,
                  phone: user.phone,
                  email: user.email,
                  role: user.role?.name,
                  station: user.station ? user.station.id : undefined,
                  segment: user.partner_segment
                    ? user.partner_segment.id
                    : undefined,
                }
              : segment
              ? { role: 'PARTNER' || 'PARTNER_ADMIN', segment: segment?.id }
              : null
          }
        >
          <Row gutter={10}>
            <Col span={12}>
              <Item
                name="role"
                label={t('Role')}
                rules={[
                  {
                    required: true,
                    message: t('Please, select a role from the list!'),
                  },
                ]}
              >
                <Select
                  disabled={segment}
                  placeholder={t('Select Role')}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  onSelect={onSelect}
                >
                  {loadingRoles ? (
                    <Option
                      value={null}
                      disabled
                      style={{ textAlign: 'center' }}
                    >
                      <Spin tip="Loading Roles..." />
                    </Option>
                  ) : (
                    rolesData.roles.map((role) => (
                      <Option key={role.id} value={role.name}>
                        {formatRole(role.name)}
                      </Option>
                    ))
                  )}
                </Select>
              </Item>
            </Col>

            <Col span={12}>
              <Item
                name="segment"
                label={t('Partner Segment')}
                rules={[
                  {
                    required: role === 'PARTNER' && role === 'PARTNER_ADMIN',
                    message: t('Please, select a segment from the list!'),
                  },
                ]}
              >
                <Select
                  placeholder={t('Select Partner Segment')}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  disabled={role !== 'PARTNER' && role !== 'PARTNER_ADMIN'}
                >
                  {loadingSegments ? (
                    <Option
                      value={null}
                      disabled
                      style={{ textAlign: 'center' }}
                    >
                      <Spin tip="Loading Segments..." />
                    </Option>
                  ) : (
                    segmentsData.allPartnerSegments.map((segment) => (
                      <Option key={segment.id} value={segment.id}>
                        {segment.name}
                      </Option>
                    ))
                  )}
                </Select>
              </Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <Item
                name="name"
                label={t('Full Name')}
                rules={[
                  {
                    required: true,
                    message: t('Please, enter the staff member name!'),
                  },
                ]}
              >
                <Input placeholder="e.g. John Doe" size="large" />
              </Item>
            </Col>

            <Col span={12}>
              <Item
                name="phone"
                label={t('Phone Number')}
                rules={[
                  {
                    required: editing ? false : true,
                    message: t('Please, enter a valid phone number!'),
                    min: 7,
                    // max: 12,
                    // pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                  },
                ]}
              >
                <Input
                  disabled={editing ? true : false}
                  placeholder="e.g. 01123456789"
                  size="large"
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={24}>
              <Item
                name="email"
                label={t('Email')}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: t('Please, enter a valid email!'),
                  },
                ]}
              >
                <Input placeholder="e.g. email@company.com" size="large" />
              </Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <Item name="station" label={t('Station (Optional)')}>
                <Select
                  placeholder="Select Assigned Station"
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  allowClear
                >
                  {loadingLocations ? (
                    <Option
                      value={null}
                      disabled
                      style={{ textAlign: 'center' }}
                    >
                      <Spin tip="Loading Locations..." />
                    </Option>
                  ) : (
                    Object.keys(availableLocations).map(
                      (cityName, cityIndex) => (
                        <OptGroup key={`city_${cityIndex}`} label={cityName}>
                          {availableLocations[cityName].map(
                            (location, locationIndex) => (
                              <Option
                                key={`location_${cityIndex}_${locationIndex}`}
                                value={location.id}
                              >
                                {location.name_en}
                              </Option>
                            )
                          )}
                        </OptGroup>
                      )
                    )
                  )}
                </Select>
              </Item>
            </Col>
          </Row>

          <Row gutter={10} align="middle">
            <Col span={28}>
              <Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={creatingUser || updatingUser}
                >
                  {editing
                    ? updatingUser
                      ? t('Updating')
                      : t('Update')
                    : creatingUser
                    ? t('Creating')
                    : t('Create')}
                </Button>
              </Item>
            </Col>

            <Col span={8}>
              <Item style={{ margin: 0 }}>
                <Popconfirm
                  title={t('Are you sure you want to discard?')}
                  okText={t('Yes')}
                  cancelText={t('No')}
                  onConfirm={() => history.goBack()}
                  onCancel={() => {}}
                >
                  <Button type="link" size="large" danger>
                    {t('Cancel')}
                  </Button>
                </Popconfirm>
              </Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </NewUserContainer>
  );
};

export default UserForm;
