import gql from 'graphql-tag';

export default gql`
  mutation getCart($cartToken: String!) {
    getCart(token: $cartToken) {
      id
      token
      tickets {
        id
        seat_number
        from_time
        to_time
        seat_type {
          id
        }
        price
        routeLine {
          id
        }
        seat_type {
          id
          name_en
          name_ar
        }
        trip {
          id
          ref_code
          access_level
          date
          time
        }
        from_location {
          id
          code
          name_en
          name_ar
          type
          city {
            id
            code
            name_en
            name_ar
          }
        }
        to_location {
          id
          code
          name_en
          name_ar
          type
          city {
            id
            code
            name_en
            name_ar
          }
        }
      }
      created_at
      updated_at
    }
  }
`;
