import gql from 'graphql-tag';

export const PRINT_LUGGAGE_LABEL = gql`
  mutation printLuggageLabel($id: [ID!], $luggageNumber: Int!) {
    printLuggageLabel(id: $id, luggage_number: $luggageNumber) {
      status
      message
    }
  }
`;

export default PRINT_LUGGAGE_LABEL;
