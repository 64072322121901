import gql from 'graphql-tag';

export const GET_CANCEL_MULTI_TICKETS_DEDUCTION_AMOUNT = gql`
  query getCancelMultiTicketsDeductionAmount($id: [ID!]) {
    getCancelMultiTicketsDeductionAmount(id: $id) {
      status
      message
      message_ar
      data {
        deduction_amount
      }
    }
  }
`;

export default GET_CANCEL_MULTI_TICKETS_DEDUCTION_AMOUNT;
