import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en.json';
import translationAr from './locales/ar.json';

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAr,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    debug: true,
    nterpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
// initialized and ready to go!
// i18next is already initialized, because the translation resources where passed via init function
export default i18next;
