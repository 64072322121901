import gql from 'graphql-tag';

export const CASH_OTHER_FEES = gql`
  query getCustomersExtraFees($first: Int!, $page: Int!) {
    getCustomersExtraFees(first: $first, page: $page) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        amount
        created_at
        entity_type
        entity_id
        action
        user {
          id
          name
        }
      }
    }
  }
`;

export default CASH_OTHER_FEES;
