import gql from 'graphql-tag';

const GET_AUDITS = gql`
  query audits(
    $first: Int!
    $page: Int
    $auditable_id: ID
    $auditable_type: String
  ) {
    audits(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
      auditable_id: $auditable_id
      auditable_type: $auditable_type
    ) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        user_type
        new_values
        auditable_id
        auditable_type
        created_at
        user {
          ... on Customer {
            id
            name
            phone
          }
          ... on Driver {
            id
            name
            phone
          }
          ... on User {
            id
            name
            phone
          }
        }
      }
    }
  }
`;

export default GET_AUDITS;
