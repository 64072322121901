import React from 'react';
import { Card } from 'antd';

const InstanceInfo = ({ cardTitle, trip }) => {
  const { ref_code, date, time, seats_tickets, is_active } = trip;
  return (
    <Card title={cardTitle}>
      <p>Code: {ref_code}</p>
      <p>
        Date Time: {date} {time}
      </p>
      <p>#tickets: {seats_tickets.length}</p>
      <p>Status: {is_active}</p>
    </Card>
  );
};

export default InstanceInfo;
