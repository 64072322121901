import React, { useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Modal, Row, Col, Button, Select, DatePicker, Spin } from 'antd';
import moment from 'moment';
import {
  ReservedTickets,
  allSeatTypes,
  REPLACE_TICKET_ORDER,
  GET_ACTIVE_LOCATIONS,
} from 'services';
import Trips from 'scenes/ticketing/trips';
import BusSalon from 'scenes/ticketing/bus-salon-replace';

const ReplaceModal = ({
  visible,
  setVisible,
  ticket,
  form: { getFieldDecorator, validateFields, resetFields },
}) => {
  const [formValues, setFormValues] = useState(null);
  const [currentTrip, setCurrentTrip] = useState(null);
  const [visibleSalon, setVisibleSalon] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState({});
  const [cartToken, setCartToken] = useState(null);
  const { data: seatTypesData } = useQuery(allSeatTypes, {});

  const [
    getReservedTickets,
    { data: reservedSeats, loading: loadingReservedSeats },
  ] = useLazyQuery(ReservedTickets);
  const reset = () => {
    resetFields();
    setSelectedSeats({});
    setCartToken(null);
    setCurrentTrip(null);
    setFormValues(null);
  };
  const fromLocationId = formValues && formValues.from;

  const toLocationId = formValues && formValues.to;

  const handleSubmit = () => {
    window.location.reload();
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const formFields = ['trip_date'];
    validateFields(formFields, (err, fieldsValue) => {
      if (!err) {
        const searchValues = {
          from: ticket?.from_location?.id,
          to: ticket?.to_location?.id,
          trip_date: fieldsValue.trip_date,
        };
        console.info(searchValues);
        setFormValues(searchValues);
      }
    });
  };

  const showModal = ({ id: tripId }) => {
    setVisibleSalon(true);
    getReservedTickets({
      variables: {
        tripId,
        fromLocationId,
        toLocationId,
      },
    });
  };

  const handleCancel = () => {
    setVisibleSalon(false);
  };

  return (
    <Modal
      visible={visible}
      title="Replace Ticket"
      width="50%"
      bodyStyle={{
        height: '300px',
        overflowY: formValues === null ? 'none' : 'scroll',
      }}
      onCancel={() => {
        reset();
        setVisible(false);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            reset();
            setVisible(false);
          }}
        >
          Cancel
        </Button>,
      ]}
    >
      <Form onSubmit={handleSearch} layout="vertical">
        <h3>
          {`${ticket?.from_location?.name_en} - To - ${ticket?.to_location?.name_en}`}{' '}
        </h3>

        <Form.Item label="Trip Date">
          {getFieldDecorator('trip_date', {
            rules: [
              {
                type: 'object',
                required: true,
                message: 'Please specify the trip date!',
              },
            ],
          })(
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={(current) => {
                return current && current < moment().startOf('day');
              }}
            />
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      {formValues && (
        <Trips
          formValues={formValues}
          showModal={showModal}
          setCurrentTrip={setCurrentTrip}
        />
      )}
      {currentTrip && (
        <>
          <Modal
            title={`Select Your Seats for trip ${currentTrip.ref_code}`}
            visible={visibleSalon}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
            ]}
          >
            <Row>
              <Col span={18}>
                <Spin spinning={loadingReservedSeats}>
                  <BusSalon
                    handleSubmit={handleSubmit}
                    exit_modal={handleCancel}
                    formValues={formValues}
                    trip={currentTrip}
                    data={{
                      cartToken: cartToken,
                      ticket_id: ticket.id,
                      from: fromLocationId,
                      to: toLocationId,
                    }}
                    allSeatTypes={seatTypesData.allSeatTypes}
                    reservedSeats={
                      reservedSeats && reservedSeats.tripReservedSeats
                        ? reservedSeats.tripReservedSeats.reserved_seats
                        : []
                    }
                    selectedSeats={
                      (currentTrip &&
                        selectedSeats[currentTrip.groupTicketId]) ||
                      []
                    }
                  />
                </Spin>
              </Col>
              <Col span={6}>
                <p>Seat Pricing:</p>
                {currentTrip &&
                  currentTrip.routeLine &&
                  currentTrip.routeLine.prices &&
                  currentTrip.routeLine.prices.length &&
                  currentTrip.routeLine.prices.map((seatPricing, index) => (
                    <p key={index}>
                      {seatPricing.seat_type.name_en}: {seatPricing.price}
                    </p>
                  ))}
              </Col>
            </Row>
          </Modal>
        </>
      )}
    </Modal>
  );
};

export default Form.create({})(ReplaceModal);
