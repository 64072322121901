import gql from 'graphql-tag';

const GET_TICKETS_PRINT = gql`
  query getTicketsPrint($id: [ID!]) {
    getTicketsPrint(id: $id) {
      id
      uuid
      code
      qr_code_name
      payment_method
      status
      created_at
      seat_number
      original_price
      price
      discount_cost
      burn_rate
      from_time
      to_time
      from_date
      from_location {
        id
        name_en
        name_ar
        city {
          id
          name_en
          name_ar
        }
      }
      to_location {
        id
        name_en
        name_ar
        city {
          id
          name_en
          name_ar
        }
      }
      seat_type {
        id
        name_en
        name_ar
      }
      trip {
        id
        date
        time
        ref_code
        locations {
          id
          name_en
          pivot {
            time
            date
          }
        }
      }
      order {
        id
        order_number
      }
    }
  }
`;

export default GET_TICKETS_PRINT;
