import gql from 'graphql-tag';

const GET_CUSTOMERS = gql`
  query customers($first: Int!, $page: Int!, $customerId: ID) {
    customers(
      first: $first
      page: $page
      id: $customerId
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        total
      }
      data {
        id
        name
        phone
        verification_code {
          code
          expiry_time
        }
        registered
        guest
      }
    }
  }
`;

export default GET_CUSTOMERS;
