import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

const TripInfo = ({ cardTitle, tripTemplate }) => {
  const [t,i18n]=useTranslation()

  const {
    ref_code,
    from_date,
    to_date,
    access_level,
    is_active,
  } = tripTemplate;
  return (
    <Card title={cardTitle}>
      <p>{t("Code")}: {ref_code}</p>
      <p>
        {t("Duration: From")} {from_date} {t("to")} {to_date}
      </p>
      <p>{t("Access")}:  {access_level==='Public' ? t('Public'):t('Private')}</p>
      <p>{t("Status")}: {is_active==='Active'?t('Active'):t('Inactive')}</p>
    </Card>
  );
};

export default TripInfo;
