import gql from 'graphql-tag';

export const GET_SEGMENTS = gql`
  query partnerSegments($first: Int!, $page: Int) {
    partnerSegments(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
        currentPage
      }
      data {
        id
        name
        phone
        status {
          value
          label
        }
        credit_balance
        credit_total_added
        agents_count
        tickets_count
        sub_agent
      }
    }
  }
`;

export default GET_SEGMENTS;
