import gql from 'graphql-tag';

export default gql`
  mutation updateBusResource($id: ID!, $resource: String!) {
    updateBusResource(id: $id, resource: $resource) {
      message
      status
    }
  }
`;
