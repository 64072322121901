import gql from 'graphql-tag';

const GET_PROMO_CODE_DETAILS = gql`
  query promoCode($id: ID!) {
    promoCode(id: $id) {
      id
      name
      code
      start_date
      max_quota
      total_revenue
      cost
      payment_methods
      tickets {
        id
        uuid
        code
        original_price
        price
        discount_cost
        burn_rate
        trip {
          id
          ref_code
          date
        }
        templates {
          id
          ref_code
           
        }
        customer {
          id
          name
          phone
        }
      }
    }
  }
`;

export default GET_PROMO_CODE_DETAILS;
