import gql from 'graphql-tag';

const GET_ORDER_DETAILS = gql`
  query shippingOrder($id: ID!) {
    shippingOrder(id: $id) {
      id
      order_number
      status {
        value
        label
      }
      sender {
        id
        name
        phone
        national_id
      }
      receiver {
        id
        name
        phone
        national_id
      }
      from_location {
        id
        name_en
        name_ar
      }
      to_location {
        id
        name_en
        name_ar
      }
      trip_route_line {
        id
        trip {
          id
          ref_code
        }
      }
      total_price
      items {
        id
        name
        price
        initial_weight
        extra_weight
        quantity
        category {
          id
          name
        }
      }
      created_at
      creatable {
        id
        name
      }
      store
    }
  }
`;

export default GET_ORDER_DETAILS;
