/** List available trips based on search results for shipment */
import React, { useEffect } from 'react';
import { Table, Button } from 'antd';
import styled from '@emotion/styled/macro';
import { useQuery } from '@apollo/react-hooks';
import { colors, formatDate, formatTime, notify } from 'utilities';
import { SEARCH_TRIPS } from 'services';
import { useTranslation } from 'react-i18next';

const TripsListContainer = styled.section`
  margin-top: 10px;

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-table-wrapper {
    margin: 0 !important;
  }

  .ant-tabs-tab {
    height: 50px;
    min-width: 170px;

    div {
      color: ${colors.primaryColor};
      font-size: 16px;
    }
  }
`;

const TripsList = ({ searchValues, setLoadingResults, setTrip }) => {
  const {t,i18n} = useTranslation();
  const nameLang = i18n.language;
  const { data: tripsData, loading: fetchingTrips } = useQuery(SEARCH_TRIPS, {
    variables: {
      from: searchValues.from,
      to: searchValues.to,
      trip_date: searchValues.tripDate?.format('YYYY-MM-DD'),
      get_suggestions: false,
    },

    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setLoadingResults(fetchingTrips);
  }, [fetchingTrips, setLoadingResults]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Trip Code',
      dataIndex: 'ref_code',
    },
    {
      title: 'Departure City',
      dataIndex: ['routeLine', 'from_city', nameLang==="en" ? 'name_en' : 'name_ar'],
    },
    {
      title: 'Arrival City',
      dataIndex: ['routeLine', 'to_city', nameLang==="en" ? 'name_en' : 'name_ar'],
    },
    {
      title: 'Departure Date',
      render: (trip) => formatDate(trip?.fromDate),
    },
    {
      title: 'Arrival Date',
      render: (trip) => formatDate(trip?.toDate),
    },
    {
      title: 'Departure Time',
      render: (trip) => formatTime(trip?.fromTime),
    },
    {
      title: 'Arrival Time',
      render: (trip) => formatTime(trip?.toTime),
    },
    {
      title: 'Actions',
      render: (trip) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setTrip(trip);
            }}
          >
            {t("Select_1")}
          </Button>
        );
      },
    },
  ];
   //Translate Function For columns
   const ti81n = columns.map((ele) => {
    ele.title = t(ele.title);
  });
  

  return (
    <TripsListContainer>
      <Table
        style={{ marginTop: 15 }}
        bordered
        rowKey="id"
        columns={columns}
        loading={fetchingTrips}
        dataSource={tripsData?.searchTrips.trips}
        pagination={false}
        scroll={{ x: 400 }}
      />
    </TripsListContainer>
  );
};

export default TripsList;
